// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { HTMLAttributes, useEffect, useRef } from "react"
import styled from "styled-components/macro"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { Message } from "../ConferenceRoom"
import { useContentShareContext } from "../context/ContentShareContext"
import { useVideoContext } from "../context/VideoContext"
import VideoNameplate from "./VideoNameplate"

const ContentVideoRoot = styled.div<{ guestBannerHeight: number; isLocalScreenShare: boolean }>`
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    &.content-video-conference-room {
        height: 100vh;
        max-width: 100%;
        max-height: calc(100vh - 60px - 80px - ${(props) => props.guestBannerHeight + "px"}); /* 60 TopBar 80 controls*/
    }

    &.content-video-conference-overlay {
        height: 100%;
        overflow: hidden;
        border-radius: 4px;
        text-align: center;
        ${(props) =>
            props.isLocalScreenShare
                ? "background-color: " + (branding.mainInfoColor ?? "#000") + ";"
                : "background-color: #000;"}
        border: 1px solid #fff;
    }

    video.content-video-conference-room {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
    }
    video.content-video-conference-overlay {
        height: 100%;
        border-radius: 4px;
    }
`

interface Props extends HTMLAttributes<HTMLDivElement> {
    guestBannerHeight: number
}

export default function ContentVideo(props: Props) {
    const contentShareContext = useContentShareContext()
    const videoContext = useVideoContext()
    const strings = useLanguageState().getStrings()
    const videoElement = useRef(null)
    const isScreenShareEnabled = contentShareContext.isSharingScreen()
    const isLocalScreenShare = contentShareContext.isLocalAttendeeSharingScreen()
    const shareScreenTileId = contentShareContext.getShareScreenTile()?.tileId
    console.log("ContentVideo komponenat")
    useEffect(
        () => {
            if (!shareScreenTileId) return
            if (isScreenShareEnabled && !isLocalScreenShare) {
                videoContext.bindVideoElement(shareScreenTileId, videoElement.current as unknown as HTMLVideoElement)
            } else {
                videoContext.unbindVideoElement(shareScreenTileId)
            }
            return () => {
                if (shareScreenTileId && isScreenShareEnabled && !isLocalScreenShare)
                    videoContext.unbindVideoElement(shareScreenTileId)
            }
        },
        // eslint-disable-next-line
        [shareScreenTileId, isScreenShareEnabled, isLocalScreenShare]
    )
    let attendeeId = contentShareContext.getShareScreenTile()?.boundAttendeeId
    if (attendeeId) {
        attendeeId = attendeeId.substr(0, attendeeId.indexOf("#"))
    }

    if (isScreenShareEnabled)
        return (
            <ContentVideoRoot
                guestBannerHeight={props.guestBannerHeight}
                className={props.className}
                isLocalScreenShare={isLocalScreenShare}
            >
                {isLocalScreenShare ? (
                    <Message style={{ fontSize: "16px" }}>{strings.conferenceTexts.currentlySharingScreen}</Message>
                ) : (
                    <>
                        <video muted ref={videoElement} className={props.className} />
                        {attendeeId && <VideoNameplate attendeeId={attendeeId} />}
                    </>
                )}
            </ContentVideoRoot>
        )
    else return null
}

import { BackendServiceError, fetchDataRest, fetchDataWebService, topic } from "./BackendServicesUtils"
import { News, NewsItem } from "./Types"
import { defaultLogger as logger } from "../globalStates/AppState"

/*********************************************************************************************
 * NEWS DETAILS
 **********************************************************************************************/
export interface NewsResponse {
    content: News
}

export interface NewsDetailsParams {
    newsid: string
    rootCategories?: Array<string>
    showCategoryHierarchy?: boolean
}

export async function loadNewsData(params: NewsDetailsParams): Promise<NewsResponse> {
    try {
        const data = await fetchDataWebService("/newsdetails", params)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/newsdetails",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

/*********************************************************************************************
 * NEWS LIST
 **********************************************************************************************/
export interface NewsListResponse {
    count: number
    newsList: News[]
}

export async function loadNewsListData(params: object, signal?: AbortSignal): Promise<NewsListResponse> {
    try {
        const data = await fetchDataWebService("/search", params, signal, true)
        return { count: data.count, newsList: data.entities }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

/*********************************************************************************************
 * NEWS LIST
 **********************************************************************************************/

export interface MostReadNewsItem {
    results: { cnt: number; item: News }[]
    cnt: number
    item: News
}

export async function loadMostReadNews(profileId: string, signal?: AbortSignal): Promise<MostReadNewsItem[] | null> {
    const defaultRoute: string = `/tracking/topic/${topic}/actionreport/mostReadNews`

    try {
        const result = await fetchDataRest(defaultRoute, { profileId: profileId }, "GET")

        if ((result as any).httpStatus) {
            return null
        }
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return null
    }
}

/*********************************************************************************************
 * FEED NEWS LIST
 **********************************************************************************************/
export interface FeedNewsResponse {
    news: NewsItem[]
}

export async function loadFeedNews(): Promise<FeedNewsResponse | BackendServiceError> {
    const defaultRoute = `/news/list/${topic}`

    try {
        const data = await fetchDataRest(defaultRoute, null, "GET")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { news: data.items }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

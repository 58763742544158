import { useState, useRef, useEffect } from "react"
import * as React from "react"
import styled from "styled-components"
import branding from "../../branding/branding"
import { device } from "../../utils/Device"
import { IconChevronLeft, IconChevronRight } from "../Icons"
import { CategoryConfigType } from "../../contentArea/ContentAreaPageBranding"
import { ViewMode } from "../CrsTabs"

export const CATEGORIES_SLIDER_PREDEFINED_HEIGHT = 60

const SliderContainer = styled.div<{ showShadow?: boolean; visible: boolean }>`
    display: ${(props) => (props.visible ? "block" : "none")};
    position: relative;
    width: 100%;
    height: 60px;
    padding: 0 25px;
    box-shadow: ${(props) => (props.showShadow ? branding.primaryScrollDarkShadowTTB : "none")};

    @media ${device.laptop} {
        display: block;
    }
`

const ContentWrapper = styled.div`
    border-bottom: 1px solid ${branding.inputUnFocusedBorderColor};
    height: 100%;
    width: 100%;
    padding: 0 25px;
`

const SliderWrapper = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`

const SliderItem = styled.div<{ isActive: boolean }>`
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
    font-family: ${branding.font1};
    font-size: 14px;
    line-height: 17px;
    color: ${(props) =>
        props.isActive
            ? branding.categoriesPageContent.categorySliderItemActiveColor
            : branding.categoriesPageContent.categorySliderItemDefaultColor};
    font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
    margin-right: ${branding.exhibitorsPageContent.categoriesSliderSpacing};
    cursor: pointer;
    outline: none;

    &:last-child {
        margin-right: 0;
    }

    & span {
        white-space: nowrap;
    }
`

const ArrowWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    cursor: pointer;

    &.left {
        left: 20px;
    }

    &.right {
        right: 20px;
    }

    &.arrow-hidden {
        display: none;
    }
`
interface ArrowProps {
    isPrevArrow?: boolean
    isHidden: boolean
    onClick: (isLeft: boolean) => void
}
const Arrow = (props: ArrowProps) => {
    const { onClick, isPrevArrow, isHidden } = props
    const fillColor = branding.categoriesPageContent.categorySliderArrowsColor
    const positionClassName = isPrevArrow ? "left" : "right"
    const hiddenClassName = isHidden ? "arrow-hidden" : ""
    return (
        <ArrowWrapper
            className={`${positionClassName} ${hiddenClassName}`}
            onClick={() => {
                !isHidden && onClick(isPrevArrow ?? false)
            }}
        >
            {isPrevArrow ? IconChevronLeft({ fill: fillColor }) : IconChevronRight({ fill: fillColor })}
        </ArrowWrapper>
    )
}

interface CategoriesSliderProps {
    hideOnScroll: boolean
    filtersVisible: boolean
    categoriesConfiguration: CategoryConfigType[]
    activeSubCategory: CategoryConfigType | undefined
    onSliderItemClick: (category: CategoryConfigType, viewMode: ViewMode) => void
}
const CategoriesSlider: React.FC<CategoriesSliderProps> = (props) => {
    const SLIDER_STEP = 200
    const { hideOnScroll, filtersVisible, categoriesConfiguration, activeSubCategory } = props
    const sliderRef = useRef<HTMLDivElement>(null)
    const [leftArrowHidden, setLeftArrowHidden] = useState(false)
    const [rightArrowHidden, setRightArrowHidden] = useState(false)

    const onArrowClick = (isLeft?: boolean) => {
        if (sliderRef && sliderRef.current) {
            const scrollLeft = sliderRef.current.scrollLeft
            const newLeft = isLeft ? scrollLeft - SLIDER_STEP : scrollLeft + SLIDER_STEP
            sliderRef.current.scroll({ left: newLeft, behavior: "smooth" })
        }
    }

    const setArrowsVisibility = (scrollLeft: number, clientWidth: number, scrollWidth: number) => {
        setRightArrowHidden(scrollLeft + clientWidth === scrollWidth)
        setLeftArrowHidden(scrollLeft === 0)
    }

    const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const { scrollLeft, clientWidth, scrollWidth } = e.currentTarget
        setArrowsVisibility(scrollLeft, clientWidth, scrollWidth)
    }

    useEffect(() => {
        if (sliderRef && sliderRef.current) {
            const { scrollLeft, clientWidth, scrollWidth } = sliderRef.current
            setArrowsVisibility(scrollLeft, clientWidth, scrollWidth)
        }
    }, [sliderRef, props.categoriesConfiguration])

    if (categoriesConfiguration && categoriesConfiguration.length > 0) {
        return (
            <SliderContainer showShadow={hideOnScroll} visible={filtersVisible}>
                <ContentWrapper>
                    {/* LEFT ARROW */}
                    <Arrow isPrevArrow={true} onClick={onArrowClick} isHidden={leftArrowHidden} />

                    {/* RIGHT ARROW */}
                    <Arrow onClick={onArrowClick} isHidden={rightArrowHidden} />

                    {/* SLIDER */}
                    <SliderWrapper ref={sliderRef} onScroll={handleScroll}>
                        {categoriesConfiguration.map((config, index) => {
                            return (
                                <SliderItem
                                    key={index}
                                    isActive={activeSubCategory?.rootCategoryAlias === config.rootCategoryAlias}
                                    onClick={() =>
                                        props.onSliderItemClick(config, config.listViewAsDefault ? ViewMode.LIST : ViewMode.TILES)
                                    }
                                >
                                    <span>{config.rootCategoryLabel}</span>
                                </SliderItem>
                            )
                        })}
                    </SliderWrapper>
                </ContentWrapper>
            </SliderContainer>
        )
    }

    return null
}

export default CategoriesSlider

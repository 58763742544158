import { ReactNode, ReactElement } from "react"

export interface ConditionalWrapperProps {
    wrapper: (children: ReactNode) => ReactElement | null
}
export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = (props) => {
    const w = props.wrapper(props.children)
    const r = w ? w : props.children
    return r as ReactElement | null
}

// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { AttendeeData } from "../../backendServices/MeetingServices"

interface RosterAttendeeType extends AttendeeData {
    rosterId?: string
    muted: boolean
    signalStrength: number
    volume: number
    handRaised: boolean
}

export const rosterAttendeeTypeEquals = (val1: RosterAttendeeType, val2: RosterAttendeeType): boolean => {
    if (!val1 && !val2) return true
    if ((val1 && !val2) || (!val1 && val2)) return false

    return val1.name === val2.name && val1.rosterId === val2.rosterId && val1.muted === val2.muted
}

export default RosterAttendeeType

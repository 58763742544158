import { createState, State, useState } from "@hookstate/core";

interface contactsCache {
    [key: string]: { connectionStatus: string, userType: string }
}

const useWrapState = ((s: State<contactsCache>) => {

    const state = useState(s)
    return {
        getConnectionStatus: (id: string) => {
            return state.get()[id]?.connectionStatus
        },
        setConnectionStatus: (id: string, connectionStatus: string) => {
            if (connectionStatus !== state.get()[id]?.connectionStatus) {
                state.set(newState => {
                    newState[id] = { connectionStatus: connectionStatus, userType: newState[id]?.userType }
                    return newState
                })
            }
        },
        getUserType: (id: string) => {
            return state.get()[id]?.userType
        },
        setUserType: (id: string, userType: string) => {
            if (userType !== state.get()[id]?.userType) {
                state.set(newState => {
                    newState[id] = { userType: userType, connectionStatus: newState[id]?.connectionStatus }
                    return newState
                })
            }
        },
        setAll: (arr: { id: string, connectionStatus: string, userType: string }[]) => {
            state.set(newState => {
                arr.forEach(x => newState[x.id] = { connectionStatus: x.connectionStatus, userType: x.userType })
                return newState
            })
        }
    }
})

const state = createState({} as contactsCache)
export const useContactState = () => useWrapState(useState(state))
import branding from "../branding/branding"

export const homePageRoute: string = "/"
export const systemCheckPageRoute: string = "/check"
export const networkingPageRoute: string = "/networking"
export const relevantPeopleRoute: string = "/networking?initialViewType=RELEVANT_PPL"
export const myProfilePageRoute: string = "/myprofile"
export const editMyProfilePageRoute: string = "/myprofile?mode=EDIT"
export const helpPageRoute: string = "/help"
export const rulesPageRoute: string = "/rules"
export const termsOfUsePageRoute: string = "/termsofuse"
export const testPageRoute: string = "/test"
export const pressMediaPageRoute: string = "/press"
export const businessAreaPageRoute: string = "/business"
export const businessAreaBuyersPageRoute: string = "/business#buyers"
export const businessAreaSellersPageRoute: string = "/business#sellers"
export const businessAreaAllPageRoute: string = "/business#all"
export const iframeTestPageRoute: string = "/iframetest"

export const collectionOverviewRoute: string = "/collections"
export const collectionRoute: string = "/collections/:id"

export const hallplanRoute: string = "/hallplan/:hall?--:stand?"
export const hallplanOverviewRoute: string = "/hallplan"

/* Details */
export const organizationBackOfficePageRoute: string = "/company/:organizationName?--:organizationId/backoffice"
export const organizationDetailPageRoute: string = "/company/:organizationName?--:organizationId"
export const personDetailPageRoutePerson: string = "/person/:personName?--p-:personId"
export const personDetailPageRouteUser: string = "/person/:personName?--u-:userId"
export const eventDateDetailPageRoute: string = "/eventdate/:eventdateName?--:eventDateId"
export const productDetailPageRoute: string = "/product/:productName?--:productId"
export const newsDetailPageRoute: string = "/news/:newsName?--:newsId"
export const jobofferDetailPageRoute: string = "/joboffer/:jobofferName?--:jobofferId"
export const trademarkDetailPageRoute: string = "/trademark/:trademarkName?--:trademarkId"
export const meetingPageRoute: string = "/meeting/:roomName"
export const conferenceGroupPageRoute: string = "/meetings/:groupId"
export const videoRoomPageRoute: string = "/channel/:channelId"
export const eventDetailPageRoute: string = "/event/:eventName?--:eventId"

export const conferencePageRoute: string = "/meetings"

export const showfloorPageRoute: string = "/showfloor"
export const exhibitorsPageRoute: string = "/showfloor#organization"
export const productsPageRoute: string = "/showfloor#product"
export const trademarksPageRoute: string = "/showfloor#trademark"
export const categoriesPageRoute: string = "/showfloor#category"
export const customCategoriesPageRoute: string = "/showfloor#" + branding.exhibitorsPageContent.customCategoriesUrl
export const exhibitorNewsPageRoute: string = "/showfloor#news"
export const joboffersPageRoute: string = "/showfloor#joboffer"

export const bookmarkedSpeakersPageRoute: string = "/myspeakers"
export const bookmarkedExhibitorsPageRoute: string = "/myexhibitors"
export const bookmarkedProductsPageRoute: string = "/myproducts"
export const bookmarkedTrademarksPageRoute: string = "/mytrademarks"
export const bookmarkedNewsPageRoute: string = "/mynews"
export const bookmarkedJoboffersPageRoute: string = "/myjoboffers"
export const mySchedulePageRoute: string = "/myschedule"
export const mediaPartnerPageRoute: string = "/mediapartner"
export const exhibitorStartupPageRoute: string = "/startup"
export const globalSearchResultPageRoute: string = "/results"

export const newsPageRoute: string = "/news"
export const newsEventOrganizerPageRoute: string = "/news#eventorganizer"
export const newsOrganizationPageRoute: string = "/news#organization"
export const newsStoryPageRoute: string = "/news#story"
export const couponsPageRoute: string = "/coupon"
export const sponsorsPageRoute: string = "/sponsor"
export const programPageRoute: string = "/program"
export const speakersPageRoute: string = "/program#speakers"

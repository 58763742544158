import * as React from "react"
import { Range, getTrackBackground } from 'react-range';
import styled from 'styled-components';
import branding from '../../../branding/branding';
import { ReactRangeProps, ReactRangeThumb, ReactRangeTrack } from './ZoomRangeSlider';


const LoadSuggestionsRangeRoot = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    max-width: 400px;
    justify-content: center;
    align-items: space-between;
    margin-right: 3rem;
    margin-left: 3rem;
    & .range-track-inner {
        width: 100% !important;
    }
`

const LoadSuggestionsRangeValues = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    
`

const LoadSuggestionsRangeValue = styled.span`
    font-family: ${branding.font1};
    cursor: pointer;
    @media(max-width: 1500px) {
        font-size: .7rem;
    }
    &.disabled {
        opacity: .5;
        cursor: not-allowed;
    }
`

const LOAD_NEW_SUGGESTIONS_STEP_VALUE = 10;
const LOAD_NEW_SUGGESTIONS_MIN_VALUE = 10;
const LOAD_NEW_SUGGESTIONS_MAX_VALUE = 40;

const RangeSlider = (rootProps: ReactRangeProps) => {
    return (
        <Range
            values={rootProps.values}
            step={LOAD_NEW_SUGGESTIONS_STEP_VALUE}
            min={LOAD_NEW_SUGGESTIONS_MIN_VALUE}
            max={LOAD_NEW_SUGGESTIONS_MAX_VALUE}
            onChange={values => rootProps.onChange(values)}
            renderTrack={({ props, children }) => (
                <ReactRangeTrack
                    style={{ width: '100%' }}
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}>
                    <div
                        ref={props.ref}
                        className={'range-track-inner'}
                        style={{
                            background: getTrackBackground({
                                values: rootProps.values,
                                colors: [branding.networkingArea.networkingRangeButtonColor, "#D9D9D9"],
                                min: LOAD_NEW_SUGGESTIONS_MIN_VALUE,
                                max: LOAD_NEW_SUGGESTIONS_MAX_VALUE
                            }),
                        }}
                    >
                        {children}
                    </div>
                </ReactRangeTrack>
            )}
            renderThumb={({ props, isDragged, index }) => (
                <ReactRangeThumb {...props} key={index}>

                </ReactRangeThumb>
            )}
        />
    )
}

export interface SuggestionsSliderProps {
    value?: number
    loadSuggestionsValue: number[]
    setLoadSuggestionsValue: React.Dispatch<React.SetStateAction<number[]>>
    hasNextPage: boolean
    setCurrentDataPage: (value: React.SetStateAction<number>) => void
}

export const setLoadSuggestionsValueMethod = (props: SuggestionsSliderProps): void => {
    if (isSuggestionValueDisabled(props.value!, props.loadSuggestionsValue, props.hasNextPage))
        return
    props.setCurrentDataPage(0)
    props.setLoadSuggestionsValue([props.value!])
}

const isSuggestionValueDisabled = (
    value: number,
    loadSuggestionsValue: number[],
    hasNextPage: boolean): boolean => {
    return value > loadSuggestionsValue[0] && hasNextPage === false;
}


const SuggestionsSlider = (props: SuggestionsSliderProps) => {

    return (
        <LoadSuggestionsRangeRoot>
            <RangeSlider values={props.loadSuggestionsValue} onChange={props.setLoadSuggestionsValue} />
            <LoadSuggestionsRangeValues>
                <LoadSuggestionsRangeValue className={isSuggestionValueDisabled(10, props.loadSuggestionsValue, props.hasNextPage) ? 'disabled' : ''} onClick={() => setLoadSuggestionsValueMethod({ ...{ value: 10, ...props } })}>10</LoadSuggestionsRangeValue>
                <LoadSuggestionsRangeValue className={isSuggestionValueDisabled(20, props.loadSuggestionsValue, props.hasNextPage) ? 'disabled' : ''} onClick={() => setLoadSuggestionsValueMethod({ ...{ value: 20, ...props } })}>20</LoadSuggestionsRangeValue>
                <LoadSuggestionsRangeValue className={isSuggestionValueDisabled(30, props.loadSuggestionsValue, props.hasNextPage) ? 'disabled' : ''} onClick={() => setLoadSuggestionsValueMethod({ ...{ value: 30, ...props } })}>40</LoadSuggestionsRangeValue>
                <LoadSuggestionsRangeValue className={isSuggestionValueDisabled(40, props.loadSuggestionsValue, props.hasNextPage) ? 'disabled' : ''} onClick={() => setLoadSuggestionsValueMethod({ ...{ value: 40, ...props } })}>60</LoadSuggestionsRangeValue>
            </LoadSuggestionsRangeValues>
        </LoadSuggestionsRangeRoot>
    )
}

export default SuggestionsSlider
import * as React from "react"
import { Tooltip } from "react-bootstrap"
import styled from "styled-components"
import { NetworkingUser } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { CustomOverlayTrigger } from "../../../ui/CustomOverlayTrigger"
import { IconNetworkingMinus, IconNetworkingPlus } from "../../../ui/Icons"
import { personFilterType } from "../NetworkingPageContent"
import { viewType } from "../NetworkingPageContentBranding"

const LoadMoreConnectionsBtnRoot = styled.div<{ top: number; left: number }>`
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    top: ${(props) => props.top}px;
    left: ${(props) => props.left}px;
    font-family: ${branding.font1};
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s;
    &.active {
        opacity: 1;
    }
`

interface LoadMoreConnectionsBtnProps {
    active: boolean
    showNodeDetail: boolean
    currentViewType: viewType
    hoveredUser: string
    userAttributeFilter: string
    loggedInUserId: string | undefined
    usersWithLoadedConnections: string[]
    netChart: ZoomCharts.NetChart | undefined
    selectedPersonType: personFilterType
    loadMoreConnectionsBtnRef: React.RefObject<HTMLDivElement>
    setUserHovered: (value: React.SetStateAction<boolean>) => void
    setLoadMoreConnections: (value: React.SetStateAction<boolean>) => void
    setShouldCollapseNode: () => void
}

const LoadMoreConnectionsBtn = (props: LoadMoreConnectionsBtnProps) => {
    const languageState = useLanguageState()
    const strings = languageState.getStrings()

    if (
        !props.hoveredUser ||
        !props.netChart ||
        props.userAttributeFilter ||
        props.selectedPersonType !== personFilterType.BOTH ||
        branding.networkingArea.disableLoadContactsOfMyContacts
    )
        return null

    let hoveredNode = props.netChart.getNode(props.hoveredUser)
    let hoveredNodeDimensions = props.netChart!.getNodeDimensions(hoveredNode)

    if (!hoveredNodeDimensions) return null

    const onLoadMoreConnectionsBtnClick = () => {
        if (props.usersWithLoadedConnections.includes(hoveredNode.id)) {
            // minus- / collapse-click
            // props.netChart!.collapseNode(hoveredNode.id)
            props.setUserHovered(false)
            props.setShouldCollapseNode()
            ;(hoveredNode.data as NetworkingUser).showLoader = true
        } else if (props.netChart) {
            // plus- / expand-click
            //netChart.expandNode(hoveredUser)
            props.setUserHovered(false)
            props.setLoadMoreConnections(true)
            ;(hoveredNode.data as NetworkingUser).showLoader = true
        }
    }

    return props.hoveredUser !== props.loggedInUserId && props.currentViewType === viewType.EXPLORE_MY_CON ? (
        <LoadMoreConnectionsBtnRoot
            ref={props.loadMoreConnectionsBtnRef}
            top={hoveredNodeDimensions.y - hoveredNodeDimensions.hwidth}
            left={hoveredNodeDimensions.x - 12.5 + hoveredNodeDimensions.hwidth}
            className={props.active ? "active" : ""}
            onClick={() => {
                onLoadMoreConnectionsBtnClick()
            }}
            onMouseLeave={() => {
                if (props.showNodeDetail) return
                else props.setUserHovered(false)
            }}
            onMouseOver={() => {
                if (props.showNodeDetail) return
                else props.setUserHovered(true)
            }}
        >
            {props.active && (
                <CustomOverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="tooltip" style={{ fontFamily: branding.font1 }}>
                            {props.usersWithLoadedConnections.includes(hoveredNode.id)
                                ? !props.netChart.getNode(hoveredNode.id).expanded
                                    ? "Expand connections"
                                    : strings.networkingArea.collapseConnections
                                : strings.networkingArea.loadMoreConnectionsText}
                        </Tooltip>
                    }
                >
                    <div>
                        {props.usersWithLoadedConnections.includes(hoveredNode.id)
                            ? IconNetworkingMinus({
                                  fill: "#fff",
                                  width: "25",
                                  height: "25"
                              })
                            : IconNetworkingPlus({
                                  fill: "#fff",
                                  width: "25",
                                  height: "25"
                              })}
                    </div>
                </CustomOverlayTrigger>
            )}
        </LoadMoreConnectionsBtnRoot>
    ) : null
}

export default LoadMoreConnectionsBtn

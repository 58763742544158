import { BackendServiceError, fetchDataRest, topic } from "./BackendServicesUtils"
import { VirtualCafeAccessUser } from "./Types"
import { defaultLogger as logger } from "../globalStates/AppState"

/*********************************************************************************************
 * UserVirtualCafeAccess & UserEventDateAccess
 **********************************************************************************************/

export interface UserRestrictedAreaAccess {
    id: string
    restrictedAreaId: string
    restrictedAreaType: string
    user: VirtualCafeAccessUser
    status: string
    requestReason: string
    topic: string
    createdAt: string
}

export interface RestrictedAreaType {
    resourceName: string
    pathName: string
    value: string
}

export const RestrictedAreaTypes = {
    VirtualCafe: { value: "virtualCafe", resourceName: "uservirtualcafeaccess", pathName: "virtualcafe" } as RestrictedAreaType,
    RoundTable: { value: "roundtable", resourceName: "userroundtableaccess", pathName: "roundtable" } as RestrictedAreaType,
    PrivateEvent: { value: "privateEvent", resourceName: "usereventdateaccess", pathName: "privateevent" } as RestrictedAreaType,
    ConferenceRoom: {
        value: "conferenceroom",
        resourceName: "userconferenceroomaccess",
        pathName: "conferenceroom"
    } as RestrictedAreaType
}

export async function getUserRestrictedAreaAccess(
    userId: string,
    restrictedAreaId: string,
    restrictedAreaType: RestrictedAreaType
): Promise<UserRestrictedAreaAccess | BackendServiceError> {
    const defaultRoute: string = `/${restrictedAreaType.resourceName}/topic/${topic}/${restrictedAreaType.pathName}/${restrictedAreaId}/access/user/${userId}`
    try {
        return await fetchDataRest(defaultRoute, null, "GET")
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function sendRequestForRestrictedAreaAccess(
    userId: string,
    restrictedAreaId: string,
    requestReason: string,
    restrictedAreaType: RestrictedAreaType
): Promise<UserRestrictedAreaAccess | BackendServiceError> {
    const defaultRoute: string = `/${restrictedAreaType.resourceName}/topic/${topic}/${restrictedAreaType.pathName}/${restrictedAreaId}/access/user/${userId}`
    try {
        return await fetchDataRest(defaultRoute, null, "PUT", { requestReason: requestReason })
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: { requestReason: requestReason },
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function acceptOrDeclineUserRestrictedAreaAccess(
    userId: string,
    restrictedAreaId: string,
    action: string,
    restrictedAreaType: RestrictedAreaType
): Promise<UserRestrictedAreaAccess | BackendServiceError> {
    const defaultRoute: string = `/${restrictedAreaType.resourceName}/topic/${topic}/${restrictedAreaType.pathName}/${restrictedAreaId}/access/user/${userId}`
    try {
        return await fetchDataRest(defaultRoute, null, "POST", { action: action })
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: { action: action },
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function deleteUserRestrictedAreaAccess(
    userId: string,
    restrictedAreaId: string,
    restrictedAreaType: RestrictedAreaType
): Promise<UserRestrictedAreaAccess | BackendServiceError> {
    const defaultRoute: string = `/${restrictedAreaType.resourceName}/topic/${topic}/${restrictedAreaType.pathName}/${restrictedAreaId}/access/user/${userId}`
    try {
        return await fetchDataRest(defaultRoute, null, "DELETE", {})
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function getUserRestrictedAreaAccessList(
    userId: string,
    restrictedAreaId: string,
    params: object,
    restrictedAreaType: RestrictedAreaType
): Promise<UserRestrictedAreaAccess[] | BackendServiceError> {
    const defaultRoute: string = `/${restrictedAreaType.resourceName}/topic/${topic}/${restrictedAreaType.pathName}/${restrictedAreaId}/access`
    try {
        const data = await fetchDataRest(defaultRoute, params, "GET", {})
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return data.content
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function getAccessStatusForAllEventDates(): Promise<UserRestrictedAreaAccess[] | BackendServiceError> {
    const defaultRoute: string = `/${RestrictedAreaTypes.PrivateEvent.resourceName}/topic/${topic}/privateevent/access`
    try {
        const data = await fetchDataRest(defaultRoute, null, "GET", {})
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return data.content
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: {},
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function getAccessStatusForAllVirtualCafes(): Promise<UserRestrictedAreaAccess[] | BackendServiceError> {
    const defaultRoute: string = `/${RestrictedAreaTypes.VirtualCafe.resourceName}/topic/${topic}/virtualcafe/access`
    try {
        const data = await fetchDataRest(defaultRoute, null, "GET", {})
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return data.content
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: {},
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function getAccessStatusForAllConferenceRooms(): Promise<UserRestrictedAreaAccess[] | BackendServiceError> {
    const defaultRoute: string = `/${RestrictedAreaTypes.ConferenceRoom.resourceName}/topic/${topic}/conferenceroom/access`
    try {
        const data = await fetchDataRest(defaultRoute, null, "GET", {})
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return data.content
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: {},
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

import styled from "styled-components"

export const deviceSize = {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    mobileXL: 576,
    tablet: 768,
    laptop: 1024,
    laptopL: 1440,
    desktop: 1920,
    desktopL: 2560
}

export const device = {
    mobileS: `(min-width: ${deviceSize.mobileS}px)`,
    mobileM: `(min-width: ${deviceSize.mobileM}px)`,
    mobileL: `(min-width: ${deviceSize.mobileL}px)`,
    mobileXL: `(min-width: ${deviceSize.mobileXL}px)`,
    mobile: `(max-width: ${deviceSize.tablet}px)`,
    tablet: `(min-width: ${deviceSize.tablet}px)`,
    laptop: `(min-width: ${deviceSize.laptop}px)`,
    laptopL: `(min-width: ${deviceSize.laptopL}px)`,
    desktop: `(min-width: ${deviceSize.desktop}px)`,
    desktopL: `(min-width: ${deviceSize.desktopL}px)`
}

//for hiding parts of the pages in mobile view
export const DesktopVersionContainer = styled.div`
    display: none;

    @media ${device.tablet} {
        display: block;
    }
`

//for hiding parts of the pages in desktop view
export const MobileVersionContainer = styled.div`
    display: block;

    @media ${device.tablet} {
        display: none;
    }
`

//for special Showfloor case - (not) showing filter bar on certain screen sizes
export const HideOnMobileAndIpadContainer = styled.div`
    display: none;
    width: 100%;

    @media (min-width: 769px) {
        display: block;
    }
`

import { useEffect } from "react"
import * as React from "react"
import { Alert } from 'react-bootstrap'
import styled from 'styled-components'
import { useAppState } from '../globalStates/AppState'
import { useAlertState } from '../globalStates/AlertState'


const CrsAlertRoot = styled.div<{ isNetworkingOpen: boolean }>`
    position: fixed;
    left: ${props => props.isNetworkingOpen ? 'calc(50% - 260px)' : '50%'};
    transform: ${props => props.isNetworkingOpen ? 'translateX(calc(-50% + 130px))' : 'translateX(-50%)'};
    top: 5px;
    z-index: 1080;

    & .alert {
        opacity: 1 !important;
    }

    & button.close {
        outline: none !important;
    }
`

export enum CrsAlertType {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY",
    SUCCESS = "SUCCESS",
    DANGER = "DANGER",
    WARNING = "WARNING",
    INFO = "INFO"
}


const CrsAlert: React.FunctionComponent = () => {
    const appState = useAppState()
    const alertState = useAlertState()


    useEffect(() => {
        let alertTimeout = setTimeout(() => { alertState.hideAlert() }, alertState.getAlertData()?.duration || 5000)

        return () => { clearTimeout(alertTimeout) }
        // eslint-disable-next-line
    }, [])

    return (
        <CrsAlertRoot isNetworkingOpen={appState.isNetworkingOpen()}>
            <Alert
                variant={alertState.getAlertData()?.type.toLowerCase()}
                onClose={() => alertState.hideAlert()}
                dismissible>{alertState.getAlertData()?.message}</Alert>
        </CrsAlertRoot>
    )
}

export default CrsAlert
import {createState, useState} from '@hookstate/core';
import branding from "../branding/branding"



export interface Theme {
    sidebarColor: string,
    borderRadius: string,
    logoRadius: string,

    fontSizeBig?: string,
    fontSizeH1?: string,
    fontSizeH2?: string,
    fontSizeSmall?: string,
    fontSizeMicro?: string,
    fontSizeNano?: string,

    lineHeightBig?: string,
    lineHeightH1?: string,
    lineHeightH2?: string,
    lineHeightSmall?: string,
    lineHeightMicro?: string,
    lineHeightNano?: string, 
}
export const theme1: Theme = {
    sidebarColor: branding.theme1Color,
    borderRadius: "15px",
    logoRadius: "50%",

}
export const theme2: Theme = {
    sidebarColor: branding.theme2Color,
    borderRadius: "0",
    logoRadius: "25%",
}
export const theme3: Theme = {
    sidebarColor: "linear-gradient(176deg," + branding.theme3ColorPrimary + "0%," + branding.theme3ColorSecondary + "100%) ",
    borderRadius: "0",
    logoRadius: "0",
}

const sizes= {
    s: 0,
    m: 1,
    l: 2,
    xl: 3
}
const displaySize = {
    s: 1024,
    m: 1280, 
    l: 1920,
    xl:2560
}

const fontSizesBig= ["4rem", "6rem", "8.3rem", "10rem"]
const lineHeightsBig= ["6rem", "8rem", "10rem", "12rem"]

const fontSizesH1 = ["0.9rem", "1.2rem", "1.8rem", "3rem"]
const lineHeightsH1 = ["1.4rem", "1.6rem", "2rem", "3.4rem"]

const fontSizesH2 = ["0.8rem", "1rem", "1.3rem", "1.8rem"]
const lineHeightsH2 = ["1.2rem", "1.4rem", "1.6rem", "1.8rem",]

const fontSizesSmall = ["0.65rem", "0.8rem", "1rem", "1.5rem"]
const lineHeightsSmall = ["1rem", "1.2rem", "1.4rem", "1.6rem",]

const fontSizesMicro =["0.5rem", "0.7rem", "0.8rem", "1rem"]
const lineHeightsMicro = ["0.6rem", "0.8rem", "1rem", "1.2rem",]

const fontSizesNano = ["0.4rem", "0.5rem", "0.6rem", "0.8rem"] 
const lineHeightsNano= ["0.4rem", "0.6rem", "0.8rem", "1rem",]

const state = createState(theme1);
export function useThemeState(currentWidth: number) {
     
    let size = currentWidth <= displaySize.s ? sizes.s: currentWidth <= displaySize.m ? sizes.m : currentWidth <= displaySize.l ? sizes.l : sizes.xl;
    
    theme1.fontSizeBig = fontSizesBig[size];
    theme1.lineHeightBig = lineHeightsBig[size];

    theme1.fontSizeH1 = fontSizesH1[size];
    theme1.lineHeightH1 = lineHeightsH1[size];

    theme1.fontSizeH2 = fontSizesH2[size];
    theme1.lineHeightH2 = lineHeightsH2[size];

    theme1.fontSizeSmall = fontSizesSmall[size];
    theme1.lineHeightSmall = lineHeightsSmall[size];

    theme1.fontSizeMicro = fontSizesMicro[size];
    theme1.lineHeightMicro = lineHeightsMicro[size];
    
    theme1.fontSizeNano = fontSizesNano[size];
    theme1.lineHeightNano = lineHeightsNano[size];

    return useState(state)
}


/*********************************************************************************************
 * GET DATA PRIVACY DOCS
 **********************************************************************************************/

import { getActiveLanguage } from "../globalStates/LanguageState"
import { BackendServiceError, fetchDataRest, seriesOfTopicsName, topic } from "./BackendServicesUtils"
import { defaultLogger as logger } from "../globalStates/AppState"
import { getProfileFromBackendResponse, ProfileResponse } from "./SeriesOfTopicsUserServices"
import queryString from "query-string"
import { Auth } from "aws-amplify"
import branding from "../branding/branding"

export interface DataPrivacyDoc {
    key: string
    url: string
}
export interface DataPrivacyDocs {
    dataPrivacyDocs: [DataPrivacyDoc]
}
export async function getDataPrivacyDocs(): Promise<DataPrivacyDoc | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/dataprivacy`
    const body = {
        lang: getActiveLanguage()
    }
    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            // TODO Handle multiple Docs?
            return (data as DataPrivacyDocs).dataPrivacyDocs[0]
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * LOGIN WITH PASSWORD
 **********************************************************************************************/

export async function loginWithPassword(
    email: string,
    password: string,
    privacyDocs: DataPrivacyDocs
): Promise<ProfileResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile`

    const customLoginData = getCustomLoginData()

    const body = {
        email: email,
        lang: getActiveLanguage(),
        dataPrivacyDocs: privacyDocs.dataPrivacyDocs,
        password: password,
        customData: customLoginData
    }

    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body, undefined, true)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return getProfileFromBackendResponse(data)
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            body,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * LOGIN WITH SSO
 **********************************************************************************************/

export async function loginWithSSO(
    ssoToken: string,
    privacyDocs: DataPrivacyDocs
): Promise<ProfileResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile`

    const body = {
        ssoToken: ssoToken,
        lang: getActiveLanguage(),
        dataPrivacyDocs: privacyDocs.dataPrivacyDocs
    }

    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body, undefined, true)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return getProfileFromBackendResponse(data)
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            body,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * REGISTER PROFILE
 **********************************************************************************************/

export async function registerProfile(
    email: string,
    privacyDocs: DataPrivacyDocs
): Promise<{ profileId: string } | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile`
    const body = {
        email: email,
        lang: getActiveLanguage(),
        dataPrivacyDocs: privacyDocs.dataPrivacyDocs
    }

    function dummyFunction() {
        //added just so the dontForceLogout value can be passed without problems
        return
    }

    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body, dummyFunction, true)
        if ((data as BackendServiceError).httpStatus || (data as BackendServiceError).responseJson?.errorCode) {
            return data
        } else {
            return { profileId: data.profileId }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            body,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * LOGOUT
 **********************************************************************************************/

export interface TokenResponse {
    beConnectionToken: string
}

export async function logout(profileId: string): Promise<TokenResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/logout`
    try {
        const data = await fetchDataRest(defaultRoute, null, "POST")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            try {
                await Auth.signOut()
            } catch (error: any) {
                logger.warn({
                    message: "BackendServices unable to logout from cognito",
                    errorMessage: error.message,
                    errorStack: error.stack
                })
            }
            return { beConnectionToken: data.beConnectionToken }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * APP DEVICE & Login
 **********************************************************************************************/

export async function createAppDevice(): Promise<TokenResponse | BackendServiceError> {
    const defaultRoute: string = `/appdevice/sot/${seriesOfTopicsName}`
    const body = {
        kind: "web",
        lang: getActiveLanguage()
    }
    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { beConnectionToken: data.beConnectionToken }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            body,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * PERSONALIZE TICKET
 **********************************************************************************************/
export async function personalizeTicket(redeemCode: string): Promise<ProfileResponse | BackendServiceError> {
    const defaultRoute: string = `/ticket/topic/${topic}/personalize`

    const body = {
        lang: getActiveLanguage(),
        redeemCode: redeemCode
    }

    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body, undefined, true)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return data // todo
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            body,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/* #region  COGNITO */

export async function cognitoLogin(profileId: string, beConnectionToken: string, email: string) {
    // although the pw parameter is nullable, it cannot be left empty (undefined, null or empty string) because amplify throws an error otherwise
    // see https://github.com/aws-amplify/amplify-js/issues/5623
    // The password must always be the same, because after the authentication by the guide backend we don't care about it and therefore Cognito should not check it.
    return Auth.signIn(profileId, "password", {
        topicName: branding.configuration.topicName,
        userPoolName: branding.configuration.userPoolName,
        beConnectionToken,
        email
    })
}

export async function cognitoCurrentAuthUser() {
    return Auth.currentAuthenticatedUser()
}
/* #endregion */

/* #region  HELPERS */
function getCustomLoginData(): object {
    const queryParams: any = queryString.parse(window.location.search)
    const serverHint = queryParams.SIDwebserver
    if (serverHint) {
        return {
            SIDwebserver: serverHint
        }
    }
    return {}
}

/* #endregion */

/* tslint:disable */
/* eslint-disable */
// these are specific subscriptions

/*
* created by AMerkle
*/
export const onNotificationCreatedByLambda = /* GraphQL */ `
  subscription OnNotificationCreatedByLambda($userId: ID!) {
    onNotificationCreated(userId: $userId) {
      userId
      type
      content
    }
  }
`;

export const onMessageCreatedLight = /* GraphQL */ `
  subscription OnMessageCreatedLight($conversationId: ID!) {
    onMessageCreated(conversationId: $conversationId) {
      id
      authorId
      content
      conversationId
      isSent
      createdAt
    }
  }
`;

export const onConversationUpdatedLight = /* GraphQL */ `
  subscription OnConversationUpdatedLight($id: ID!) {
    onConversationUpdated(id: $id) {
      name
      description
    }
  }
`;

export const onUserConversationDeletedLight = /* GraphQL */ `
  subscription OnUserConversationDeletedLight($userId: ID!) {
    onUserConversationDeleted(userId: $userId) {
      id
      conversationId
    }
  }
`;

export const onUserConversationUpdatedLight = /* GraphQL */ `
  subscription OnUserConversationUpdatedLight($userId: ID!) {
    onUserConversationUpdated(userId: $userId) {
      conversation {
        id
        name
        mostRecentMessage
        mostRecentMessageCreatedAt
      }
    }
  }
`;

export const onUserConversationCreatedByConversationLight = /* GraphQL */ `
  subscription OnUserConversationCreatedByConversationLight($conversationId: ID!) {
    onUserConversationCreatedByConversation(conversationId: $conversationId) {
      user {
        id
        name
        pictureUrl
      }
    }
  }
`;

export const onUserConversationDeletedByConversationLight = /* GraphQL */ `
  subscription OnUserConversationDeletedByConversationLight($conversationId: ID!) {
    onUserConversationDeletedByConversation(conversationId: $conversationId) {
      userId
    }
  }
`;

export const onCalendarEntryParticipationCreatedLight = /* GraphQL */ `
  subscription OnCalendarEntryParticipationCreatedLight($userId: ID!) {
    onCalendarEntryParticipationCreated(userId: $userId) {
      id
    }
  }
`;

export const onCalendarEntryParticipationDeletedLight = /* GraphQL */ `
  subscription OnCalendarEntryParticipationDeletedLight($userId: ID!) {
    onCalendarEntryParticipationDeleted(userId: $userId) {
      id
    }
  }
`;

export const onMeetingInviteChangedLight = /* GraphQL */ `
  subscription OnMeetingInviteChangedLight($id: ID!) {
    onMeetingInviteChanged(id: $id) {
      id
      meetingId
      meeting {
        id
        start
        end
        createdAt
        updatedAt
      }
      inviterId
      inviteeId
      status
      created
      createdAt
      updatedAt
      inviter {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        createdAt
        updatedAt
      }
      invitee {
        id
        presenceStatus
        lastConnected
        name
        pictureUrl
        createdAt
        updatedAt
      }
    }
  }
`;

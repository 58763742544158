import * as React from "react"
import { isAndroid, isIOS } from "react-device-detect"
import styled from "styled-components"
import branding from "./branding/branding"
import { useLanguageState } from "./globalStates/LanguageState"

const MobileWebsiteCheckSiteRoot = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    overflow-y: hidden;
    height: 100vh;
    font-family: ${branding.font1};

    &.scrollbar-hidden::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for Firefox */
    &.scrollbar-hidden {
        scrollbar-width: none;
    }
`

const MobileWebsiteCheckStyleOverlay = styled.div`
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgb(229, 229, 229, 0.9);
`

const MobileWebsiteCheckStyleContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
`

const InfoRoot = styled.div`
    position: relative;
    height: 100vh;
    background: #fff;
`

const InfoContainer = styled.div`
    margin: 0;
    position: absolute;
    top: 33%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    width: 90%;

    @media (min-width: 700px) {
        width: 600px;
        top: 50%;
    }

    @media (max-height: 700px) {
        bottom: 0;
        top: 40%;
    }
`

const FairLogo = styled.div`
    width: 6rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
`

const Subtitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
`

const Text = styled.div`
    font-size: 16px;
    line-height: 18.75px;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-left: 18px;
    margin-right: 18px;
    font-weight: 400;
    color: ${branding.loginRegistrationSite.mobileLoginTextColor};
`

const ButtonsContainer = styled.div`
    margin-top: 40px;
`

const MainButton = styled.button`
    flex: 0 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 7px;
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: ${branding.loginRegistrationSite.mobileLoginButtonColor};
    color: #fff;
    transition: 0.5s;
    cursor: pointer;
    width: 345px;
    outline: none;

    @media (max-width: 480px) {
        width: 290px;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        opacity: 0.7;
    }
`

const SimpleButton = styled.div`
    color: ${branding.loginRegistrationSite.mobileLoginButtonColor};
    cursor: pointer;
    font-weight: 700;
    margin-left: auto;
    margin-right: auto;

    &:hover {
        text-decoration: underline;
    }
`

const FooterImage = styled.div`
    bottom: 0;
    position: fixed;
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: center;
    background: url(/branding/mobile_check_background.png);

    background-size: cover;
    background-repeat: no-repeat;
`

export const onAppDownloadClick = (strings: any) => {
    if (isAndroid) {
        window.open(`${strings.loginRegistrationSite.androidAppUrl}`, "_blank")
    } else if (isIOS) {
        window.open(`${strings.loginRegistrationSite.iOSAppUrl}`, "_blank")
    } else {
        window.open(`${strings.loginRegistrationSite.androidAppUrl}`, "_blank")
    }
}

const MobileWebsiteCheck: React.FunctionComponent = () => {
    const strings = useLanguageState().getStrings()

    const onContinueWithMobileClick = () => {
        localStorage.removeItem("virtualGuide-mobileWebsiteEnabled")
        localStorage.setItem("virtualGuide-mobileWebsiteEnabled", "true")
        window.location.reload()
    }

    return (
        <MobileWebsiteCheckSiteRoot className="scrollbar-hidden">
            <MobileWebsiteCheckStyleContainer>
                <MobileWebsiteCheckStyleOverlay>
                    <InfoRoot>
                        <InfoContainer>
                            <FairLogo>
                                <img
                                    src={
                                        branding.loginRegistrationSite.useCustomLogoForMobile
                                            ? "/branding/logo_mobile.png"
                                            : "/branding/logo.png"
                                    }
                                    alt={strings.configuration.ogTitle}
                                />
                            </FairLogo>
                            <Subtitle>{strings.loginRegistrationSite.moreOptionsForMobileTitle}</Subtitle>
                            <Text>{strings.loginRegistrationSite.moreOptionsForMobileDescription}</Text>
                            <ButtonsContainer>
                                <MainButton onClick={() => onAppDownloadClick(strings)}>
                                    {strings.loginRegistrationSite.downloadMobileAppButtonText}
                                </MainButton>
                                <SimpleButton>
                                    <p onClick={onContinueWithMobileClick}>
                                        {strings.loginRegistrationSite.continueWithMobileButtonText}
                                    </p>
                                </SimpleButton>
                            </ButtonsContainer>
                        </InfoContainer>
                    </InfoRoot>

                    {branding.loginRegistrationSite.appDownloadModeFooterShown && <FooterImage />}
                </MobileWebsiteCheckStyleOverlay>
            </MobileWebsiteCheckStyleContainer>
        </MobileWebsiteCheckSiteRoot>
    )
}

export default MobileWebsiteCheck

import * as React from "react"
import styled from "styled-components"
import { Link, useRouteMatch } from "react-router-dom"
import branding from "../branding/branding"
import { useLanguageState } from "../globalStates/LanguageState"
import { meetingPageRoute } from "../navigationArea/RoutePaths"

const BackendErrorContainer = styled.div<{ isMeetingPage: any }>`
    margin-left: 7%;
    margin-right: 7%;
    color: ${(props) =>
        props.isMeetingPage ? branding.backendError.backendErrorDarkColor : branding.backendError.backendErrorColor};
`

const ErrorButtonContainer = styled.div`
    height: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`

const ErrorButton = styled(Link)<{ width?: string; isMeetingPage: any }>`
    font-size: 14px;
    width: ${(props) => (props.width ? props.width : "auto")};
    font-family: ${branding.font1};
    border-radius: 5px;
    border: 1px solid;
    border-color: ${(props) =>
        props.isMeetingPage ? branding.backendError.backendErrorDarkColor : branding.backendError.backendErrorColor};
    background-color: transparent;
    color: ${(props) =>
        props.isMeetingPage ? branding.backendError.backendErrorDarkColor : branding.backendError.backendErrorColor};
    padding: 0.4rem 2rem;
    text-align: center;
    &:hover {
        text-decoration: none;
        color: ${(props) =>
            props.isMeetingPage ? branding.backendError.backendErrorDarkColor : branding.backendError.backendErrorColor};
    }
`

const ErrorMessage = styled.div<{ textAlign?: string }>`
    font-family: ${branding.font1};
    font-size: 14px;
    text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
    width: inherit;
    white-space: normal;
    overflow: hidden;
`

interface BackendErrorProps {
    textAlign?: string
    buttonWidth?: string
    errorMessage?: string
    textColor?: string
    backgroundColor?: string
}

const BackendError: React.FunctionComponent<BackendErrorProps> = (props: BackendErrorProps) => {
    const strings = useLanguageState().getStrings().backendError
    const isMeetingPage = useRouteMatch(meetingPageRoute)

    return (
        <BackendErrorContainer className={"backend-err-cont"} isMeetingPage={isMeetingPage}>
            <ErrorMessage textAlign={props.textAlign} style={{ color: props.textColor, backgroundColor: props.backgroundColor }}>
                {props.errorMessage ? props.errorMessage : strings.errorMessage}
            </ErrorMessage>
            <ErrorButtonContainer>
                <ErrorButton
                    to={{ pathname: "/", state: { reload: true } }}
                    width={props.buttonWidth}
                    isMeetingPage={isMeetingPage}
                >
                    {strings.errorButtonTitle}
                </ErrorButton>
            </ErrorButtonContainer>
        </BackendErrorContainer>
    )
}

export default BackendError

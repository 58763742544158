import React from "react"
import { OverlayTrigger } from "react-bootstrap"
import { OverlayChildren, Placement } from "react-bootstrap/esm/Overlay"
import { OverlayDelay, OverlayTriggerType } from "react-bootstrap/esm/OverlayTrigger"
import { ConditionalWrapper } from "./ConditionalWrapper"

interface CustomOverlayTriggerProps {
    placement?: Placement | undefined
    delay?: OverlayDelay | undefined
    trigger?: OverlayTriggerType | OverlayTriggerType[] | undefined
    show?: boolean | undefined
    overlay: OverlayChildren
}

export const CustomOverlayTrigger: React.FC<CustomOverlayTriggerProps> = (props) => {
    const { placement, delay, overlay, trigger, show } = props
    const isTouchDevice = "ontouchstart" in window

    return (
        <ConditionalWrapper
            wrapper={(children: any) =>
                isTouchDevice ? null : (
                    <OverlayTrigger placement={placement} delay={delay} overlay={overlay} trigger={trigger} show={show}>
                        {children}
                    </OverlayTrigger>
                )
            }
        >
            {props.children}
        </ConditionalWrapper>
    )
}

import * as React from "react"
import { useState, useEffect } from "react"
import { Entity, News } from "../../backendServices/Types"
import branding from "../../branding/branding"
import { getNewsDateFormatted } from "../detailPages/components/DetailPageSections"
import { DetailNavLink } from "../detailPages/DetailNavLink"
import { useLanguageState } from "../../globalStates/LanguageState"
import { MobileVersionContainer } from "../../utils/Device"
import BadgeArea from "../../ui/BadgeArea"
import BookmarkWithToggle from "../../ui/BookmarkWithToggle"
import { SectionHeaderTitle } from "../../ui/CompaniesTilesLayout"
import { IconNewsLink } from "../../ui/Icons"
import {
    LinkIcon,
    Image,
    TopSectionRoot,
    NewsSlider,
    GeneralSectionRoot,
    GeneralDateBookmarkContainer,
    NewsDate,
    NewsTitle,
    NewsTeaser,
    TileRoot,
    TopBookmarkContainer,
    TopDateTitleContainer,
    Prio1Container,
    Prio2Container,
    MostReadSectionTitle,
    MostReadSectionRoot,
    MostReadItemRoot,
    MostReadItemLogoRoot,
    MostReadItemLogo,
    MostReadItemContent,
    MostReadSectionTileWrapper,
    NewsLinkIconContainer
} from "./NewsPageSectionsStyledComponents"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { Col, Row } from "react-bootstrap"
import { timeAgo } from "../../utils/DateUtils"
import TileRow from "../../ui/TileRow"
import { trackNewsAction } from "../../backendServices/TrackingServices"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { sectionOrder, Sections, SectionType } from "../../utils/searchUtils"

export function getNewsSections(sections: Sections, searchKrit?: string): News[][] {
    let newsSections: News[][] = [[], []]

    sectionOrder.forEach((sectionType: SectionType) => {
        switch (sectionType) {
            case SectionType.TOP: {
                const section = sections[sectionType]
                if (section) {
                    const entitiesList = section.entities.sort(function (a: Entity, b: Entity) {
                        return new Date((b as News).date).valueOf() - new Date((a as News).date).valueOf()
                    })

                    entitiesList.forEach((entity: Entity) => {
                        const newsItem: News = entity as News

                        //Prio 1 group of the Top section always shows one piece of news at a time.
                        //This would always be the newest piece of news with the TOTL value of 0.
                        //All other news of this Prio group are sorted normally in the general section below.
                        if (newsItem.totl === 0 && newsSections[0].findIndex((n: News) => n.totl === 0) === -1) {
                            newsSections[0].push(newsItem)
                        }

                        //Prio 2 group of the Top section always shows four news at a time.
                        //This would always be the four newest pieces of news with the TOTL value greater than 0.
                        //All other news of this Prio group are sorted normally in the general section below.}
                        else if (newsItem.totl > 0 && newsSections[0].filter((n: News) => n.totl > 0).length < 4) {
                            newsSections[0].push(newsItem)
                        }
                    })
                }
                break
            }

            case SectionType.ALL: {
                const section = sections[sectionType]
                if (section) {
                    const entitiesList = section.entities

                    entitiesList.forEach((entity: Entity) => {
                        const newsItem: News = entity as News

                        if (newsSections[0].findIndex((n: News) => n.id === newsItem.id) > -1) {
                            //we don't display the news that are already shown in the Top section
                            return
                        }

                        newsSections[1].push(newsItem)
                    })
                }
                break
            }
        }
    })

    return newsSections
}

interface NewsPageSectionsProps {
    sections: Sections
    searchKrit?: string
    showOnlyBookmarks: boolean
    mostReadNewsList: News[]
    mostReadSectionVisible: boolean
}

const NewsPageSections: React.FunctionComponent<NewsPageSectionsProps> = React.memo((props: NewsPageSectionsProps) => {
    const windowSize = useWindowDimensions()
    const { useMobileDesign } = useWindowDimensions()
    const [newsSections, setNewsSections] = useState<News[][]>([])

    const showMostReadSection = (): boolean => {
        //we don't want to show Most Read section when a category filter is selected, or "Show only bookmarks" toggle is enabled
        return (
            props.mostReadSectionVisible &&
            props.mostReadNewsList.length > 0 &&
            !props.showOnlyBookmarks &&
            (props.searchKrit === undefined || (props.searchKrit !== undefined && props.searchKrit?.length! === 0))
        )
    }

    const getGeneralSectionCol = (): number => {
        return !showMostReadSection() || useMobileDesign ? 12 : windowSize.width > 3400 ? 10 : 9
    }

    const getMostReadSectionCol = (): number => {
        return windowSize.width > 3400 ? 2 : 3
    }

    useEffect(() => {
        setNewsSections(getNewsSections(props.sections, props.searchKrit))
        // eslint-disable-next-line
    }, [props.sections])

    return (
        <>
            {newsSections.map((section: News[], sectionIndex: number) => {
                switch (sectionIndex) {
                    case 0:
                        return props.searchKrit ? null : <TopSection newsList={section} searchKrit={props.searchKrit} />
                    case 1:
                        return (
                            <>
                                {showMostReadSection() && (
                                    <MobileVersionContainer>
                                        <MostReadSection newsList={props.mostReadNewsList} searchKrit={props.searchKrit} />
                                    </MobileVersionContainer>
                                )}
                                <Row style={{ marginRight: "20px" }}>
                                    <Col xs={getGeneralSectionCol()} style={{ paddingRight: "0px" }}>
                                        <GeneralSection
                                            newsList={section}
                                            searchKrit={props.searchKrit}
                                            className={showMostReadSection() ? "" : "mostReadSectionNotVisible"}
                                        />
                                    </Col>
                                    {showMostReadSection() && !useMobileDesign && (
                                        <Col xs={getMostReadSectionCol()}>
                                            <MostReadSection newsList={props.mostReadNewsList} searchKrit={props.searchKrit} />
                                        </Col>
                                    )}
                                </Row>
                            </>
                        )
                    default:
                        return null
                }
            })}
        </>
    )
})

export default NewsPageSections

interface NewsSectionProps {
    newsList: News[]
    searchKrit?: string
    className?: string
}

export const TopSection: React.FunctionComponent<NewsSectionProps> = (props: NewsSectionProps) => {
    const { useMobileDesign } = useWindowDimensions()

    const [prio1Item, setPrio1Item] = useState<News>()
    const [prio2Items, setPrio2Items] = useState<News[]>([])

    useEffect(() => {
        setPrio1Item(props.newsList.find((n: News) => n.totl === 0))
        setPrio2Items(props.newsList.filter((n: News) => n.totl > 0).slice(0, 4))
        // eslint-disable-next-line
    }, [props.newsList])

    const getNewsForMainSlider = (): News[] => {
        //in mobile view all news from the top section are put into the slider, without affecting the order
        let newsForMainSlider: News[] = []

        if (prio1Item) newsForMainSlider.push(prio1Item)

        prio2Items.forEach((item: News) => {
            newsForMainSlider.push(item)
        })

        return newsForMainSlider
    }

    const renderSlides = () => {
        return getNewsForMainSlider().map((item: News, index: number) => (
            <NewsTile key={index} newsItem={item} searchKrit={props.searchKrit} prio="top" />
        ))
    }

    return (
        <TopSectionRoot className={props.className ?? ""}>
            {useMobileDesign ? (
                <NewsSlider
                    dots={true}
                    slidesToShow={1}
                    slidesToScroll={1}
                    autoplay={getNewsForMainSlider().length > 1}
                    autoplaySpeed={branding.newsPageContent.topSectionSliderAutoplayValue ?? 10000}
                >
                    {renderSlides()}
                </NewsSlider>
            ) : (
                <>
                    {prio1Item && (
                        <Prio1Container>
                            <NewsTile key={prio1Item.id} newsItem={prio1Item} searchKrit={props.searchKrit} prio="top" />
                        </Prio1Container>
                    )}
                    {prio2Items.length > 0 && (
                        <Prio2Container>
                            {prio2Items.map((item: News) => {
                                return <NewsTile key={item.id} newsItem={item} searchKrit={props.searchKrit} prio="mid" />
                            })}
                        </Prio2Container>
                    )}
                </>
            )}
        </TopSectionRoot>
    )
}

export const GeneralSection: React.FunctionComponent<NewsSectionProps> = (props: NewsSectionProps) => {
    const title = branding.exhibitorsPageContent.sectionHeaderAllExhibitors
        .split("{$count}")
        .join(props.newsList.length.toString())

    return (
        <>
            {props.searchKrit && props.searchKrit?.length > 0 && (
                <MobileVersionContainer>
                    <SectionHeaderTitle style={{ margin: "10px 0px 15px 20px" }}>{title}</SectionHeaderTitle>
                </MobileVersionContainer>
            )}
            <GeneralSectionRoot id="generalSection" className={props.className ?? ""}>
                {props.newsList.map((item: News, index: number) => {
                    return <NewsTile key={index} newsItem={item} searchKrit={props.searchKrit} />
                })}
            </GeneralSectionRoot>
        </>
    )
}

export const MostReadSection: React.FunctionComponent<NewsSectionProps> = (props: NewsSectionProps) => {
    const { useMobileDesign } = useWindowDimensions()
    var contentElement = document.getElementById("generalSection")

    return (
        <MostReadSectionRoot
            style={{ height: !useMobileDesign && contentElement ? contentElement?.offsetHeight?.toString() + "px" : "auto" }}
        >
            <MostReadSectionTitle>{branding.newsPageContent.mostReadSectionTitle}</MostReadSectionTitle>
            {useMobileDesign ? (
                <TileRow
                    iconVisible={false}
                    title=""
                    titleVisible={false}
                    navLinkTextVisible={false}
                    hideShadows={false}
                    offsetLeft={0}
                    navLinkText=""
                    scrollBar={false}
                    childWidth={135 + 10}
                    childCount={props.newsList.length}
                    customMargin="-45px 0px 0 0px"
                >
                    <MostReadSectionTileWrapper count={props.newsList.length}>
                        {props.newsList.map((item: News, index: number) => (
                            <div style={{ width: 135, height: "auto" }} key={index}>
                                <NewsTile newsItem={item} mostRead />
                            </div>
                        ))}
                    </MostReadSectionTileWrapper>
                </TileRow>
            ) : (
                <>
                    {props.newsList.map((item: News, index: number) => {
                        return <NewsTile key={index} newsItem={item} mostRead />
                    })}
                </>
            )}
        </MostReadSectionRoot>
    )
}

interface NewsTileProps {
    newsItem: News
    searchKrit?: string
    prio?: string
    mostRead?: boolean
}

const NewsTile: React.FunctionComponent<NewsTileProps> = (props: NewsTileProps) => {
    const windowSize = useWindowDimensions()
    const { useMobileDesign } = useWindowDimensions()
    const userState = useLoggedInState()
    const profileId = userState.user()?.profileId!
    const lang = useLanguageState().getLanguage()
    const [bookmarkVisible, setBookmarkVisible] = useState<boolean>(false)

    const getNewsDate = (): string | undefined => {
        return getNewsDateFormatted(props.newsItem.date, lang, branding.eventTiming.eventDaysFormatPatternNewsList)
    }

    const getMostReadLogoCol = (): number => {
        return useMobileDesign ? 12 : windowSize.width > 2600 ? 2 : 3
    }

    const getMostReadContentCol = (): number => {
        return windowSize.width > 2600 ? 10 : 9
    }

    let content: JSX.Element = props.prio ? (
        <Image src={props.newsItem.logoUrl ?? "/branding/no-news-image.png"} className={props.prio ?? ""}>
            <TopBookmarkContainer className={props.prio ?? ""}>
                {bookmarkVisible && (
                    <BookmarkWithToggle
                        newBookmarkItem
                        fontSize="20px"
                        color="#fff"
                        favIconBasic={true}
                        type="news"
                        id={props.newsItem.id}
                        name={props.newsItem.name as string}
                    />
                )}
            </TopBookmarkContainer>

            <TopDateTitleContainer>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    {props.newsItem.date && <NewsDate className={props.prio ?? ""}>{getNewsDate()}</NewsDate>}
                    {props.newsItem.externalUrl && (
                        <NewsLinkIconContainer>
                            {IconNewsLink({
                                fill: "#fff",
                                height: "13px",
                                width: "13px"
                            })}
                        </NewsLinkIconContainer>
                    )}
                </div>
                <NewsTitle className={props.prio ?? ""}>{props.newsItem.name}</NewsTitle>
                {props.newsItem.categories && props.newsItem.categories?.length! > 0 && (
                    <BadgeArea
                        maxBadgeCount={branding.exhibitorsPageContent.exhibitorTilesMaxBadgeCount}
                        categories={props.newsItem.categories}
                        marginTop="15px"
                        marginBottom="0"
                        fontSize={branding.categoryBadgesShowfloorTextSize}
                    />
                )}
            </TopDateTitleContainer>
        </Image>
    ) : (
        <>
            <Image
                src={props.newsItem.logoUrl ?? "/branding/no-news-image.png"}
                className={"general " + (bookmarkVisible ? "hover" : "")}
            >
                <LinkIcon>
                    {bookmarkVisible && (
                        <BookmarkWithToggle
                            newBookmarkItem
                            fontSize="15px"
                            color="#fff"
                            favIconBasic={true}
                            type="news"
                            id={props.newsItem.id}
                            name={props.newsItem.name as string}
                        />
                    )}
                </LinkIcon>
            </Image>
            <GeneralDateBookmarkContainer>
                <NewsDate>{props.newsItem.date && getNewsDate()}</NewsDate>

                {props.newsItem.externalUrl &&
                    IconNewsLink({
                        fill: branding.newsPageContent.newsTilesLayoutDateTimeColor,
                        height: "15px",
                        width: "15px"
                    })}
            </GeneralDateBookmarkContainer>
            <NewsTitle>{props.newsItem.name}</NewsTitle>
            <NewsTeaser>{props.newsItem.descriptionTeaser}</NewsTeaser>
        </>
    )

    if (props.mostRead) {
        content = (
            <MostReadItemRoot>
                <Col xs={getMostReadLogoCol()} style={{ paddingLeft: "0px" }}>
                    <MostReadItemLogoRoot>
                        <MostReadItemLogo src={props.newsItem.logoUrl ?? "/branding/no-news-image.png"} alt="" />
                    </MostReadItemLogoRoot>
                    <MobileVersionContainer>
                        <BadgeArea categories={props.newsItem.categories || []} simpleLayout />
                        <NewsTitle className="mostRead">{props.newsItem.name}</NewsTitle>
                    </MobileVersionContainer>
                </Col>
                {!useMobileDesign && (
                    <Col xs={getMostReadContentCol()} style={{ paddingLeft: "0px" }}>
                        <MostReadItemContent>
                            <BadgeArea categories={props.newsItem.categories || []} simpleLayout />
                            <NewsTitle className="mostRead">{props.newsItem.name}</NewsTitle>
                            {props.newsItem.date && <NewsDate>{timeAgo(lang, new Date(props.newsItem.date))}</NewsDate>}
                        </MostReadItemContent>
                    </Col>
                )}
            </MostReadItemRoot>
        )
        return (
            <div
                onClick={() => {
                    trackNewsAction(profileId, "news", props.newsItem.id)
                }}
            >
                {props.newsItem.externalUrl ? (
                    <a href={props.newsItem.externalUrl} target="_blank" rel="noopener noreferrer">
                        {content}
                    </a>
                ) : (
                    <DetailNavLink
                        id={props.newsItem.id}
                        type="news"
                        name={props.newsItem.name}
                        source="NEWSLIST"
                        searchKrit={props.searchKrit}
                    >
                        {content}
                    </DetailNavLink>
                )}
            </div>
        )
    }

    return (
        <TileRoot
            onMouseEnter={() => {
                setBookmarkVisible(true)
            }}
            onMouseLeave={() => {
                setBookmarkVisible(false)
            }}
            onClick={() => {
                trackNewsAction(profileId, "news", props.newsItem.id)
            }}
            className={props.prio ?? "general"}
        >
            {props.newsItem.externalUrl ? (
                <a href={props.newsItem.externalUrl} target="_blank" rel="noopener noreferrer">
                    {content}
                </a>
            ) : (
                <DetailNavLink
                    id={props.newsItem.id}
                    type="news"
                    name={props.newsItem.name}
                    source="NEWSLIST"
                    searchKrit={props.searchKrit}
                >
                    {content}
                </DetailNavLink>
            )}
        </TileRoot>
    )
}

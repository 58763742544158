import * as React from "react"
import styled from "styled-components"
import { useFavoriteState } from "../globalStates/Favorites"
import { syncFavorites } from "../backendServices/FavoritesServices"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import moment from "moment"
import { IconBookmark, IconBookmarkFilled, IconFavorite, IconFavoriteFilled } from "./Icons"
import { EntityType } from "../backendServices/Types"
import branding from "../branding/branding"
import {
    ShareBookmarkButton,
    ShareBookmarkButtonIcon,
    ShareBookmarkButtonText
} from "../contentArea/detailPages/PersonDetailPageContent"

const ActionIcon = styled.div`
    position: absolute;
    cursor: pointer;
`
interface FavIconProps {
    color: string
    right: string
    fontSize: string
    left: string
    top: string
    marginleft: string
    marginright: string
}
const FavIcon = styled(ActionIcon)<FavIconProps>`
    color: ${(props) => props.color};
    right: ${(props) => props.right};
    font-size: ${(props) => props.fontSize};
    left: ${(props) => props.left};
    top: ${(props) => props.top};
    margin-left: ${(props) => props.marginleft};
    margin-top: ${(props) => props.marginright};
`

export const NewFavIcon = styled.div`
    cursor: pointer;
`

interface BookmarkWithToggleProps {
    type: EntityType
    id: string
    name: string
    favIconNone?: boolean
    favIconBasic?: boolean
    color?: string
    stroke?: string
    fontSize?: string
    newBookmarkItem?: boolean
    className?: string
    fullButton?: boolean
    favouriteButton?: boolean
    buttonText?: string
    customFunction?: () => void
    customSize?: string
}

export const BookmarkWithToggle: React.FC<BookmarkWithToggleProps> = (props) => {
    const favoriteState = useFavoriteState()
    //const appState = useAppState()
    const userState = useLoggedInState()

    const profileId = userState.user()?.profileId
    let date = new Date()
    date.setMinutes(date.getMinutes() - 1)

    function syncFav(type: EntityType) {
        if (profileId) {
            syncFavorites({
                profileId: profileId,
                body: {
                    currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), //"2020-01-01 12:00:00",
                    lastSyncTime: favoriteState.getLastSyncTime(),
                    changedFavorites: [
                        {
                            id: props.id,
                            kind: type.toUpperCase(),
                            deleted: favoriteState.is(type, props.id) ? false : true,
                            lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }
                    ]
                }
            })
                .then((resp) => {
                    favoriteState.setLastSyncTime(new Date())
                })
                .catch((e: { message: React.SetStateAction<string> }) => {
                    // syncFavorites failed, logged in BackendServices
                })
        }
    }
    const isNone = props.favIconNone
    const color = props.color
        ? props.color
        : props.type !== "organization"
        ? "#969696"
        : props.favIconBasic
        ? branding.sideIconBar.sideIconColorDark
        : "#FFFFFF"
    const icon1 =
        props.type !== "organization" && (props.type === "sotuser" || props.type === "person" || props.type === "networking_user")
            ? props.favouriteButton === true
                ? IconFavoriteFilled({ fill: color, width: props.customSize ?? "20", height: props.customSize ?? "20" })
                : IconBookmarkFilled({ fill: color, width: props.customSize ?? "20", height: props.customSize ?? "20" })
            : IconBookmarkFilled({ fill: color, width: props.customSize ?? "20", height: props.customSize ?? "20" })
    const icon2 =
        props.type !== "organization" && (props.type === "sotuser" || props.type === "person" || props.type === "networking_user")
            ? props.favouriteButton === true
                ? IconFavorite({ fill: color, stroke: color })
                : IconBookmark({ fill: color, width: props.customSize ?? "20", height: props.customSize ?? "20" })
            : IconBookmark({ fill: color, width: props.customSize ?? "20", height: props.customSize ?? "20" })
    const top = props.type !== "organization" || !isNone ? "0" : "50%"
    const left = props.type !== "organization" || !isNone ? "" : "50%"
    const right = props.type !== "organization" || !isNone ? "5px" : ""
    const marginleft = props.type !== "organization" || !isNone ? "0" : "-50px"
    const marginright = props.type !== "organization" || !isNone ? "0" : "-50px"
    const fontSize = props.fontSize ? props.fontSize : props.type !== "organization" || !isNone ? "20px" : "80px"

    function toggleFavoriteState(event: any) {
        favoriteState.toggle(props.type, props.id)
        if (props.type === "person" || props.type === "sotuser") {
            syncFav("person")
            syncFav("sotuser")
        } else syncFav(props.type)

        event.preventDefault()
        event.stopPropagation()
        if (props.type === "sotuser" || props.type === "person") favoriteState.triggerRefreshFavorites(props.id)
    }

    if (props.fullButton) {
        return (
            <ShareBookmarkButton
                onClick={(event) => {
                    toggleFavoriteState(event)
                }}
            >
                <ShareBookmarkButtonIcon>
                    {props.newBookmarkItem ? (
                        <NewFavIcon className={props.className}>
                            {favoriteState.is(props.type, props.id) ? icon1 : icon2}
                        </NewFavIcon>
                    ) : (
                        <FavIcon
                            color={color}
                            top={top}
                            left={left}
                            right={right}
                            marginleft={marginleft}
                            marginright={marginright}
                            fontSize={fontSize}
                            className={props.className}
                            onClick={(event) => {
                                toggleFavoriteState(event)
                            }}
                        >
                            {favoriteState.is(props.type, props.id) ? icon1 : icon2}
                        </FavIcon>
                    )}
                </ShareBookmarkButtonIcon>
                <ShareBookmarkButtonText>{props.buttonText ?? ""}</ShareBookmarkButtonText>
            </ShareBookmarkButton>
        )
    }

    return (
        <>
            {props.newBookmarkItem ? (
                <NewFavIcon
                    className={props.className}
                    onClick={(event) => {
                        toggleFavoriteState(event)

                        if (props.customFunction) {
                            props.customFunction()
                        }
                    }}
                >
                    {favoriteState.is(props.type, props.id) ? icon1 : icon2}
                </NewFavIcon>
            ) : (
                <FavIcon
                    color={color}
                    top={top}
                    left={left}
                    right={right}
                    marginleft={marginleft}
                    marginright={marginright}
                    fontSize={fontSize}
                    className={props.className}
                    onClick={(event) => {
                        toggleFavoriteState(event)

                        if (props.customFunction) {
                            props.customFunction()
                        }
                    }}
                >
                    {favoriteState.is(props.type, props.id) ? icon1 : icon2}
                </FavIcon>
            )}
        </>
    )
}

export default BookmarkWithToggle

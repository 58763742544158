import { BackendServiceError, fetchDataRest, fetchDataWebService, topic } from "./BackendServicesUtils"
import { EventDate } from "./Types"
import { defaultLogger as logger } from "../globalStates/AppState"

/*********************************************************************************************
 * EVENTDATE LIST
 **********************************************************************************************/
export interface EventDateListResponse {
    count?: number
    eventDates: EventDate[]
}

export async function loadEventDateList(params: object, signal?: AbortSignal): Promise<EventDateListResponse> {
    try {
        const data = await fetchDataWebService("/search", params, signal)
        return { count: data.count, eventDates: transformEventDateList(data.entities) }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { count: 0, eventDates: [] }
    }
}

//const TIME_REGEX = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/gm
function transformEventDateList(eventDates: EventDate[]) {
    return eventDates.map((elem) => {
        /* if (!elem.start || !TIME_REGEX.test(elem.start)) {
            elem.start = branding.eventTiming.eventDateDefaultStartTime
        } */
        let [startHour, startMinutes] = elem?.start?.split(":")
        elem.startHour = parseInt(startHour)
        elem.startMinutes = parseInt(startMinutes)

        /* if (!elem.end || !TIME_REGEX.test(elem.end)) {
            elem.end = branding.eventTiming.eventDateDefaultEndTime
        } */
        let [endHour, endMinutes] = elem?.end?.split(":")
        elem.endHour = parseInt(endHour)
        elem.endMinutes = parseInt(endMinutes)
        return elem
    })
}

/*********************************************************************************************
 * EVENTDATE DETAILS
 **********************************************************************************************/
export interface EventDateDetailsResponse {
    content: EventDate
    rootCategories?: Array<string>
    showCategoryHierarchy?: boolean
}

export async function loadEventDateDetails(params: object, signal?: AbortSignal): Promise<EventDateDetailsResponse> {
    try {
        const data = await fetchDataWebService("/eventdatedetails", params, signal)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/eventdatedetails",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

/*********************************************************************************************
 * ROUNDTABLE LIST
 **********************************************************************************************/
export async function loadRoundTableList(
    params: object,
    profileId: string,
    signal?: AbortSignal
): Promise<EventDateListResponse> {
    try {
        const data = await fetchDataRest(`/eventDates/topic/${topic}/profile/${profileId}/roundtables`, params, "GET")
        return { count: data.count, eventDates: transformEventDateList(data.eventDates) }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: `/eventDates/topic/${topic}/profile/${profileId}/roundtables`,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { count: 0, eventDates: [] }
    }
}

/*********************************************************************************************
 * CHANNEL FIRST EVENTDATE
 **********************************************************************************************/
export interface EventDateChannelFirstDetailsResponse {
    currentEventDate?: EventDate
    nextEventDate?: EventDate
}

export async function loadChannelFirstEventDate(
    channel: string,
    delayTime?: number
): Promise<EventDateChannelFirstDetailsResponse | BackendServiceError> {
    const defaultRoute: string = `/eventDates/topic/${topic}/channel/${channel}`
    try {
        const data = await fetchDataRest(
            defaultRoute,
            {
                delayTime: delayTime
            },
            "GET"
        )
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { currentEventDate: data.currentEventDate, nextEventDate: data.nextEventDate }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: null,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * EVENTDATE DAYS
 **********************************************************************************************/
export interface EventDateDatesResponse {
    dates: string[]
}

export async function loadEventDateDates(params: object, signal?: AbortSignal): Promise<EventDateDatesResponse> {
    try {
        const data = await fetchDataWebService("/eventdatedates", params, signal)
        return { dates: data.dates }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/eventdatedates",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

import { BackendServiceError, fetchDataRest, topic } from "./BackendServicesUtils"
import { defaultLogger as logger } from "../globalStates/AppState"
import { EventDateBase } from "./Types"

/*********************************************************************************************
 * CREATE JON MEETING
 **********************************************************************************************/
export type MeetingKind =
    | "showroom"
    | "call"
    | "virtualCafe"
    | "calenderEntry"
    | "greenroom"
    | "roundtable"
    | "breakout"
    | "conferenceroom"
export type AttendeeRole = "recorder" | "member" | "moderator" | "viewer"
interface MediaPlacement {
    AudioFallbackUrl: string
    AudioHostUrl: string
    ScreenDataUrl: string
    ScreenSharingUrl: string
    ScreenViewingUrl: string
    SignalingUrl: string
    TurnControlUrl: string
}

interface ChimeMeeting {
    MeetingId: string
    ExternalMeetingId: string
    MediaRegion: string
    MediaPlacement: MediaPlacement
}

interface ChimeAttendee {
    ExternalUserId: string
    AttendeeId: string
    JoinToken: string
    Role: AttendeeRole
}

interface ChimeData {
    Meeting: ChimeMeeting
    Attendee: ChimeAttendee
}

interface MeetingData {
    id: string
    maxAttendees: number
    meetingKind: MeetingKind
    remainingDurationMillis: number
    maxDurationSeconds: number
    timeLimitChanged: boolean
}

export interface AttendeeData {
    id?: string
    name?: string
    role?: AttendeeRole
    avatarUrl?: string
    position?: string
    positionDe?: string
    connectionStatus?: string
    company?: string
    userType?: string
}

export interface ChimeMeetingData {
    meeting: MeetingData
    attendee: AttendeeData
    chime: ChimeData
}
/** Join meeting, without retries */
export async function createOrJoinMeeting(
    externalMeetingId: string,
    externalUserId: string
): Promise<ChimeMeetingData | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/meeting/${externalMeetingId}/attendee/${externalUserId}`
    try {
        return await fetchDataRest(defaultRoute, { autoLeave: true }, "PUT")
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * LEAVE MEETING
 **********************************************************************************************/
export interface LeaveRoomResponse {
    message: string
}

export async function leaveRoom(
    externalMeetingId: string,
    externalUserId: string
): Promise<LeaveRoomResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/meeting/${externalMeetingId}/attendee/${externalUserId}`
    try {
        return await fetchDataRest(defaultRoute, { action: "leave" }, "DELETE")
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function kick(
    externalMeetingId: string,
    externalUserId: string,
    reason?: string
): Promise<LeaveRoomResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/meeting/${externalMeetingId}/attendee/${externalUserId}`
    try {
        return await fetchDataRest(defaultRoute, { action: "kick", reason: reason ? reason : "" }, "DELETE", undefined)
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function ban(
    externalMeetingId: string,
    externalUserId: string,
    reason?: string
): Promise<LeaveRoomResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/meeting/${externalMeetingId}/attendee/${externalUserId}`
    try {
        return await fetchDataRest(defaultRoute, { action: "ban", reason: reason ? reason : "" }, "DELETE", undefined)
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function getAttendeeInfo(
    externalMeetingId: string,
    externalUserId: string
): Promise<AttendeeData | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/meeting/${externalMeetingId}/attendee/${externalUserId}`
    try {
        const data = await fetchDataRest(defaultRoute, null, "GET", undefined)
        return data.attendee
            ? (data.attendee as AttendeeData)
            : ({ httpStatus: 500, httpStatusText: "wrong response format" } as BackendServiceError)
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500, httpStatusText: "wrong response format" } as BackendServiceError
    }
}
export async function listAttendeeInfos(externalMeetingId: string): Promise<AttendeeData[] | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/meeting/${externalMeetingId}`
    try {
        const data = await fetchDataRest(defaultRoute, null, "GET", undefined)
        if (!Array.isArray(data)) return { httpStatus: 500, httpStatusText: "wrong response format" } as BackendServiceError
        const result = []
        for (var entry of data) {
            if (!entry.attendee) return { httpStatus: 500, httpStatusText: "wrong response format" } as BackendServiceError
            result.push(entry.attendee)
        }
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500, httpStatusText: "wrong response format" } as BackendServiceError
    }
}

export interface CheckForTimerUpdateResponse {
    maxMeetingLengthInSeconds?: number
    newRemainingDuration?: number
    timerUpdated: boolean
}

export async function checkForTimerUpdate(externalMeetingId: string): Promise<CheckForTimerUpdateResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/meeting/${externalMeetingId}/checkForTimerUpdate`
    try {
        const data = await fetchDataRest(defaultRoute, null, "GET", undefined)
        return data
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500, httpStatusText: "wrong response format" } as BackendServiceError
    }
}

/*********************************************************************************************
 * LIST MEETING ATTENDEES
 **********************************************************************************************/
export interface MeetingAttendeeResponse {
    numAttendees: number
    maxAttendees: number
    attendees: ChimeAttendee[]
}

export interface ListMeetingAttendeeResponse {
    [externalMeetingId: string]: MeetingAttendeeResponse
}

export async function listMeetingAttendees(
    externalMeetingIds: string[]
): Promise<ListMeetingAttendeeResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/listAttendeesForMeetings`
    try {
        const data = await fetchDataRest(defaultRoute, { externalMeetingIds: externalMeetingIds }, "GET", undefined)
        return data
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: { externalMeetingIds: externalMeetingIds },
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * GREEN ROOM START/STOP LIVE
 *********************************************************************************************/
export interface ChannelResponse {
    channelId: string
    name?: string
    isLive: boolean
    isLocked: boolean
    url: string
    redundantUrl?: string
    thumbnailUrl: string
    currentEventDate?: EventDateBase
}

export async function startLive(channelId: string): Promise<ChannelResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/channel/${channelId}/start`
    try {
        const data = await fetchDataRest(defaultRoute, {}, "PUT", undefined)
        return data
    } catch (error: any) {
        logger.error({
            message: "Failed to go live with channel",
            request: defaultRoute,
            params: null,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export type StopReason = "error" | "default"

export async function stopLive(channelId: string, reason: StopReason): Promise<ChannelResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/channel/${channelId}/stop`
    let requestBody = { reason: reason }
    try {
        const data = await fetchDataRest(defaultRoute, {}, "PUT", requestBody)
        return data
    } catch (error: any) {
        logger.error({
            message: "Failed to stop live channel",
            request: defaultRoute,
            params: null,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function lockChannel(channelId: string, authorizedUsers: string[]): Promise<ChannelResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/channel/${channelId}/lock`
    let requestBody = { authorizedUsers: authorizedUsers }
    try {
        const data = await fetchDataRest(defaultRoute, {}, "PUT", requestBody)
        return data
    } catch (error: any) {
        logger.error({
            message: "Failed to lock channel",
            request: defaultRoute,
            params: requestBody,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function unlockChannel(channelId: string): Promise<ChannelResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/channel/${channelId}/unlock`
    try {
        const data = await fetchDataRest(defaultRoute, {}, "PUT", undefined)
        return data
    } catch (error: any) {
        logger.error({
            message: "Failed to unlock channel",
            request: defaultRoute,
            params: null,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export interface RestartRecorderResponse {
    success: boolean
}
export async function restartRecorder(channelId: string): Promise<RestartRecorderResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/channel/${channelId}/restartrecorder`
    try {
        const data = await fetchDataRest(defaultRoute, {}, "POST", undefined)
        return data
    } catch (error: any) {
        logger.error({
            message: "Failed to restart the channel recorder",
            request: defaultRoute,
            params: null,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function getChannelInfo(channelId: string): Promise<ChannelResponse | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/channel/${channelId}`
    try {
        const data = await fetchDataRest(defaultRoute, {}, "GET", undefined)
        return data
    } catch (error: any) {
        logger.error({
            message: "Failed to fetch channel info",
            request: defaultRoute,
            params: null,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function getChannels(delayTime?: number): Promise<ChannelResponse[] | BackendServiceError> {
    const defaultRoute = `/meeting/topic/${topic}/channels`
    try {
        const data = await fetchDataRest(
            defaultRoute,
            {
                delayTime: delayTime
            },
            "GET",
            undefined
        )
        return data.channels
    } catch (error: any) {
        logger.error({
            message: "Failed to fetch channels",
            request: defaultRoute,
            params: null,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import styled from 'styled-components';
import { useChimeContext } from '../context/ChimeContext';
import { IconMicrophoneOff, IconMicrophone, IconRaiseHand } from '../../ui/Icons';
import { useRosterContext } from '../context/RosterContext';
import { MeetingStatusCode } from '../enums/MeetingStatusCode'

const VideoNameplateRoot = styled.div<{ isSpeaking?: boolean }>`
  position: absolute;
  font-size: 14px;
  bottom: 8px;
  left: 8px;
  max-width: 100%;
  padding: 3px 10px;
  border-radius: 5px;
  background: rgba(32, 36, 40, 0.6);
  /* border: 2px solid ${props => props.isSpeaking ? "#00f" : "#fff"}; */
  color: #fff;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  line-height: 22px;
`

const Name = styled.div`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 5px;
`

type Props = {
  attendeeId: string
};

export default function VideoNameplate(props: Props) {
  // const activeSpeakerContext = useActiveSpeakerContext()
  const chime = useChimeContext()
  const rosterContext = useRosterContext()
  const attendee = rosterContext.getAttendee(props.attendeeId)
  if (!attendee || chime.getMeetingStatus().meetingStatusCode !== MeetingStatusCode.Succeeded)
    return null
  // const isSpeaking = activeSpeakerContext.isActiveSpeaker(props.attendeeId)
  return (
    <VideoNameplateRoot>
      {/* {isSpeaking && IconVolumeHigh({fill: "#fff", width: "14", height: "14" })} */}
      {attendee.handRaised && IconRaiseHand({fill: "#fff", width: "14", height: "14" })}
      {attendee.name && <Name>{attendee.name}</Name>}
      {attendee.muted && IconMicrophoneOff({fill: "#fff", width: "14", height: "14" })}
      {!attendee.muted && IconMicrophone({fill: "#fff", width: "14", height: "14" })}
    </VideoNameplateRoot>
  );
}

import * as React from "react"
import { NavLink } from "react-router-dom"
import styled from "styled-components"
import branding from "../../branding/branding"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { device } from "../../utils/Device"

const DynamicBannerRoot = styled.div<{ backgroundImage?: string }>`
    position: relative;
    width: calc(100% - 18px);
    padding-top: 25%;
    background: ${(props) => props.backgroundImage};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 5px;

    @media ${device.mobileXL} {
        padding-top: 18%;
    }

    @media ${device.tablet} {
        padding-top: 12.5%;
        width: calc(100% + 100px);
        min-height: 120px;
        height: auto;
    }

    @media ${device.mobile} {
        width: calc(100% - 18px);
        min-height: unset;
    }
`
const DynamicBannerContent = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    flex-direction: column;
    flex-flow: column;
    justify-content: center;

    @media ${device.mobile} {
        top: 10;
    }
`
const NetworkingIcon = styled.div<{ img?: string }>`
    width: 40px;
    height: 40px;
    border: 1px solid white;
    border-radius: 50%;
    margin-bottom: 18px;
    background-image: url("${(props) => props.img}");
    background-size: cover;
    background-color: lightgrey;
    font-size: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 1600px) {
        width: 22px;
        height: 22px;
        font-size: 10px;
    }
`

const NetworkingText = styled.div`
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    color: ${branding.receptionPage.networkingBannerTextColor ?? "#fff"};
    @media only screen and (max-width: 2200px) {
        /* font-size: 7.35vw; */
        font-size: 18px;
        line-height: 20px;
    }
    @media only screen and (max-width: 1800px) {
        font-size: 16px;
        line-height: 18px;
    }

    @media only screen and (max-width: 1500px) {
        font-size: 14px;
        line-height: 16px;
    }

    @media ${device.mobile} {
        margin-top: -12px;
        font-size: 10px;
        line-height: 12px;
    }
`

const NetworkingButton = styled(NavLink)<{ textLength: number }>`
    width: 160px;
    height: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    border: 1px solid white;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
    font-family: ${branding.font1};
    color: ${branding.primaryColor} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    visibility: ${(props) => (props.textLength > 0 ? "visible" : "hidden")};

    &:hover {
        color: #fff !important;
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.2);
    }

    @media only screen and (max-width: 1800px) {
        margin-top: 10px;
    }

    @media only screen and (max-width: 1300px) {
        margin-top: 0px;
    }

    @media ${device.mobile} {
        margin-top: 3px;
        height: 20px;
        font-size: 10px;
        line-height: 14px;
    }
`

interface DynamicBannerProps {
    navLink: string
    buttonText: string
    text1: string
    text2: string
    isNetworkingBanner?: boolean
    isLeadFinderBanner?: boolean
    isProgramBanner?: boolean
}

const DynamicBanner: React.FunctionComponent<DynamicBannerProps> = (props: DynamicBannerProps) => {
    let userState = useLoggedInState()
    const name = [userState.user()?.firstName, userState.user()?.lastName].join(" ")
    const alt = name
        ? name
              .split(" ")
              .map((value, index) => (index <= 1 && value && value.length > 0 ? value.substr(0, 1).toUpperCase() : null))
              .join("")
        : ""

    return (
        <DynamicBannerRoot
            backgroundImage={
                props.isNetworkingBanner
                    ? "url(/branding/networking-banner.png)"
                    : props.isLeadFinderBanner
                    ? "url(/branding/leadfinder-banner.png)"
                    : props.isProgramBanner
                    ? "url(/branding/program-banner.png)"
                    : "url(/branding/dynamic-banner.png)"
            }
        >
            <DynamicBannerContent>
                <NetworkingIcon img={userState.user()?.logoUrl}>{userState.user()?.logoUrl ? "" : alt}</NetworkingIcon>
                <NetworkingText>
                    {props.text1}
                    <br></br>
                    {props.text2}
                </NetworkingText>
                <NetworkingButton textLength={props.buttonText.length} to={props.navLink}>
                    {props.buttonText}
                </NetworkingButton>
            </DynamicBannerContent>
        </DynamicBannerRoot>
    )
}

export default DynamicBanner

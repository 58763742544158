import { createState, useState, State } from '@hookstate/core';
import { Category } from '../backendServices/Types';



export interface CategoryState {
    categories: Array<Category>
}

const useWrapState = ((state: State<CategoryState>) => {
    return ({
        setCategoriesState: (newState: { categories: Array<Category> }) => {
            state.set(newState)
        },
        getCategories: (): Array<Category> => {
            const categories = state.get().categories
            return categories && categories.length > 0 ? categories : []
        },
        getCategoryByAlias: (alias: string): Category | undefined => {
            const categories = state.get().categories
            return categories && categories.length > 0 ? categories.find(x => x.alias === alias) : undefined
        },
    })
})
const state = createState({} as CategoryState)
export const useCategoriesState = () => useWrapState(useState(state))
import { Col, Row } from "react-bootstrap"
import PreviewVideo from "../../PreviewVideo"
import { SettingsContentContainer } from "../Settings"
import { LogoSettingsPosition } from "./Position"
import { LogoSettingsType } from "./Type"

export function LogoSettings() {
    return (
        <SettingsContentContainer>
            <Row>
                <Col md={7}>
                    <PreviewVideo />

                    <LogoSettingsPosition />
                </Col>

                <Col md={5}>
                    <LogoSettingsType />
                </Col>
            </Row>
        </SettingsContentContainer>
    )
}

import { Component } from "react"
import ErrorSite from "./ErrorSite"
import styled from "styled-components"
import { defaultLogger as logger } from "../../globalStates/AppState"

const ContentAreaErrorBoundaryPanel = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
`

class ContentAreaErrorBoundary extends Component {
    state = {
        error: null
    }

    static getDerivedStateFromError(error: any) {
        return { error: error }
    }

    componentDidCatch(error: any, errorInfo: any) {
        logger.error(error, errorInfo)
    }

    render() {
        if (this.state.error) {
            return (
                <ContentAreaErrorBoundaryPanel>
                    <ErrorSite />
                </ContentAreaErrorBoundaryPanel>
            )
        }
        return this.props.children
    }
}

export default ContentAreaErrorBoundary

import { useEffect, useState } from "react"
import * as React from "react"
import styled from "styled-components"
import { IconCloseFilled, IconPlayFilled } from "../../ui/Icons"
import VideoPlayerReal from "../videoPlayer/VideoPlayerReal"
import { useAppState } from "../../globalStates/AppState"

const CloseIcon = styled.div`
    position: absolute;
    cursor: pointer;
    top: -10px;
    left: calc(100% - 60px);
    z-index: 2;
    opacity: 0;
`
const VideoPlayerIcon = styled.div`
    cursor: pointer;
    padding: 1rem;
    border-radius: 50%;
    border: 3px solid white;
`

/* Invisible layer under the modal that will not allow user to scroll when the video is active */
const DarkInvisibleShield = styled.div`
    display: block;
    position: absolute;
    left: -100%;
    top: -100%;
    width: 200vw;
    height: 200vh;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.4);
`

const VideoModalRoot = styled.div<{ width?: string; headerOpen?: boolean }>`
    position: absolute;
    z-index: 100;
    top: 50%;
    transform: translateY(-50%);
    left: ${(props) => `calc(100% - 5% - ${props.headerOpen ? props.width : `calc(${props.width} - 5%)` || "300px"})`};
    display: flex;
    width: ${(props) => (props.width ? (props.headerOpen ? props.width : `calc(${props.width} - 5%)`) : "300px")};
    padding-bottom: ${(props) => `calc(9 / 16 * ${props.headerOpen ? props.width : `calc(${props.width} - 5%)` || "300px"})`};

    &.center-video {
        top: 50vh;
        left: 0;
        width: 100%;
        padding-bottom: calc(9 / 16 * 50%);
        max-height: 550px;
    }
`

const VideoThumbnailContainer = styled.div<{ bgImage: string }>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: ${(props) => `url(${props.bgImage})`};
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalVideoPlayerContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    outline: none;
    max-width: 36%;
    max-height: 550px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

    & video {
        outline: none !important;
    }

    :hover ${CloseIcon} {
        opacity: 1;
    }
`

const VideoThumbnail = (bgImage: string, onPlay: React.Dispatch<React.SetStateAction<boolean>>) => {
    return (
        <VideoThumbnailContainer bgImage={bgImage}>
            <VideoPlayerIcon onClick={() => onPlay(true)}>
                {IconPlayFilled({ width: "25", height: "25", fill: "white" })}
            </VideoPlayerIcon>
        </VideoThumbnailContainer>
    )
}

interface ModalVideoPlayerProps {
    videoUrl: string
    setShowVideo: React.Dispatch<React.SetStateAction<boolean>>
    showVideo: boolean
}

const ModalVideoPlayer: React.FC<ModalVideoPlayerProps> = (props) => {
    const appState = useAppState()

    // make PiP-Player disappear when ModalVideoPlayer is opened and reappear when ModalVideoPlayer is closed
    useEffect(() => {
        if (props.showVideo) {
            if (appState.liveStreamChannel) {
                if (appState.liveStreamChannel.eventDate) {
                    appState.setTemporaryLiveStreamChannel(
                        Object.assign({}, appState.liveStreamChannel, {
                            eventDate: JSON.parse(JSON.stringify(appState.liveStreamChannel.eventDate))
                        })
                    )
                } else {
                    appState.setTemporaryLiveStreamChannel(Object.assign({}, appState.liveStreamChannel))
                }
                appState.setLiveStreamChannel(null)
            }
            if (appState.vodEventDate) {
                appState.setVodEventDateTemporary(Object.assign({}, JSON.parse(JSON.stringify(appState.vodEventDate))))
                appState.setVodEventDate(null)
            }
        } else {
            if (appState.temporaryLiveStreamChannel) {
                if (appState.temporaryLiveStreamChannel.eventDate) {
                    appState.setLiveStreamChannel(
                        Object.assign({}, appState.temporaryLiveStreamChannel, {
                            eventDate: JSON.parse(JSON.stringify(appState.temporaryLiveStreamChannel.eventDate))
                        })
                    )
                } else {
                    appState.setLiveStreamChannel(Object.assign({}, appState.temporaryLiveStreamChannel))
                }
                appState.setTemporaryLiveStreamChannel(null)
            }
            if (appState.vodEventDateTemporary) {
                appState.setVodEventDate(Object.assign({}, JSON.parse(JSON.stringify(appState.vodEventDateTemporary))))
                appState.setVodEventDateTemporary(null)
            }
        }
    }, [props.showVideo]) //eslint-disable-line

    const handleKeyUp = (event: KeyboardEvent) => {
        if (event.key === "Escape") props.setShowVideo(false)
    }

    useEffect(() => {
        window.addEventListener("keyup", handleKeyUp)
        return function cleanUp() {
            window.removeEventListener("keyup", handleKeyUp)
        }
    }, []) // eslint-disable-line

    if (!props.showVideo) {
        return null
    } else {
        return (
            <ModalVideoPlayerContainer>
                <CloseIcon
                    onClick={() => {
                        props.setShowVideo(false)
                    }}
                >
                    {IconCloseFilled({ width: "70", height: "70", fill: "transparent" })}
                </CloseIcon>
                <VideoPlayerReal
                    src={props.videoUrl}
                    controls={true}
                    live={false}
                    autoPlay={true}
                    sourceIsStatic={true}
                    disablePlayerStateIsSavedAndRead={true}
                    remoteStreamLayout={false}
                />
            </ModalVideoPlayerContainer>
        )
    }
}

interface VideoModalProps {
    videoUrl: string
    videoImageUrl: string
    width?: string
    rootCss?: React.CSSProperties
    headerOpen?: boolean
}

export const VideoModal = (props: VideoModalProps) => {
    const [showVideo, setShowVideo] = useState(false)

    return (
        <VideoModalRoot
            width={props.width}
            style={props.rootCss}
            className={showVideo ? "center-video" : ""}
            headerOpen={props.headerOpen}
        >
            {showVideo && <DarkInvisibleShield onClick={() => setShowVideo(false)} />}
            <ModalVideoPlayer videoUrl={props.videoUrl} setShowVideo={setShowVideo} showVideo={showVideo}></ModalVideoPlayer>
            {!showVideo && VideoThumbnail(props.videoImageUrl, setShowVideo)}
        </VideoModalRoot>
    )
}

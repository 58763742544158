import * as React from "react"
import styled from "styled-components"
import branding from "../branding/branding"

const TextLimitRoot = styled.div<{ textAlign: string; marginRight: string }>`
    text-align: ${(props) => props.textAlign};
    font-size: 9px;
    line-height: 20px;
    margin-top: 5px;
    margin-right: ${(props) => props.marginRight};
    font-family: ${branding.font1};
    color: #727272;
`

interface TextLimitProps {
    textLength: number
    maxTextLength: number
    fontSize?: string
    fontFamily?: string
    textAlign?: string
    marginRight?: string
}

const TextLimit: React.FunctionComponent<TextLimitProps> = React.memo((props: TextLimitProps) => {
    return (
        <TextLimitRoot textAlign={props.textAlign ?? "right"} marginRight={props.marginRight ?? "5px"}>
            {props.textLength} / {props.maxTextLength}
        </TextLimitRoot>
    )
})

export default TextLimit

import { useRef, useEffect, Dispatch, SetStateAction, useImperativeHandle, forwardRef, MutableRefObject } from "react"

import * as React from "react"
import videojs, { VideoJsPlayer } from "video.js"
import styled from "styled-components"
import { keyframes } from "styled-components"
import { useHistory } from "react-router-dom"
import { useAppState } from "../../globalStates/AppState"
import { buildDetailLink } from "../detailPages/DetailNavLink"
import { useLanguageState } from "../../globalStates/LanguageState"
import "./Video-js.css"
import { ResizeState, TmpVideoPlayerStatus } from "./PictureInPictureVideoPlayer"
import { determineSourceType } from "./VideoPlayerReal"

/* mini-version of VideoPlayer.tsx for Picture-In-Picture-Mode,
livestream-capable video-player which uses the open source library video.js
video.js implements HLS-Streaming
the UI is styled by changing the default CSS-skin that video.js provides */

/* ################### video player logic #################################################################################################*/

const usePlayer = (
    channelId: string | undefined,
    url: string | undefined,
    sourceIsStatic: boolean = false,
    userControlsEnabled: boolean,
    setErrorOccured: Dispatch<SetStateAction<boolean>>,
    setIsPaused: Dispatch<SetStateAction<boolean>>,
    tmpVideoPlayerStatus: MutableRefObject<TmpVideoPlayerStatus | null>
) => {
    const appState = useAppState()
    const strings = useLanguageState().getStrings()

    const videoRef = useRef(null)
    const player = useRef<VideoJsPlayer | null>(null)

    // instantiate and dispose of player only once
    // similar to componentDidMount / componentDidUpdate / componentWillUnmount
    // do something after render
    useEffect(() => {
        if (!channelId || !url) {
            return undefined
        }
        const options = {
            autoplay: true,
            controls: userControlsEnabled,
            preload: "auto",
            liveui: true,
            fluid: true,
            userActions: {
                // doubleClick: handlePlayerDoubleClick,
                hotkeys: {
                    fullscreenKey: () => false
                }
            },
            html5: {
                vhs: {
                    withCredentials: true,
                    enableLowInitialPlaylist: true,
                    overrideNative: true
                },
                nativeAudioTracks: false,
                nativeVideoTracks: false
            },
            sources: [
                {
                    src: url,
                    type: determineSourceType(url, sourceIsStatic)
                }
            ],
            controlBar: {
                children: ["PlayToggle"]
            }
        }

        let audioTrackList: videojs.TrackList

        function addTrack(e: Event) {
            if (!audioTrackList) return
            // if audio track was previously set in appState, choose this audio track
            // don't do anything, if only one audio track was added
            const audioTrackFromAppState = appState.videoPlayerStatus?.audioTrack
            if (audioTrackFromAppState && audioTrackList.length > 1) {
                for (let i = 0; i < audioTrackList.length; i++) {
                    let track = audioTrackList[i]
                    if (track !== undefined) {
                        const trackAny = track as any
                        if (trackAny.label === audioTrackFromAppState) {
                            // setting enabled to true does not seem to trigger a change event, so we trigger it ourselves
                            trackAny.enabled = true
                            audioTrackList.trigger("change")
                        } else {
                            trackAny.enabled = false
                        }
                    }
                }
            }
        }

        function assertOneTrack(e: Event) {
            if (!audioTrackList) return
            // todo: this only works for two audio tracks
            // prevent activating two audio tracks at the same time
            if (audioTrackList.length > 1) {
                const trackAny0 = audioTrackList[0] as any
                const trackAny1 = audioTrackList[1] as any
                if (trackAny0.enabled === trackAny1.enabled) {
                    e.stopImmediatePropagation()
                }
            }
        }
        // pass player ready callback as third argument
        const vjsPlayer = videojs(videoRef?.current!, { ...(options as any) }, function (this: VideoJsPlayer) {
            // read volume level, mute-state and play/pause-state from appState, so it stays the same when user switches
            // between Video- and PiP-Player

            /* the following lines deal with the feature, that video.js-Player allows a state where volume is 0 but mute 
            is set to false and vice versa, this is probably due to the fact that muting can be toggled via two UI-Elements:
            volume-slider and mute-toggle-button,
            this is probably on purpose so the user can mute player via mute button and then switch back to the previous
            volume, when mute is turned off again */
            setErrorOccured(false)
            if (tmpVideoPlayerStatus.current) tmpVideoPlayerStatus.current.updateCounter = 0

            // { IWillNotUseThisInPlugins: true } suppresses warning about tech usage
            audioTrackList = vjsPlayer.tech({ IWillNotUseThisInPlugins: true })?.audioTracks()

            if (audioTrackList) {
                audioTrackList.addEventListener("addtrack", addTrack)
                audioTrackList.addEventListener("change", assertOneTrack)
            }
        }) // end player init

        // load error is displayed using appstrings from branding.js
        videojs.addLanguage("de", {
            "The media could not be loaded, either because the server or network failed or because the format is not supported.":
                strings.videoPlayerBranding.pipStreamErrorMessage
        })

        videojs.addLanguage("en", {
            "The media could not be loaded, either because the server or network failed or because the format is not supported.":
                strings.videoPlayerBranding.pipStreamErrorMessage
        })

        // playlist error
        videojs.addLanguage("de", {
            "Playback cannot continue. No available working or supported playlists.":
                strings.videoPlayerBranding.videoPlayerPlaylistErrorMessage
        })

        videojs.addLanguage("en", {
            "Playback cannot continue. No available working or supported playlists.":
                strings.videoPlayerBranding.videoPlayerPlaylistErrorMessage
        })

        if (vjsPlayer) {
            const bigPlayButton = vjsPlayer.getChild("BigPlayButton")
            if (bigPlayButton) {
                vjsPlayer.removeChild(bigPlayButton)
            }
        }

        function handleError(event: React.MouseEvent) {
            setErrorOccured(true)
        }
        vjsPlayer.on("error", handleError)

        function handleIsPaused(event: React.MouseEvent) {
            setIsPaused(vjsPlayer.paused())
        }

        function handleTimeUpdate(event: React.MouseEvent) {
            if (!tmpVideoPlayerStatus.current) return
            const currentTime = vjsPlayer.currentTime()
            tmpVideoPlayerStatus.current.timeOffsetliveStream = vjsPlayer.liveTracker.liveCurrentTime() - currentTime
            tmpVideoPlayerStatus.current.timeOffsetVOD = currentTime
        }

        function handleMetaDataLoaded(event: React.MouseEvent) {
            const tmpAppstate = appState.videoPlayerStatus
            const isVod = appState.liveStreamChannel === null && appState.vodEventDate !== null
            if (tmpAppstate?.timeOffsetVOD && isVod) {
                vjsPlayer.currentTime(tmpAppstate.timeOffsetVOD)
            } else if (tmpAppstate?.timeOffsetliveStream) {
                const newTimeOffset = tmpAppstate.timeOffsetliveStream > 140 ? 140 : tmpAppstate.timeOffsetliveStream
                vjsPlayer.currentTime(vjsPlayer.liveTracker.liveCurrentTime() - newTimeOffset)
            }

            if (tmpAppstate) {
                if (tmpAppstate.volume === 0) {
                    vjsPlayer.volume(0)
                    vjsPlayer.muted(true)
                } else if (tmpAppstate.isMuted === true) {
                    vjsPlayer.muted(true)
                } else {
                    if (tmpAppstate.volume) {
                        vjsPlayer.volume(tmpAppstate.volume)
                    }
                    vjsPlayer.muted(false)
                }

                if (tmpAppstate.isPaused !== undefined) {
                    if (tmpAppstate.isPaused) {
                        vjsPlayer.pause()
                        setIsPaused(true)
                    } else {
                        vjsPlayer.play()
                        setIsPaused(true)
                    }
                } else {
                    vjsPlayer.pause()
                    setIsPaused(true)
                }
            }
        }

        vjsPlayer.on("play", handleIsPaused)
        vjsPlayer.on("pause", handleIsPaused)
        vjsPlayer.on("timeupdate", handleTimeUpdate)
        vjsPlayer.on("loadedmetadata", handleMetaDataLoaded)

        vjsPlayer.addClass("vjs-miniplayer")

        player.current = vjsPlayer

        // returned function is called as cleanup
        return () => {
            if (vjsPlayer.error()) {
                // reset time offset in case of error
                appState.setVideoPlayerStatus({
                    volume: vjsPlayer.volume(),
                    isMuted: vjsPlayer.muted(),
                    isPaused: vjsPlayer.paused(),
                    timeOffsetliveStream: 0
                })
            }
            if (audioTrackList) {
                audioTrackList.removeEventListener("change", assertOneTrack)
                audioTrackList.removeEventListener("addtrack", addTrack)
            }
            vjsPlayer.off("error", handleError)
            vjsPlayer.off("play", handleIsPaused)
            vjsPlayer.off("pause", handleIsPaused)
            vjsPlayer.off("timeupdate", handleTimeUpdate)
            vjsPlayer.off("loadedmetadata", handleMetaDataLoaded)
            vjsPlayer.dispose()
        }
        // passing empty array as second argument will result in the effect and clean up running only once (on mount and unmount)
        // effect doesn't depend on any value from props or state
    }, []) //eslint-disable-line

    if (!channelId || !url) {
        return undefined
    }

    return { player: player.current, videoRef: videoRef }
} // end usePlayer()

export interface PictureInPictureVideoPlayerProps {
    userControlsEnabled: boolean
    resizeState: ResizeState
    errorOccured: boolean
    setErrorOccured: Dispatch<SetStateAction<boolean>>
    setIsPaused: Dispatch<SetStateAction<boolean>>
    tmpVideoPlayerStatus: MutableRefObject<TmpVideoPlayerStatus>
    sourceIsStatic?: boolean
}

export interface PlayerHandle {
    handleClick: () => void
    handleDoubleClick: () => void
}

const PictureInPictureVideoPlayerReal: React.ForwardRefRenderFunction<PlayerHandle, PictureInPictureVideoPlayerProps> = (
    props,
    ref
) => {
    const appState = useAppState()
    const channelId = appState.liveStreamChannel?.id
    const eventdateId = appState.vodEventDate?.id
    const history = useHistory()
    const playerRef = usePlayer(
        channelId ?? eventdateId,
        appState.liveStreamChannel ? appState.liveStreamChannel?.url : appState.vodEventDate?.videoOnDemandUrl,
        props.sourceIsStatic,
        props.userControlsEnabled,
        props.setErrorOccured,
        props.setIsPaused,
        props.tmpVideoPlayerStatus
    )

    useImperativeHandle(ref, () => ({
        handleClick: () => {
            const player = playerRef?.player
            if (!player) {
                return
            }
            if (player.paused()) {
                player.play()
            } else {
                player.pause()
            }
        },
        handleDoubleClick: () => {
            if (channelId) {
                history.push(buildDetailLink(channelId, "", "channel"))
            } else if (eventdateId) {
                history.push(buildDetailLink(eventdateId, appState.vodEventDate?.name || "", "eventdate"))
            }
        }
    }))

    if (!playerRef) {
        return <></>
    }

    return (
        <PictureInPicturePlayerRoot>
            <div data-vjs-player>
                <video ref={playerRef.videoRef} className="video-js" data-isPip="true" data-isStream="true"></video>
            </div>
        </PictureInPicturePlayerRoot>
    )
} // end PictureInPicturePlayer

/* ################### UI styling #########################################################################################################*/

// keyframes for fading animations (pause and play)

// pause animation
const screenFadePause = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0.25;
    }
`

const logoFadePause = keyframes`
    from {
        opacity: 0.25;
        transform: scale(2);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
`

// play animation
const screenFadePlay = keyframes`
    from {
        opacity: 0.25;
    }

    to {
        opacity: 1;
    }
`

const logoFadePlay = keyframes`
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0.25;
        transform: scale(2);
    }
`

export const PictureInPicturePlayerRoot = styled.div`
    z-index: 1400;
    position: relative;
    display: flex;
    justify-content: center; // centered horizontally
    align-items: center; // centered vertically

    /* video player  */
    & .video-js.vjs-miniplayer {
        cursor: pointer;
    }

    /* video element */
    & .vjs-miniplayer .vjs-tech {
        background-color: #000;
        box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.4);
    }

    & .vjs-logo {
        display: none;
    }

    /* paused: fade out to darkened screen */
    & .vjs-miniplayer.vjs-paused .vjs-tech {
        animation: ${screenFadePause} 0.2s linear;
        opacity: 0.25;
    }

    & .vjs-miniplayer.vjs-paused.vjs-has-started .vjs-play-control {
        animation: ${logoFadePause} 0.2s linear;
    }

    /* playing: reverse on-pause-animation when playback resumes*/
    & .vjs-miniplayer.vjs-playing .vjs-tech {
        animation: ${screenFadePlay} 0.2s linear;
    }

    & .vjs-miniplayer.vjs-playing.vjs-has-started .vjs-play-control {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        animation: ${logoFadePlay} 0.2s linear;
        opacity: 0;
    }

    & .vjs-play-control {
        pointer-events: none;
    }

    & #vjs_video_3:hover .vjs-big-play-button {
        background-color: transparent;
    }

    /* control bar */
    & .vjs-control-bar {
        /* pointer-events: none; */
        background: transparent;
        height: 100%;

        // center child elements that have position:absolute
        position: absolute;
        display: flex;
        justify-content: center; // horizontally centered
        align-items: center; // vertically centered
    }

    /* error display */
    & .vjs-error-display.vjs-modal-dialog::before {
        text-indent: -9999px;
    }

    & .vjs-modal-dialog-content {
        position: absolute;
        display: flex;
        justify-content: center; // horizontally centered
        align-items: center; // vertically centered
        overflow: hidden;
        margin-top: -40px;
    }
    /* control buttons - icons */
    & .vjs-big-play-button {
        background: url(/videoPlayerIcons/play.svg) no-repeat;
        border-style: none !important;
        border-radius: 1px;
        padding: 0 0 0 0;
        /* center play button */
        position: absolute !important;
    }

    & .vjs-button[title="Play"] {
        background: url(/videoPlayerIcons/play.svg) no-repeat;
        width: 23px !important;
    }

    & .vjs-button[title="Pause"] {
        background: url(/videoPlayerIcons/pause.svg) no-repeat;
    }

    & .vjs-button[title="Pause"],
    & .vjs-button[title="Play"],
    & .vjs-big-play-button {
        position: absolute;
        text-indent: -9999px;
        width: 26px;
        height: 26px;
        background-size: contain;
        filter: invert(100%);
    }

    & .vjs-button[title="Mute"] {
        background: url(/videoPlayerIcons/volume-3.svg) no-repeat;
    }

    & .vjs-mute-control[title="Unmute"] {
        background: url(/videoPlayerIcons/volume-mute.svg) no-repeat;
    }

    & .vjs-button[title="Replay"] {
        background: url(/videoPlayerIcons/skip-back.svg) no-repeat;
    }

    & .vjs-button[title="Captions"] {
        background: url(/videoPlayerIcons/settings.svg) no-repeat;
    }

    & .vjs-theatermode-control {
        background: url(/videoPlayerIcons/theatre-mode.svg) no-repeat;
        height: 38px !important;
        width: 38px !important;
        margin-top: 1px !important;
    }

    /* animate volume icon for different levels */
    & .vjs-icon-volume-low {
        background: url(/videoPlayerIcons/volume-low.svg) no-repeat;
    }

    & .vjs-button.vjs-vol-1[title="Mute"] {
        background: url(/videoPlayerIcons/volume-1.svg) no-repeat;
    }
    & .vjs-button.vjs-vol-2[title="Mute"] {
        background: url(/videoPlayerIcons/volume-2.svg) no-repeat;
    }

    /* audiotrack button when different audio tracks available */
    & .vjs-button[title="Audio Track"] {
        background: url(/videoPlayerIcons/music-note.svg) no-repeat;
    }

    /* remove yellow border from buttons when hovered over or clicked */
    & .vjs-button:active,
    & .vjs-button:hover,
    & .vjs-button:focus,
    & .vjs-button:visited,
    & .vjs-big-play-button:active,
    & .vjs-big-play-button:hover,
    & .vjs-big-play-button:focus,
    & .vjs-big-play-button:visited,
    & .vjs-control-bar:focus {
        border-style: none !important;
        outline-style: none !important;
        background-color: transparent !important;
    }

    & button.vjs-close-button.vjs-control.vjs-button {
        background: url(/videoPlayerIcons/close.svg) no-repeat;
        background-size: contain;
        margin-top: 10px !important;
        margin-right: 15px !important;
    }

    /* styling that all buttons in control bar have in common*/
    & .vjs-button[title="Picture-in-Picture"],
    & .vjs-button[title="Exit Picture-in-Picture"],
    & .vjs-button[title="Fullscreen"],
    & .vjs-button[title="Mute"],
    & .vjs-mute-control[title="Unmute"],
    & .vjs-button[title="Non-Fullscreen"],
    & .vjs-button[title="Audio Track"],
    & .vjs-button.vjs-vol-1[title="Mute"],
    & .vjs-button.vjs-vol-2[title="Mute"],
    & .vjs-button[title="Audio Track"],
    & .vjs-button[title="Replay"],
    & .vjs-button[title="Captions"],
    & .vjs-theatermode-control,
    & button.vjs-close-button.vjs-control.vjs-button {
        text-indent: -9999px;
        width: 26px;
        height: 26px;
        background-size: contain;
        filter: invert(100%);
        margin-top: 6.5px;
        margin-left: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        border-radius: 1px;
        padding: 0 0 0 0;
    }

    /* live button */
    & .vjs-seek-to-live-control {
        border: transparent !important;
        height: 31px;
        display: block;
        margin-top: 5px;
    }

    & .vjs-seek-to-live-control:focus {
        text-shadow: none;
        border-style: none !important;
        outline-style: none !important;
    }

    /* live text */
    & .vjs-seek-to-live-text {
        user-select: none;
        font-size: 16px;
        overflow: hidden;
        margin-top: 10px;
    }

    & .vjs-seek-to-live-text:focus {
        outline: none;
    }

    /* red dot */
    & .vjs-icon-placeholder {
        font-size: 16px;
        overflow: hidden;
        vertical-align: -2px;
    }

    /* progress bar */
    & .vjs-progress-control {
        bottom: 10px;
    }

    /* white slide-bar */
    & .vjs-play-progress.vjs-slider-bar {
    }

    & .vjs-progress-holder {
    }

    /* white dot */
    & .vjs-load-progress {
    }

    /* little line that attaches to cursor as you hover the progress bar */
    & .vjs-mouse-display {
    }

    /* white progress bar and little circle at current position*/
    & .vjs-play-progress {
    }

    /* volume slider */
    & .vjs-volume-horizontal {
        margin-top: 5px;
    }
`

export default forwardRef(PictureInPictureVideoPlayerReal)

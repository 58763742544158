import { ColorString, LocalizedString } from "../../branding/branding";
import { autosuggestFilterOptions } from "./HelperFunctions";

export interface ZoomChartLicenceObject {
    /**
    * @title Domain
    * @description Zoom chart licence domain     
    * @title_de Domain
    * @description_de Zoom chart licence domain     
    */
    domain: string
    /**
    * @title Value
    * @description Zoom chart licence value     
    * @title_de Value
    * @description_de Zoom chart licence value     
    */
    value: string
    /**
    * @title Key
    * @description Zoom chart licence key     
    * @title_de Key
    * @description_de Zoom chart licence key     
    */
    key: string
}

export interface Topic {
    /**
    * @title Label
    * @description Text for topic label    
    * @title_de Label
    * @description_de Text for topic label    
    */
    label: string
    /**
    * @title Value
    * @description Text for topic value    
    * @title_de Value
    * @description_de Text for topic value    
    */
    value: number
}
export interface NetworkingAreaBranding {
    /**
    * @title Filter by type text
    * @description Text for filter by type label    
    * @title_de Filter by type text
    * @description_de Text for filter by type label    
    */
    filterByTypeText: LocalizedString
    /**
    * @title Filter by position text
    * @description Text for filter by position label    
    * @title_de Filter by position text
    * @description_de Text for filter by position label    
    */
    filterByPositionText: LocalizedString
    /**
    * @title Show my contacts text
    * @description Text for show my contacts label    
    * @title_de Show my contacts text
    * @description_de Text for show my contacts label    
    */
    showMyContactsText: LocalizedString
    /**
    * @title Say hello text
    * @description Text for say hello label    
    * @title_de Say hello text
    * @description_de Text for say hello label    
    */
    sayHelloText: LocalizedString
    /**
    * @title Show more text
    * @description Text for show more label    
    * @title_de Show more text
    * @description_de Text for show more label    
    */
    showMoreText: LocalizedString
    /**
    * @title Start video audio chat text
    * @description Text for start video autdio chat label    
    * @title_de Start video audio chat text
    * @description_de Text for start video autdio chat label    
    */
    startVideoAudioChatText: LocalizedString
    /**
    * @title Start text chat
    * @description Text for start chat label    
    * @title_de Start text chat
    * @description_de Text for start chat label    
    */
    startTextChat: LocalizedString
    /**
    * @title Connected text
    * @description Text for connected label   
    * @title_de Connected text
    * @description_de Text for connected label   
    */
    connectedText: LocalizedString
     /**
    * @title Requested text
    * @description Text for requested label   
    * @title_de Requested text
    * @description_de Text for requested label   
    */
    requestedText: LocalizedString
    /**
    * @title Ignored text
    * @description Text for ignored label   
    * @title_de Ignored text
    * @description_de Text for ignored label   
    */
    ignoredText: LocalizedString
    /**
    * @title Load more connections text
    * @description Text for load more connections label   
    * @title_de Load more connections text
    * @description_de Text for load more connections label   
    */
    loadMoreConnectionsText: LocalizedString
    /**
    * @title Show tactical view text
    * @description Text for show tactical view label   
    * @title_de Show tactical view text
    * @description_de Text for show tactical view label   
    */
    showTacticalViewText: LocalizedString
    /**
    * @title Show second gen text
    * @description Text for show second generation label   
    * @title_de Show second gen text
    * @description_de Text for show second generation label   
    */
    showSecondGenText: LocalizedString
    /**
    * @title Show relevant connections text
    * @description Text for show relevant connections label   
    * @title_de Show relevant connections text
    * @description_de Text for show relevant connections label   
    */
    showRelevantConnectionsText: LocalizedString
     /**
    * @title My connections text
    * @description Text for my connections label   
    * @title_de My connections text
    * @description_de Text for my connections label   
    */
    myConnectionsText: LocalizedString
    /**
    * @title Find new people text
    * @description Text for find new people tab title  
    * @title_de Find new people text
    * @description_de Text for find new people tab title  
    */
    findNewPeopleText: LocalizedString
    /**
    * @title Relevant tiles text
    * @description Text for relevant tab title  
    * @title_de Relevant tiles text
    * @description_de Text for relevant tab title  
    */
    relevantTilesText: LocalizedString
    /**
    * @title Filtered by interest text
    * @description Text for filtered by interest label  
    * @title_de Filtered by interest text
    * @description_de Text for filtered by interest label  
    */
    filteredByInterestText: LocalizedString
    /**
    * @title Exhibitors text
    * @description Text for exhibitors list item inside of the filter dropdown  
    * @title_de Exhibitors text
    * @description_de Text for exhibitors list item inside of the filter dropdown  
    */
    exhibitorsTitle: LocalizedString
    /**
    * @title Both text
    * @description Text for both list item inside of the filter dropdown  
    * @title_de Both text
    * @description_de Text for both list item inside of the filter dropdown  
    */
    bothTitle: LocalizedString
    /**
    * @title Attendees text
    * @description Text for attendees list item inside of the filter dropdown  
    * @title_de Attendees text
    * @description_de Text for attendees list item inside of the filter dropdown  
    */
    attendeesTitle: LocalizedString
    /**
    * @title Reset graph text
    * @description Text for reset graph label  
    * @title_de Reset graph text
    * @description_de Text for reset graph label  
    */
    resetGraphText: LocalizedString
     /**
    * @title Selected person type filter placeholder
    * @description Text for selected person type filter placeholder  
    * @title_de Selected person type filter placeholder
    * @description_de Text for selected person type filter placeholder  
    */
    selectedPersonTypeFilterPlaceholder: LocalizedString
    /**
    * @title Suggest search all
    * @description Text for suggest search all label  
    * @title_de Suggest search all
    * @description_de Text for suggest search all label  
    */
    suggestSearchAll: LocalizedString
    /**
    * @title Suggest name contains
    * @description Text for suggest name contains label  
    * @title_de Suggest name contains
    * @description_de Text for suggest name contains label  
    */
    suggestNameContains: LocalizedString
    /**
    * @title Load more suggestions text
    * @description Text for load more suggestions label  
    * @title_de Load more suggestions text
    * @description_de Text for load more suggestions label  
    */
    loadMoreSuggestionsText: LocalizedString
    /**
    * @title Collapse connections
    * @description Text for collapse connections label  
    * @title_de Collapse connections
    * @description_de Text for collapse connections label  
    */
    collapseConnections: LocalizedString
    /**
    * @title Hide from suggestions text
    * @description Text for hide from suggestions label  
    * @title_de Hide from suggestions text
    * @description_de Text for hide from suggestions label  
    */
    hideFromSuggestionsText: LocalizedString
    /**
    * @title Networking range action button bg color
    * @description Background color for networking range action button  
    * @title_de Networking range action button bg color
    * @description_de Background color for networking range action button  
    */
    networkingRangeActionButtonBgColor: ColorString
    /**
    * @title Networking range action button color
    * @description Text color for networking range action button  
    * @title_de Networking range action button color
    * @description_de Text color for networking range action button  
    */
    networkingRangeActionButtonColor: ColorString
    /**
    * @title Networking range button color
    * @description Color for networking range button  
    * @title_de Networking range button color
    * @description_de Color for networking range button  
    */
    networkingRangeButtonColor: ColorString
     /**
    * @title Reset graph border color
    * @description Border color for reset graph button (example: 1px solid #color)
    * @title_de Reset graph border color
    * @description_de Border color for reset graph button (example: 1px solid #color)
    */
    resetGraphBorderColor: string
    /**
    * @title Reset graph text color
    * @description Text color for reset graph button  
    * @title_de Reset graph text color
    * @description_de Text color for reset graph button  
    */
    resetGraphTextColor: ColorString
    /**
    * @title Reset graph background color
    * @description Background color for reset graph button  
    * @title_de Reset graph background color
    * @description_de Background color for reset graph button  
    */
    resetGraphBackgroundColor: ColorString
     /**
    * @title Load more connections bg color
    * @description Background color for load more connections button  
    * @title_de Load more connections bg color
    * @description_de Background color for load more connections button  
    */
    loadMoreConnectionsBgColor: ColorString
    /**
    * @title Load more connections color
    * @description Text color for load more connections button  
    * @title_de Load more connections color
    * @description_de Text color for load more connections button  
    */
    loadMoreConnectionsColor: ColorString
    /**
    * @title Load more suggestions border color
    * @description Border color for load more suggestions button  
    * @title_de Load more suggestions border color
    * @description_de Border color for load more suggestions button  
    */
    loadMoreSuggestionsBorderColor: string
    /**
    * @title Load more suggestions text color
    * @description Text color for load more suggestions button  
    * @title_de Load more suggestions text color
    * @description_de Text color for load more suggestions button  
    */
    loadMoreSuggestionsTextColor: ColorString
    /**
    * @title Load more suggestions on hover bg color
    * @description Background color on hover for load more suggestions button  
    * @title_de Load more suggestions on hover bg color
    * @description_de Background color on hover for load more suggestions button  
    */
    loadMoreSuggestionsOnHoverBgColor: ColorString
    /**
    * @title Load more suggestions on hover text color
    * @description Text color on hover for load more suggestions button  
    * @title_de Load more suggestions on hover text color
    * @description_de Text color on hover for load more suggestions button  
    */
    loadMoreSuggestionsOnHoverTextColor: string
    /**
    * @title Filter border bottom color
    * @description Bottom border color for filters (example: 1px solid #color)
    * @title_de Filter border bottom color
    * @description_de Bottom border color for filters (example: 1px solid #color) 
    */
    filterBorderBottomColor: string
    /**
    * @title Filter placeholder color
    * @description Text color for filter placeholder
    * @title_de Filter placeholder color
    * @description_de Text color for filter placeholder 
    */
    filterPlaceholderColor: ColorString

       /**
    * @title Filter input color
    * @description Text color for filter input
    * @title_de Filter input color
    * @description_de Text color for filter input 
    */
        filterInputColor: ColorString
    /**
    * @title Filter icon color
    * @description Fill color for filter icon
    * @title_de Filter icon color
    * @description_de Fill color for filter icon 
    */
    filterIconColor: ColorString
    /**
    * @title Node detail root bg color
    * @description Background color for node detail root 
    * @title_de Node detail root bg color
    * @description_de Background color for node detail root  
    */
    nodeDetailRootBgColor: ColorString
    /**
    * @title Node detail root border color
    * @description Border color for node detail root 
    * @title_de Node detail root border color
    * @description_de Border color for node detail root  
    */
    nodeDetailRootBorderColor: ColorString
    /**
    * @title Node detail root box shadow color
    * @description Box shadow color for node detail root
    * @title_de Node detail root box shadow color
    * @description_de Box shadow color for node detail root  
    */
    nodeDetailRootBoxShadowColor: string
    /**
    * @title Node detail content username text color
    * @description Text color for node detail content username
    * @title_de Node detail content username text color
    * @description_de Text color for node detail content username  
    */
    nodeDetailContentUsernameTextColor: ColorString
    /**
    * @title Node detail content title text color
    * @description Text color for node detail content title
    * @title_de Node detail content title text color
    * @description_de Text color for node detail content title  
    */
    nodeDetailContentTitleTextColor: ColorString
    /**
    * @title Node detail content interests title paragraph
    * @description Text color for node detail content interests title
    * @title_de Node detail content interests title paragraph
    * @description_de Text color for node detail content interests title
    */
    nodeDetailContentInterestsTitleParagraph: ColorString
    /**
    * @title Node detail content interests border
    * @description Border color for node detail content interests
    * @title_de Node detail content interests border
    * @description_de Border color for node detail content interests
    */
    nodeDetailContentInterestsBorder: ColorString
    /**
    * @title Node detail content interests background
    * @description Background color for node detail content interests
    * @title_de Node detail content interests background
    * @description_de Background color for node detail content interests
    */
    nodeDetailContentInterestsBackground: ColorString
    /**
    * @title Node detail content interests text color
    * @description Text color for node detail content interests
    * @title_de Node detail content interests text color
    * @description_de Text color for node detail content interests
    */
    nodeDetailContentInterestsTextColor: ColorString
    /**
    * @title Node detail content remove suggestion text color
    * @description Text color for node detail content remove suggestion
    * @title_de Node detail content remove suggestion text color
    * @description_de Text color for node detail content remove suggestion
    */
    nodeDetailContentRemoveSuggestionTextColor: ColorString
    /**
    * @title Autocomplete item hover color
    * @description Background color for autocomplete item on hover
    * @title_de Autocomplete item hover color
    * @description_de Background color for autocomplete item on hover
    */
    autocompleteItemHoverColor: ColorString,
    /**
    * @title Search placeholder text
    * @description Text for search box placeholder
    * @title_de Search placeholder text
    * @description_de Text for search box placeholder
    */
    searchPlaceholderText: LocalizedString
    /**
    * @title Zoomchart licences
    * @description List of zoomchart licences
    * @title_de Zoomchart licences
    * @description_de List of zoomchart licences
    */
    zoomChartLicences: ZoomChartLicenceObject[]
    /**
    * @title Autosuggest filter options
    * @description List of autosuggest filter options
    * @title_de Autosuggest filter options
    * @description_de List of autosuggest filter options
    */
    autosuggestFilterOptions: autosuggestFilterOptions[]
    /**
    * @title Disable load contacts of my contacts
    * @description Enable / disavle option load contacts out of my contacts
    * @title_de Disable load contacts of my contacts
    * @description_de Enable / disavle option load contacts out of my contacts
    */
    disableLoadContactsOfMyContacts: boolean
    /**
    * @title Node detail actions items per row
    * @description Number of node detail actions items per row
    * @title_de Node detail actions items per row
    * @description_de Number of node detail actions items per row
    */
    nodeDetailActionsItemsPerRow: number
    /**
    * @title Go to profile page text
    * @description Text for go to profile page label
    * @title_de Go to profile page text
    * @description_de Text for go to profile page label
    */
    goToProfilePageText: LocalizedString
    /**
    * @title Hide node text
    * @description Text for hide node label
    * @title_de Hide node text
    * @description_de Text for hide node label
    */
    hideNodeText: LocalizedString
    /**
    * @title Focus node text
    * @description Text for focus node label
    * @title_de Focus node text
    * @description_de Text for focus node label
    */
    focusNodeText: LocalizedString
    /**
    * @title Unfocus node text
    * @description Text for unfocus node label
    * @title_de Unfocus node text
    * @description_de Text for unfocus node label
    */
    unfocusNodeText: LocalizedString
    /**
    * @title Pin node text
    * @description Text for pin node label
    * @title_de Pin node text
    * @description_de Text for pin node label
    */
    pinNodeText: LocalizedString
    /**
    * @title Unpin node text
    * @description Text for unpin node label
    * @title_de Unpin node text
    * @description_de Text for unpin node label
    */
    unpinNodeText: LocalizedString
    /**
    * @title Collapse node text
    * @description Text for collapse node label
    * @title_de Collapse node text
    * @description_de Text for collapse node label
    */
    collapseNodeText: LocalizedString
    /**
    * @title Expand node text
    * @description Text for expand node label
    * @title_de Expand node text
    * @description_de Text for expand node label
    */
    expandNodeText: LocalizedString
     /**
    * @title Initial view type
    * @description Particular initial view type
    * @title_de Initial view type
    * @description_de Particular initial view type
    */
    initialViewType: viewType
    /**
    * @title Initial view type for lobby networking banner link 
    * @description Particular initial view type for lobby networking banner link 
    * @title_de Initial view type for lobby networking banner link 
    * @description_de Particular initial view type for lobby networking banner link 
    */
    initialViewTypeForLobbyNetworkingBanner: viewType
    /**
    * @title Available views
    * @description List of available views
    * @title_de Available views
    * @description_de List of available views
    */
    availableViews: viewType[]
     /**
    * @title Topic filter
    * @description List of topic filters
    * @title_de Topic filter
    * @description_de List of topic filters
    */
    topicFilter: Topic[]
    /**
    * @title Topic filter active
    * @description Enable / disable visibility of topic filter
    * @title_de Topic filter active
    * @description_de Enable / disable visibility of topic filter
    */
    topicFilterActive: boolean
    /**
    * @title Topic filter placeholder
    * @description Text for topic filter placeholder
    * @title_de Topic filter placeholder
    * @description_de Text for topic filter placeholder
    */
    topicFilterPlaceholder: LocalizedString
    /**
     * @title User categories 
     * @description Array of category aliases which are used for filtering users on networking page (viewType: USERS_FILTERED_BY_INTEREST)
     * @title_de User categories
     * @description_de Array of category aliases which are used for filtering users on networking page (viewType: USERS_FILTERED_BY_INTEREST)
     */
    userCategories: string[]
}

export enum viewType {
    USERS_FILTERED_BY_INTEREST = "USERS_FILTERED_BY_INTEREST",
    RELEVANT_TILES = "RELEVANT_TILES",
    RELEVANT_PPL = "RELEVANT_PPL",
    EXPLORE_MY_CON = "EXPLORE_MY_CON"
}
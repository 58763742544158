import { createState, State, useState } from "@hookstate/core"
import {
    ChannelResponse,
    getChannelInfo,
    lockChannel,
    MeetingKind,
    startLive,
    stopLive,
    StopReason,
    unlockChannel
} from "../../backendServices/MeetingServices"
import { chimeSdk } from "../ChimeSdkWrapper"
import { DataMessageType } from "../enums/DataMessageType"
import { defaultLogger as logger } from "../../globalStates/AppState"
import branding from "../../branding/branding"
import { ChannelStatus } from "../enums/ChannelStatus"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"

interface StateValues {
    channelStatus?: ChannelStatus
    isLocked: boolean
}

const getStartValues = (): StateValues => {
    return {
        channelStatus: undefined,
        isLocked: false
    }
}

export interface GreenRoomContext {
    onCallStart: (meetingKind: MeetingKind, meetingName: string) => void
    receiveMeetingDataMessage: (dataMessageType: DataMessageType, dataMessageJson: any) => void
    startLive: () => void
    stopLive: (reason?: StopReason) => Promise<boolean>
    lockChannel: (authorizedUsers: string[]) => Promise<boolean>
    unlockChannel: () => Promise<boolean>
    getChannelStatus: () => ChannelStatus | undefined
    isLocked: () => boolean
}

const createStateWrapper = (state: State<StateValues>) => {
    const handleChannelStatusState = (channelStatus: ChannelStatus) => {
        state.set((prev) => {
            prev.channelStatus = channelStatus
            return prev
        })
    }

    const handleLockedState = (isLocked: boolean) => {
        state.set((prev) => {
            prev.isLocked = isLocked
            return prev
        })
    }

    const fetchChannelStatusForGreenRoom = (kind: MeetingKind, externalMeetingId: string) => {
        if (kind === "greenroom") {
            getChannelInfo(externalMeetingId).then((resp) => {
                if ((resp as BackendServiceError).httpStatus) {
                    const error = resp as BackendServiceError
                    logger.error({
                        message: "Green room " + externalMeetingId + " channel status could not be retrieved.",
                        errorMessage: error.httpStatusText
                    })
                } else {
                    const channelResponse = resp as ChannelResponse
                    const newChannelStatus = channelResponse.isLive ? ChannelStatus.ON_AIR : ChannelStatus.OFF_AIR
                    handleChannelStatusState(newChannelStatus)
                    handleLockedState(channelResponse.isLocked)
                }
            })
        }
    }

    return {
        onCallStart: (meetingKind: MeetingKind, meetingName: string) => {
            fetchChannelStatusForGreenRoom(meetingKind, meetingName)
        },
        receiveMeetingDataMessage: (dataMessageType: DataMessageType, dataMessageJson: any) => {
            if (dataMessageType === DataMessageType.CHANNEL_STATUS) {
                handleChannelStatusState(dataMessageJson.data)
            }
        },
        startLive() {
            const channelId = chimeSdk.externalMeetingId?.substr(3)
            if (channelId) {
                handleChannelStatusState(ChannelStatus.PREPARING)
                chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, {
                    type: DataMessageType.CHANNEL_STATUS,
                    data: ChannelStatus.PREPARING
                })
                startLive(channelId).then((response) => {
                    if ((response as BackendServiceError).httpStatus) {
                        handleChannelStatusState(ChannelStatus.OFF_AIR)
                        chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, {
                            type: DataMessageType.CHANNEL_STATUS,
                            data: ChannelStatus.OFF_AIR
                        })
                    } else {
                        const channelResponse = response as ChannelResponse
                        const newChannelStatus = channelResponse.isLive ? ChannelStatus.ON_AIR : ChannelStatus.OFF_AIR
                        const estimatedTimeUntilLive = branding.greenroomGoLiveFollowupDelaySec * 1000 // TODO use time to live value returned from backend on going live instead
                        setTimeout(() => {
                            handleChannelStatusState(newChannelStatus)
                            chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, {
                                type: DataMessageType.CHANNEL_STATUS,
                                data: newChannelStatus
                            })
                        }, estimatedTimeUntilLive)
                    }
                })
            }
        },
        async stopLive(reason: StopReason = "default") {
            const channelId = chimeSdk.externalMeetingId?.substr(3)
            if (channelId) {
                const response = await stopLive(channelId, reason ?? "default")
                if ((response as BackendServiceError).httpStatus) {
                    handleChannelStatusState(ChannelStatus.ON_AIR)
                    chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, {
                        type: DataMessageType.CHANNEL_STATUS,
                        data: ChannelStatus.ON_AIR
                    })
                    return false
                } else {
                    const channelResponse = response as ChannelResponse
                    const newChannelStatus = channelResponse.isLive ? ChannelStatus.ON_AIR : ChannelStatus.OFF_AIR
                    handleChannelStatusState(newChannelStatus)
                    chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, {
                        type: DataMessageType.CHANNEL_STATUS,
                        data: newChannelStatus
                    })
                    return true
                }
            }
            return false
        },
        async lockChannel(authorizedUsers: string[]) {
            const channelId = chimeSdk.externalMeetingId?.substr(3)
            if (channelId) {
                const response = await lockChannel(channelId, authorizedUsers)
                if ((response as BackendServiceError).httpStatus) {
                    return false
                } else {
                    const channelResponse = response as ChannelResponse
                    handleLockedState(channelResponse.isLocked)
                    return true
                }
            }
            return false
        },
        async unlockChannel() {
            const channelId = chimeSdk.externalMeetingId?.substr(3)
            if (channelId) {
                const response = await unlockChannel(channelId)
                if ((response as BackendServiceError).httpStatus) {
                    return false
                } else {
                    const channelResponse = response as ChannelResponse
                    handleLockedState(channelResponse.isLocked)
                    return true
                }
            }
            return false
        },
        getChannelStatus() {
            return state.value.channelStatus
        },
        isLocked() {
            return state.value.isLocked
        }
    }
}

const state = createState<StateValues>(getStartValues())
export const useGreenRoomContext = (): GreenRoomContext => createStateWrapper(useState(state))

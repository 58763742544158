import * as React from "react";

/**
 * source: https://github.com/fitzmode/use-dynamic-refs/blob/master/src/index.tsx
 * but since this repo does not seem to be maintained anymore and it was causing
 * peer-dependency-conflicts, I just copy pasted the few relevant lines of code
 * if this package ever gets revived and allows us to use the most current
 * react version, we can include it in here again
 */
const map = new Map<string, React.RefObject<unknown>>();

function setRef<T>(key: string): React.RefObject<T> | void {
  if (!key) return console.warn(`useDynamicRefs: Cannot set ref without key `);
  const ref = React.createRef<T>();
  map.set(key, ref);
  return ref;
}

function getRef<T>(key: string): React.RefObject<T> | undefined | void {
  if (!key) return console.warn(`useDynamicRefs: Cannot get ref without key`);
  return map.get(key) as React.RefObject<T>;
}

function useDynamicRefs<T>(): [
  (key: string) => void | React.RefObject<T>,
  (key: string) => void | React.RefObject<T>
] {
  return [getRef, setRef];
}

export default useDynamicRefs;
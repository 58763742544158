import { createState, State, useState } from "@hookstate/core"
import { chimeSdk } from "../ChimeSdkWrapper"
import { defaultLogger as logger } from "../../globalStates/AppState"
import { DataMessageType } from "../enums/DataMessageType"
import DeviceType from "../types/DeviceType"

interface StateValues {
    muted: boolean
    mutedByMod: boolean
    volume: number
}

const getStartValues = (): StateValues => {
    return {
        muted: false,
        mutedByMod: false,
        volume: 100
    }
}

export interface AudioContext {
    onCallStart: (currentAudioOutputDevice: DeviceType | null, currentAudioInputDevice: DeviceType | null) => Promise<void>
    onCallEnd: () => Promise<void>
    getVolume: () => number
    setVolume: (volume: number) => void
    isMuted: () => boolean
    isMutedByMod: () => boolean
    setMutedByMod: (mutedByMod: boolean) => void
    bindAudioElement: (audioElement: React.MutableRefObject<null>) => void
    unbindAudioElement: () => void
    realtimeMuteLocalAudio: () => void
    realtimeUnmuteLocalAudio: () => void
    receiveAttendeeDataMessage: (dataMessageType: DataMessageType) => void
}

const CreateStateWrapper = (state: State<StateValues>): AudioContext => {

    const mutedUpdate = (localMuted: boolean) => {
        state.merge({ muted: localMuted })
    }

    return {
        onCallStart: async (currentAudioOutputDevice: DeviceType | null, currentAudioInputDevice: DeviceType | null) => {
            chimeSdk.audioVideo?.realtimeSubscribeToMuteAndUnmuteLocalAudio(mutedUpdate)
            if (currentAudioInputDevice) await chimeSdk.audioVideo?.startAudioInput(currentAudioInputDevice.value)
            if (currentAudioOutputDevice) await chimeSdk.audioVideo?.chooseAudioOutput(currentAudioOutputDevice.value)
        },
        onCallEnd: async () => {
            chimeSdk.audioVideo?.stopAudioInput()
            chimeSdk.audioVideo?.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(mutedUpdate)
        },
        isMuted: () => {
            return state.value.muted
        },
        isMutedByMod: () => {
            return state.value.mutedByMod
        },
        setMutedByMod: (mutedByMod: boolean) => {
            state.merge({ mutedByMod: mutedByMod })
        },
        getVolume: () => {
            return state.value.volume
        },
        setVolume: (volume: number) => {
            state.merge({ volume: volume })
        },
        bindAudioElement: (audioElement: React.MutableRefObject<null>) => {
            if (!audioElement || !audioElement.current) {
                logger.warn("ChimeContext AudioElement doesn't exist")
                return
            }
            chimeSdk.audioVideo?.bindAudioElement(audioElement.current!)
        },
        unbindAudioElement: () => {
            chimeSdk.audioVideo?.unbindAudioElement()
        },
        realtimeMuteLocalAudio: () => {
            chimeSdk.audioVideo?.realtimeMuteLocalAudio()
            state.merge({ muted: true })
        },
        realtimeUnmuteLocalAudio: () => {
            chimeSdk.audioVideo?.realtimeUnmuteLocalAudio()
            state.merge({ muted: false, mutedByMod: false })
        },
        receiveAttendeeDataMessage: (dataMessageType: DataMessageType) => {
            switch (dataMessageType) {
                case DataMessageType.MUTE:
                    chimeSdk.audioVideo?.realtimeMuteLocalAudio()
                    state.merge({ muted: true, mutedByMod: true })
                    break
            }
        }
    }
}

const state = createState<StateValues>(getStartValues())
export const useAudioContext = (): AudioContext => CreateStateWrapper(useState(state))

import { useState, RefObject } from "react"

export default function GuestUserBannerSharedState() {
    const [guestUserBannerRef, setGuestUserBannerRef] = useState<RefObject<HTMLDivElement>>()

    return {
        guestUserBannerRef,
        setGuestUserBannerRef
    }
}

import { BasisPremiumType } from "../branding/BasisPremiumBranding"
import { MeetingRoomGroupType } from "../conference/AudioVideoBranding"
import { CalendarEntry } from "./GraphQLServices"

export interface Entity {
    id: string
    entityType: EntityType
    name?: string
}
export interface Stand {
    id: string
    hallNr: string
    standNr: string
    type: string
    hallName: string
    hallNameShort: string
    standName: string
    standNameShort: string
    categories: Category[]
    displayName: string
}
export interface Contact {
    showroomStandby: any
    entityType: "person"
    id: string
    initials: string
    logoUrl: string
    firstName: string
    middleName: string
    infotext: string
    lastName: string
    countrycode?: string
    phone: string
    mobile?: string
    email: string
    fax: string
    position: string
    salutation: string
    company: string
    industry: string
    languages?: Array<String>
    organization: string
    comment: string
    stands: Stand[]
    socialMedias: SocialMedia[]
    categories: Category[]
    exhibitors: Exhibitor[]
    eventDates: EventDate[]
    interests?: Array<String>
    lookingfor?: Array<String>
    offering?: Array<String>
    facebook?: string
    linkedIn?: string
    googleplus?: string
    youTube?: string
    twitter?: string
    xing?: string
    pinterest?: string
    instagram?: string
    organizations?: Array<{ id: string; name: string }>
    sotUserId?: string
    person?: string
    myConnectionStatus: string
    networkingActive: boolean
    type: string
    topics?: string[]
    categoryBadges?: Category[]
    description?: Description
    visible: boolean
    title: string
    joboffers?: JobOffer[]
    created?: string
}
export interface News extends Entity {
    id: string
    entityType: "news"
    initials: string
    logoUrl: string
    pictureURL: string
    name: string
    title: string
    teaser: string
    text: string
    orderkey: string
    attachments: Attachment[]
    organization: Company
    links: Link[]
    categories: Category[]
    descriptionTeaser: string
    date: string
    description?: Description
    persons: Person[]
    externalUrl: string
    type: string
    totl: number
}
export interface Exhibitor extends Entity {
    entityType: "organization"
    orderkey: string
    initials: string
    email: string
    name: string
    countryCode: string
    country: string
    city: string
    postCode: string
    basisPremium: BasisPremiumType
    pictureURL: string
    pictureText: string
    backgroundImageURL: string
    teaser: string
    stands: Stand[]
    contacts: Contact[]
    categories: Category[]
    products: Product[]
    news: News[]
    eventdates: EventDate[]
    order?: number
    consentText: string
    consentTitle: string
}
export interface Description {
    title?: string
    text?: string
    teaser?: string
}
export interface SocialMedia {
    link: string
    type: string
}
export interface Link {
    id: string
    url: string
    text: string
}
export interface Office {
    adress: string
    city: string
    country: string
    postBoxPostCode: string
}
export interface Category extends Entity {
    entityType: "category"
    alias: string
    name: string
    nameDe: string
    orderkey: string
    orderkeyDe: string
    badgeName: string
    badgeType: string
    code?: string
    parent: string
    parentDe: string
    iconSmallUrl?: string
    iconMediumUrl?: string
    iconLargeUrl?: string
    colorBooth: string
    colorBorder: string
    colorFill: string
    colorText: string
    nodes?: Category[]
    key?: string
    label?: string
    labelDe?: string
    isSynthetic?: boolean
    stadsList?: any[] //don't be confused, the typo is also in the backend response, that's why it's here too
}
export interface Product extends Entity {
    entityType: "product"
    productid: string
    initials: string
    name: string
    logoUrl: string
    logoURL: string
    pictureURL: string
    pictureText: string
    description: Description
    categories: Category[]
    attachments: Attachment[]
    exhibitors: Exhibitor[]
    persons: Person[]
    links: Link[]
    organizations: Company[]
    descriptionTeaser: string
    stands: Stand[]
    orderkey: string
    productProperties?: ProductProperty[]
    typeId?: string
    typeName?: string
}

export interface ProductProperty extends Entity {
    productPropertyId: string
    productPropertyType: string
    name?: string
    unit?: string
    type: "TEXT" | "NUMERIC" | "ENUM"
    value?: string
}
export interface Coupon extends Entity {
    entityType: "coupon"
    initials: string
    orderkey: string
    title: string
    teaser: string
    description: string
    pictureURL: string
    pictureText: string
    url: string
    validStart: string
    validEnd: string
    company: {
        id: string
        name: string
        pictureURL: string
        consentText: string
        consentTitle: string
        consentTitleDe: string
    }
}
export interface Attachment {
    id: string
    url: string
    title: string
    description: string
    largeThumbnailUrl: string
    smallThumbnailUrl: string
    contentType: string
}
export interface Trademark extends Entity {
    entityType: "trademark"
    initials: string
    orderkey: string
    name: string
    logoUrl: string
    pictureURL: string
    pictureText: string
    description: Description
    categories: Category[]
    attachments: Attachment[]
    links: Link[]
    organizations: Company[]
    descriptionTeaser: string
}
export interface DescriptionCompany {
    description: Description
}
export interface Company {
    exhibitors: Exhibitor[]
    id: string
    alias: string
    orderkey: string
    initials: string
    name: string
    basisPremium: string
    adress1: string
    adress2: string
    adress3: string
    postCode: string
    postBoxCity: string
    web: string
    description: Description
    pictureURL: string
    pictureText: string
    backgroundImageURL: string
    foundingYear: string
    size: string
    industry: string
    socialMedias: SocialMedia[]
    links: Link[]
    offices: Office[]
    stands: Stand[]
    persons: Person[]
    allPersons: Person[]
    products: Product[]
    trademarks: Trademark[]
    coupons: Coupon[]
    city: string
    country: string
    phone: string
    email: string
    news: News[]
    categories: Category[]
    attachments: Attachment[]
    eventDates: EventDate[]
    headerVideoUrl: string
    headerVideoImageUrl: string
    children: Company[]
    parent: Company
    showProducts: boolean
    showTrademarks: boolean
    iframeUrl: string
    iframeUrlDe: string
    conferenceRooms: ConferenceRoom[]
    remoteLivestreams: RemoteLivestream[]
    consentText: string
    consentTitle: string
    joboffers: JobOffer[]
    standType?: string
}

export interface RemoteLivestream {
    id: string
    title: string
    teaserText: string
    url: string
    sortKey: string
    organization: string
    backgroundImageUrl: string
}

export interface Person {
    /**
     * @title Person id
     * @description Global identifier provided for specific person
     * @title_de Person id
     * @description_de Global identifier provided for specific person
     */
    id: string
    /**
     * @title Person user id
     * @description Corresponding sot user id binded to the person
     * @title_de Person user id
     * @description_de Corresponding sot user id binded to the person
     */
    userId: string
    /**
     * @title Person user logo url
     * @description Corresponding sot user logo url binded to the person
     * @title_de Person user logo url
     * @description_de Corresponding sot user logo url binded to the person
     */
    userLogoUrl: string
    /**
     * @title Persons name initials
     * @description Initials provided for person
     * @title_de Persons name initials
     * @description_de Initials provided for person
     */
    initials: string
    descriptionTeaser?: string
    /**
     * @title Persons first name
     * @description Text for the persons first name
     * @title_de Persons first name
     * @description_de Text for the persons first name
     */
    firstName: string
    /**
     * @title Persons middle name
     * @description Text for the persons middle name
     * @title_de Persons middle name
     * @description_de Text for the persons middle name
     */
    middleName: string
    /**
     * @title Persons last name
     * @description Text for the persons last name
     * @title_de Persons last name
     * @description_de Text for the persons last name
     */
    lastName: string
    /**
     * @title Persons email address
     * @description Text for the persons email address
     * @title_de Persons email address
     * @description_de Text for the persons email address
     */
    email: string
    personFunctionIdRef: string
    personFunctionName?: string
    personFunctionTargetName?: string
    /**
     * @title Persons work position
     * @description Text for the persons work position
     * @title_de Persons work position
     * @description_de Text for the persons work position
     */
    position: string
    /**
     * @title Persons organization
     * @description Name of the organization that person belongs to
     * @title_de Persons organization
     * @description_de Name of the organization that person belongs to
     */
    organization: string
    /**
     * @title Persons logo url
     * @description Custom logo url that person has
     * @title_de Persons logo url
     * @description_de Custom logo url that person has
     */
    logoUrl: string
    /**
     * @title Persons category configurations
     * @description Configuration for persons categories
     * @title_de Persons category configurations
     * @description_de Configuration for persons categories
     */
    categories: Category[]
    /**
     * @title Persons topic id
     * @description Id for the topic that person belongs to
     * @title_de Persons topic id
     * @description_de Id for the topic that person belongs to
     */
    topic: any
    /**
     * @title Persons visiblity
     * @description Enable / disable persons visibility
     * @title_de Persons visiblity
     * @description_de Enable / disable persons visibility
     */
    visible: boolean
    /**
     * @title Showroom standby option
     * @description Enable / disable visibility for showroom standby option for the specific person
     * @title_de Showroom standby option
     * @description_de Enable / disable visibility for showroom standby option for the specific person
     */
    showroomStandby: boolean
    /**
     * @title Persons name
     * @description Complete persons name (first name + last name)
     * @title_de Persons name
     * @description_de Complete persons name (first name + last name)
     */
    name: string
    /**
     * @title Persons title
     * @description Text for persons title
     * @title_de Persons title
     * @description_de Text for persons title
     */
    title: string
}

export interface EventDateBase extends Entity {
    entityType: "eventdate"
    type?: string
    name: string
    nameDe?: string
    organizationId?: string
    start?: string
    end?: string
    date?: string
    startdatetime: string
    enddatetime: string
    descriptionLong?: string
    descriptionLongDe?: string
    descriptionTitle?: string
    descriptionTitleDe?: string
    descriptionShort?: string
    descriptionShortDe?: string
    accessProvider: AccessProvider
    ticketUrls: Array<string>
    restrictedAreaType: "privateEvent"
    locationActionType?: string
    locationActionParams?: string
}
export interface EventDate extends EventDateBase {
    location: string
    orderkey: string
    date: string
    start: string
    startHour: number
    dateTimeStart: string
    startMinutes: number
    end: string
    endHour: number
    endMinutes: number
    dateTimeEnd: string
    persons: Person[]
    teaser: string
    text: string
    formattedDate: string
    logoText: string
    logoUrl: string
    attachments: Attachment[]
    organizationLogoUrl?: string
    organizationName?: string
    channel?: Channel
    liveStreamingPreviewUrl: string
    videoOnDemandUrl: string
    order?: number
    categories?: Category[]
    roundTableAccessStatus?: string
    links: Link[]
    event: Event[]
    breakoutAccess: boolean
    organizationConsent?: any
    typeformUrl: string
    locationActionType?: string
    locationActionParams?: string
    formattedStartDateTime?: string
    startDateTime: string
    endDateTime: string
    stand: Stand
}

export enum AccessProvider {
    PUBLIC = "PUBLIC",
    BACKOFFICE = "BACKOFFICE",
    TICKET = "TICKET"
}

export type RestrictedArea = EventDateBase | MeetingRoomGroupType | ConferenceRoom
export interface Event extends Entity {
    entityType: "event"
    id: string
    name: string
    nameDe: string
    location: string
    locationDe: string
    start: string
    end: string
    logo: string
    categories: Category[]
    descriptionShort: string
    descriptionShortDe: string
    descriptionLong: string
    descriptionLongDe: string
    eventDates?: Array<EventDate>
}

export interface Channel {
    id: string
    name: string
    slidoName: string
    url: string
    thumbnailUrl: string
    currentEventDate?: EventDate
}
export interface Suggestion {
    alias?: string
    id: string
    value: string
    title: string
    subtitle: string
    icon: string
    additionalData?: any
    type?: EntityType
    active?: boolean
    suggestionGroupId?: string
}
export interface SuggestGroup {
    id: string
    name: string
    trackingName: string
    maxSuggestions: number
    suggestions: Suggestion[]
}

export interface Organization {
    id: string
    name: string
    city: string
    country: string
    logo: string
    pictureURL: string
}
export interface NetworkingUser extends Entity {
    id: string
    entityType: "networking_user"
    orderkey: string
    firstName: string
    lastName?: string
    logoUrl?: string
    loaded: boolean
    position?: string
    company?: string
    organization?: string
    speaker?: number
    contactOfExhibitor?: number
    showLoader?: boolean
    rating?: number
    organizations?: Array<Organization>
    interests?: Array<string>
    myConnectionStatus?: string
    person?: string
    type?: string
    descriptionTeaser?: string
}
export interface InvitePerson {
    id: string
    firstName: string
    lastName?: string
    name?: string // full name
    logoUrl?: string
    pictureUrl?: string
    userId?: string
    meetings?: CalendarEntry[]
    eventDates?: EventDate[]
}

export interface PrivacyUserAnswer {
    sotUserId: string
    questionText: string
    answerText: string
    accepted: boolean
    questionId: string
}

export interface VirtualCafeAccessUser {
    id: string
    firstName: string
    lastName: string
    logoUrl: string
    position: string
    company: string
}

export interface NewsItem {
    id: string
    title: string
    text: string
    subtitle: string
    date: string
    imageurl: string
    links: { link_text: string; link_url: string }[]
}

export interface CategoryBadges {
    id: string
    badgeName: string
    badgeType: string
    colorBorder: string
    colorBooth: string
    colorText: string
    colorFill: string
}

export interface SotUser {
    /**
     * @title Series of topics user id
     * @description Global identifier provided for specific series of topics user
     * @title_de Series of topics user id
     * @description_de Global identifier provided for specific series of topics user
     */
    id: string
    /**
     * @title Series of topics userid
     * @description User identifier provided for specific series of topics user
     * @title_de Series of topics userid
     * @description_de User identifier provided for specific series of topics user
     */
    userId: string
    /**
     * @title Series of topics user name initials
     * @description Initials provided for sot user
     * @title_de Series of topics user name initials
     * @description_de Initials provided for sot user
     */
    initials: string
    /**
     * @title Series of topics user first name
     * @description Text for the first name provided for sot user
     * @title_de Series of topics user first name
     * @description_de Text for the first name provided for sot user
     */
    firstName: string
    /**
     * @title Series of topics user last name
     * @description Text for the last name provided for sot user
     * @title_de Series of topics user last name
     * @description_de Text for the last name provided for sot user
     */
    lastName: string
    /**
     * @title Series of topics user position
     * @description Text for position provided for sot user
     * @title_de Series of topics user position
     * @description_de Text for position provided for sot user
     */
    position: string
    /**
     * @title Series of topics user organization
     * @description Name of the organization that sot user belongs to
     * @title_de Series of topics user organization
     * @description_de Name of the organization that sot user belongs to
     */
    organization: string
    /**
     * @title Series of topics user logo url
     * @description Custom logo url that sot user has
     * @title_de Series of topics user logo url
     * @description_de Custom logo url that sot user has
     */
    logoUrl: string
    /**
     * @title Series of topics user description teaser
     * @description Text for sot user description teaser
     * @title_de Series of topics user description teaser
     * @description_de Text for sot user description teaser
     */
    descriptionTeaser: string
    /**
     * @title Series of topics user type teaser
     * @description Text for sot user type teaser
     * @title_de Series of topics user type teaser
     * @description_de Text for sot user type teaser
     */
    type?: string
    userType?: string
}

export enum UserQuestionType {
    NUMERIC = "NUMERIC",
    TEXT = "TEXT"
}

export interface UserQuestion {
    id: string
    text: string
    orderNumber: number
    type: UserQuestionType
    allowUserDefinedAnswerOption: boolean
    showUserDefinedAnswerOptions: boolean
    minAnswerCount: number
    maxAnswerCount: number
    answerOptions?: UserAnswerOption[]
}

export interface UserAnswerOption {
    id: string
    orderNumber?: number
    type?: UserQuestionType
    feUserDefined?: boolean
    value: string
}

export interface UserAnswer {
    optionId?: string
    value?: string
}

export interface JobOffer extends Entity {
    id: string
    entityType: "joboffer"
    name: string
    orderkey: string
    initials: string
    pictureURL: string
    descriptionTeaser: string
    location: string
    employmentType: string
    startdate: string
    homeOffice: boolean
    careerLevel: string
    requirements?: string
    description?: Description
    organization: Company
    attachments: Attachment[]
    links: Link[]
    persons: Person[]
    categories: Category[]
}

export interface ConferenceRoom {
    id: string
    title: string
    backgroundImage: string
    teaser: string
    sortKey: string
    organization: string
    accessType: UserConferenceRoomAccessType
    restrictedAreaType: "conferenceroom"
}

export enum UserConferenceRoomAccessType {
    PUBLIC = "PUBLIC",
    ORGA_MANAGED = "ORGA_MANAGED"
}

export enum ShareTargetType {
    ORGANIZATION = "ORGANIZATION",
    PERSON = "PERSON",
    SOTUSER = "SOTUSER",
    EVENTDATE = "EVENTDATE",
    EVENT = "EVENT",
    PRODUCT = "PRODUCT",
    TRADEMARK = "TRADEMARK",
    NEWS = "NEWS",
    JOBOFFER = "JOBOFFER"
}

export type EntityEventDate = "eventdate"
export type EntityPerson = "person"
export type EntityOrganization = "organization"
export type EntityProduct = "product"
export type EntityTrademark = "trademark"
export type EntityNews = "news"
export type EntityCategory = "category"
export type EntityCustomCategory = "customcategory"
export type EntityCoupon = "coupon"
export type EntityNetworkingUser = "networking_user"
export type EntityUser = "sotuser"
export type EntityEvent = "event"
export type EntityCountry = "country"
export type EntityJobOffer = "joboffer"
export type EntityStandRegistration = "stand_registration"

export type EntityType =
    | EntityEventDate
    | EntityPerson
    | EntityOrganization
    | EntityProduct
    | EntityTrademark
    | EntityNews
    | EntityCategory
    | EntityCustomCategory
    | EntityCoupon
    | EntityNetworkingUser
    | EntityUser
    | EntityEvent
    | EntityCountry
    | EntityJobOffer
    | EntityStandRegistration

export type PersonOrUserType = EntityPerson | EntityUser

export type ModalType = "none" | "connect" | "meeting" | "call" | "share" | "report" | "guest"

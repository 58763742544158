import styled from "styled-components"
import { useEffect, useState, useCallback, Suspense } from "react"
import * as React from "react"
import { useLocation } from "react-router-dom"
import FilterBar from "../../ui/FilterBar"
import { syncFavorites } from "../../backendServices/FavoritesServices"
import CenteredLoader from "../../ui/CenteredLoader"
import { Person } from "../../backendServices/Types"
import { useFavoriteState } from "../../globalStates/Favorites"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import moment from "moment"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import BackendError from "../../ui/BackendError"
import EmptyTile from "../reception/EmptyTile"
import { defaultLogger as logger, useAppState } from "../../globalStates/AppState"
import { calcBreadcrumbLocations } from "../../tracking/RouteTracker"
import TopBar from "../../navigationArea/TopBar"
import Breadcrumb from "../../navigationArea/Breadcrumb"
import { ViewMode } from "../../ui/CrsTabs"
import SpeakersTilesLayout from "../../ui/SpeakersTilesLayout"
import SpeakersListLayout from "../../ui/SpeakersListLayout"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { loadPersonsData, PersonsDataRequestParams } from "../../backendServices/PersonServices"
import InView from "react-intersection-observer"

/*********************************************************************************************
 * variable definitions
 **********************************************************************************************/

let content: JSX.Element = <div />

const NextPageLoader = styled(CenteredLoader)`
    height: 120px;
`

/*********************************************************************************************
 * site assembly
 **********************************************************************************************/
const SpeakersRoot = styled.div`
    background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
`
const ContentRoot = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100%;
    width: 100%;
    overflow-x: hidden;
    background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
`

const PaddingDiv = styled.div<{ currentPage?: string }>`
    position: relative;
    padding: ${(props) =>
        window.scrollY > 0
            ? "25px 25px 15px 25px"
            : props.currentPage === "program"
            ? "25px 25px 15px 25px"
            : "25px 15px 15px 25px"};
`

const numResultRow = 12
interface SpeakersProps {
    addBannerHeight?: number
    onScroll?: Function
    guestBannerHeight?: number
    showOnlyBookmarks?: boolean
    listViewMode: boolean
    showOnlyFavorites?: boolean
}

const Speakers: React.FunctionComponent<SpeakersProps> = (props) => {
    const appState = useAppState()
    const [isLoaded, setIsLoaded] = useState(false)
    const [viewMode, setViewMode] = useState(props.showOnlyBookmarks ? ViewMode.LIST : ViewMode.TILES)
    const [isBookmarked, setIsBookmarked] = useState<boolean>(props.showOnlyBookmarks ? true : false)
    const [error, setError] = useState("")
    const [allPersons, setAllPersons] = useState<Person[]>([])
    const [startResultRow, setStartResultRow] = useState(0)
    const [filterParam, setFilterParam] = useState<string | null>(null)
    const [countPersons, setCountPersons] = useState(0)
    const location = useLocation()
    const favorites = useFavoriteState()
    const loggedInUserId = useLoggedInState().user()?.profileId
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const [refreshKey, setRefreshKey] = useState(0)
    const [showBookmarkEventsModal, setShowBookmarkEventsModal] = useState<boolean>(false)
    const favoriteState = useFavoriteState()

    const { useMobileDesign } = useWindowDimensions()

    const [customScrollRenderDelay, setCustomScrollRenderDelay] = useState<boolean>(false)
    const adjustedHeightForScrollContainer = useMobileDesign
        ? 110
        : (props.addBannerHeight ?? 0) + (props.guestBannerHeight ?? 0) + 180 // topBanner + guestBanner + other elements above real content (220px)

    useEffect(() => {
        if (isBookmarked && favoriteState.refreshFavoriteUserId !== "") {
            setAllPersons(allPersons.filter((person) => person.id !== favoriteState.refreshFavoriteUserId))
        }
        // eslint-disable-next-line
    }, [favoriteState.readOnlyRefreshFavorites])

    const loadMorePerson = useCallback(() => {
        setStartResultRow((e) => e + numResultRow)
    }, [])

    const isMoreDataToLoad = useCallback(() => {
        return countPersons - numResultRow > startResultRow
    }, [countPersons, startResultRow])

    useEffect(() => {
        setViewMode(props.listViewMode ? ViewMode.LIST : ViewMode.TILES)
    }, [props.listViewMode])

    useEffect(() => {
        setIsBookmarked(props.showOnlyBookmarks ?? props.showOnlyFavorites ?? false)
        // eslint-disable-next-line
    }, [props.showOnlyFavorites])

    useEffect(() => {
        setStartResultRow(0)
    }, [isBookmarked])

    useEffect(() => {
        if (props.showOnlyBookmarks) {
            appState.setCurrentMobileBreadcrumb(strings.programSpeakers.bookmarkedSpeakersTitle)
        }
        // eslint-disable-next-line
    }, [lang])

    useEffect(() => {
        function syncLocalStorage() {
            if (loggedInUserId) {
                syncFavorites({
                    profileId: loggedInUserId,
                    body: {
                        currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), // "2020-01-01 12:00:00",
                        lastSyncTime: favorites.getLastSyncTime()
                    }
                })
                    .then((resp) => {
                        //setIsLoaded(true)
                        favorites.setLastSyncTime(new Date())
                        const temp: Array<any> = resp.content.favorites
                        temp.forEach((item) => {
                            if (!item.deleted && !favorites.is("person", item.id)) {
                                favorites.add("person", item.id)
                            }
                        })
                        setRefreshKey(1)
                    })
                    .catch((error: { message: React.SetStateAction<string> }) => {
                        setIsLoaded(true)
                        logger.error({ message: "Speakers Fav sync failed", errorMessage: error.message })
                    })
            }
        }
        syncLocalStorage()
        //eslint-disable-next-line
    }, [location, loggedInUserId])

    useEffect(() => {
        const timeout: number = window.setTimeout(() => {
            loadSpeakersData()
        }, 500)

        return () => clearTimeout(timeout)
    }, [startResultRow, filterParam, isBookmarked, refreshKey, numResultRow, lang]) // eslint-disable-line

    function loadSpeakersData() {
        const favoritePersons = favorites.get("person")
        if (isBookmarked && (!favoritePersons || favoritePersons.length === 0)) return
        const speakerPersonFunctions = branding.configuration.speakerPersonFunctions
            .map((personFunction) => `personfunc_${personFunction}`)
            .join(", ")
        const personIds = isBookmarked ? favoritePersons : ""
        const eventDateParticipation = branding.configuration.eventDateParticipation ? "evtdpartcp_" : ""
        const personParameter: PersonsDataRequestParams = {
            filterlist: ["entity_pers", personIds, speakerPersonFunctions, eventDateParticipation],
            startresultrow: startResultRow,
            numresultrows: numResultRow,
            order: branding.programSpeakers.orderType
        }

        if (filterParam) {
            personParameter["alpha"] = filterParam
        }

        setIsLoaded(false)
        loadPersonsData(personParameter)
            .then((response) => {
                if (isBookmarked && favorites.get("person") === "") {
                    setAllPersons([])
                    setCountPersons(0)
                } else if (refreshKey === 1) {
                    setAllPersons((speaker) =>
                        startResultRow === 0 ? (speaker = response.persons) : speaker.concat(response.persons)
                    )
                    setCountPersons((e) => (e = response.count))
                }
                setIsLoaded(true)

                const tmpTimeout = setTimeout(() => {
                    setCustomScrollRenderDelay(true)
                    clearTimeout(tmpTimeout)
                }, 10)
            })
            .catch((e: { message: React.SetStateAction<string> }) => {
                setIsLoaded(true)
                setError(e.message)
                const tmpTimeout = setTimeout(() => {
                    setCustomScrollRenderDelay(true)
                    clearTimeout(tmpTimeout)
                }, 10)
            })
    }

    if (error !== "") {
        content = (
            <div style={{ marginTop: "20%" }}>
                <BackendError />
            </div>
        )
    } else if (!isLoaded) {
        content = (
            <div style={{ marginTop: "15%" }}>
                <NextPageLoader />
            </div>
        )
    } else if (countPersons === 0 || !allPersons || allPersons.length === 0) {
        content = (
            <div style={{ marginTop: "12%" }}>
                <EmptyTile header={branding.programSpeakers.noBookmarkedSpeakers} bgColor="transparent" hideButton={true} />
            </div>
        )
    }

    if (countPersons > 0) {
        content = (
            <>
                {
                    <ContentRoot id="customScrollbarSpeakers">
                        <ContentScrollContainer
                            adjustForHeaderWith={`${adjustedHeightForScrollContainer}px`}
                            handleScroll={(e: any) => {
                                if (props.onScroll) {
                                    props.onScroll({
                                        clientHeight: (e as { clientHeight: number }).clientHeight,
                                        contentScrollHeight: (e as { scrollHeight: number }).scrollHeight,
                                        scrollTop: (e as { scrollTop: number }).scrollTop
                                    })
                                }
                            }}
                        >
                            {customScrollRenderDelay && (
                                <PaddingDiv currentPage={appState.currentItem}>
                                    {viewMode === ViewMode.TILES && allPersons && (
                                        <SpeakersTilesLayout
                                            speakersList={allPersons}
                                            showBookmarkEventsModal={showBookmarkEventsModal}
                                            setShowBookmarkEventsModal={setShowBookmarkEventsModal}
                                            componentType={"person"}
                                        />
                                    )}
                                    {viewMode === ViewMode.LIST && allPersons && (
                                        <SpeakersListLayout
                                            speakersList={allPersons}
                                            setShowBookmarkEventsModal={setShowBookmarkEventsModal}
                                        />
                                    )}
                                </PaddingDiv>
                            )}
                            {isMoreDataToLoad() && (
                                <InView
                                    threshold={0.1}
                                    onChange={(inView) => {
                                        if (inView) loadMorePerson()
                                    }}
                                >
                                    <NextPageLoader />
                                </InView>
                            )}
                        </ContentScrollContainer>
                    </ContentRoot>
                }
            </>
        )
    }

    const locations = calcBreadcrumbLocations(strings)
    const breadcrumb: any[] = [
        {
            to: "/",
            name: strings.sideIconBar.lobbyMenuText
        }
    ]
    breadcrumb.push(
        { to: "/", name: strings.receptionPage.receptionPageMyHeaderTitle },
        { to: locations[1], name: strings.programSpeakers.bookmarkedSpeakersTitle }
    )

    return (
        <div style={{ background: "#fff" }}>
            {props.showOnlyBookmarks && (
                <>
                    <TopBar />
                    <Breadcrumb breadcrumb={breadcrumb} />
                    <FilterBar activeFilter={filterParam} onFilterChanged={setFilterParam} showTopBorder />
                </>
            )}
            <SpeakersRoot style={{ paddingTop: props.showOnlyBookmarks ? "50px" : "0px" }}>
                <Suspense fallback={<NextPageLoader />}>
                    <div>{content}</div>
                </Suspense>
            </SpeakersRoot>
        </div>
    )
}

export default Speakers

import { User } from "../globalStates/LoggedInUser"

export function hasAccessToOrganization(user: User, organizationId: string): boolean {
    if (user && user.type === "guest") {
        return (user.invitingOrganization && user.invitingOrganization.id === organizationId) ?? false
    }
    return true
}

export function hasAccessToUser(user: User | undefined, person: any) {
    if (user && user.type === "guest") {
        if (person.organizations && user.invitingOrganization) {
            for (const organization of person.organizations) {
                if (organization.id === user.invitingOrganization.id) {
                    return true
                }
            }
        }
        return false
    }
    return true
}

export function shouldCompleteProfile(user?: User): boolean {
    if ((user && !user?.firstName) || !user?.lastName || !user?.position || !user?.company) {
        return true
    }

    return false
}

import React from "react"
import styled from "styled-components"
import { getEnvironment } from "../../../../environments"
import { UserOrganization } from "../../../../globalStates/LoggedInUser"
import { useVideoContext } from "../../../context/VideoContext"

const CompanyLogoContainer = styled.div`
    background-color: #fff;
    border-radius: 5px;
`

interface UploadLogoProps {
    organizations?: UserOrganization[]
}
export function LogoCompany(props: UploadLogoProps) {
    const videoContext = useVideoContext()

    if (!props.organizations || !props.organizations.length) return null

    return (
        <CompanyLogoContainer>
            {props.organizations.map((organization) => {
                // on local machines we get a "Canvas is not origin-clean" error if the logo src is not local.
                // In that case the dmexcolog.png is used from the public folder. Set as default value in the constructor.

                // it seems by design it is possible just to put one company logo. ??? Not clear. Let's talk about this part.

                // for testing, i added the organizer logo

                if (!organization.logo) return null
                const logo = getEnvironment() === "dev" ? "/branding/organizerlogo_closed.png" : organization.logo

                return (
                    <RadioButtonWithImage
                        key={organization.id}
                        alt={organization.name + " Logo"}
                        imageSrc={logo}
                        radioGroup="logoCompanies"
                        checked={logo === videoContext.getLogoSrc()}
                        onClick={() => videoContext.setLogoSrc(logo)}
                    />
                )
            })}
        </CompanyLogoContainer>
    )
}

const RadioButtonWithImageRoot = styled.div<{ logoSrc: string }>`
    width: 100%;
    height: 87px;
    background-image: ${(props) => `url(${props.logoSrc})`};
    background-repeat: no-repat;
    background-size: cover;
    background-color: #fff;
    border-radius: 5px;
`
interface RadioButtonWithImageProps {
    alt: string
    imageSrc: string
    radioGroup: string
    checked: boolean
    onClick: () => void
}
function RadioButtonWithImage(props: RadioButtonWithImageProps) {
    return (
        <RadioButtonWithImageRoot onClick={props.onClick} logoSrc={props.imageSrc}>
            <input
                style={{ display: "none" }}
                type="radio"
                name={props.radioGroup}
                checked={props.checked}
                onChange={props.onClick}
            />
        </RadioButtonWithImageRoot>
    )
}

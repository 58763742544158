import * as React from "react"
import styled from 'styled-components';
// import branding from '../../branding/branding';
// import { useLanguageState } from '../../globalStates/LanguageState';
// import CrsMultiSwitch from '../../ui/CrsMultiSwitch';
import { personFilterType } from './NetworkingPageContent';
import SuggestionsSlider, { SuggestionsSliderProps } from './components/SuggestionsSlider';
import ZoomRangeSlider from './components/ZoomRangeSlider';
import { viewType } from './NetworkingPageContentBranding';

const BottomFilterContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    bottom: 0;
    right: 0;
    padding-bottom: 1rem; /* was 2rem */
    z-index: 1300;
    background-color: white; 
`

const BottomFilterContainerGradient = styled.div`
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 50px;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
`

// const BottomFilterActionBtn = styled.div`
//     font-family: ${branding.font1};
//     display: flex;
//     width: 145px;
//     height: 35px;
//     white-space: nowrap;
//     justify-content: center;
//     align-items: center;
//     padding: .4rem 2.5rem; 
//     border-radius: 5px;
//     color: ${branding.networkingArea.loadMoreSuggestionsTextColor};
//     border: ${branding.networkingArea.loadMoreSuggestionsBorderColor};
//     /* border: ${branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9'}; */
//     font-size: 12px; 
//     cursor: pointer;
//     user-select: none;
//     margin-bottom: 16px;
//     &:hover{
//         color: ${branding.networkingArea.loadMoreSuggestionsOnHoverTextColor};
//         background-color: ${branding.networkingArea.loadMoreSuggestionsOnHoverBgColor};
//         transition: .5s;
//     }
//     @media(max-width: 1500px) {
//         font-size: 12px; 
//         padding: .3rem 1.5rem; 
//     }
// `

// const CrsMultiSwitchStyle = styled.div`
//     margin-bottom: 12px;
//         * {
//             font-size: ${branding.crsMultiSwitcher.crsMultiSwitcherMultiSwitchItemFontSize ?? "12px"};
//             white-space: nowrap;
//         }
//     @media (max-width: 1600px) {
//         * {
//             font-size: ${branding.crsMultiSwitcher.crsMultiSwitcherMultiSwitchItemFontSize ?? "12px"};
//             white-space: nowrap;
//         }
//     }
// `

interface BottomFilterAreaProps {
    bottomFilterRef: React.RefObject<HTMLDivElement>
    personTypeData: { value: personFilterType; label: string; }[]
    selectedPersonType: personFilterType
    currentViewType: viewType
    zoomStrengthValue: number[]
    suggestionSliderProps: SuggestionsSliderProps
    showLoadNewSuggestionsBtn: boolean
    currentDataPage: number
    setCurrentDataPage: (value: React.SetStateAction<number>) => void
    setZoomStrengthValue: React.Dispatch<React.SetStateAction<number[]>>
    setSelectedPersonType: React.Dispatch<React.SetStateAction<personFilterType>>
}

const BottomFilterArea = (props: BottomFilterAreaProps) => {
    // const languageState = useLanguageState()
    // const strings = languageState.getStrings()


    return (
        <BottomFilterContainer ref={props.bottomFilterRef}>
            <BottomFilterContainerGradient />
            {/* <CrsMultiSwitchStyle>
                <CrsMultiSwitch items={props.personTypeData} activeItem={props.selectedPersonType} onItemClick={props.setSelectedPersonType} style={{ mainWidth: '280px', border: branding.mainBorder ? branding.mainBorder : '1px solid #d9d9d9' }} />
            </CrsMultiSwitchStyle> */}
       
            {props.currentViewType === viewType.EXPLORE_MY_CON &&
                <ZoomRangeSlider zoomStrengthValue={props.zoomStrengthValue} setZoomStrengthValue={props.setZoomStrengthValue} />
            }
            {props.currentViewType === viewType.RELEVANT_PPL &&
                <SuggestionsSlider
                    loadSuggestionsValue={props.suggestionSliderProps.loadSuggestionsValue}
                    setLoadSuggestionsValue={props.suggestionSliderProps.setLoadSuggestionsValue}
                    hasNextPage={props.suggestionSliderProps.hasNextPage}
                    setCurrentDataPage={props.suggestionSliderProps.setCurrentDataPage} />
            }
           {/* {(props.currentViewType === viewType.RELEVANT_PPL && props.showLoadNewSuggestionsBtn) && 
                <BottomFilterActionBtn onClick={() => props.setCurrentDataPage(props.currentDataPage + 1)}>{strings.networkingArea.loadMoreSuggestionsText}</BottomFilterActionBtn>} */}
        </BottomFilterContainer>
    )
}

export default BottomFilterArea;
import { VideoFrameBuffer, VideoFrameProcessor } from "amazon-chime-sdk-js"

export type Position = "topleft" | "topright" | "bottomleft" | "bottomright"
export class LogoProcessor implements VideoFrameProcessor {
    logo = new Image()
    processLogo = false
    position: Position

    /* #region  Constructor and Deconstructor */
    constructor(position: Position) {
        this.logo.src = ""
        this.logo.crossOrigin = "anonymous"
        this.position = position
    }

    async destroy() {
        return
    }
    /* #endregion */

    /* #region  Setters */
    setSrc(src: string | null) {
        if (!src || src.length === 0) {
            this.processLogo = false
            this.logo.src = ""
        } else {
            this.processLogo = true
            this.logo.src = src
        }
    }

    setPosition(position: Position) {
        this.position = position
    }

    getPosition() {
        return this.position
    }
    /* #endregion */

    /* #region  Process Video Frames */

    async process(buffers: VideoFrameBuffer[]) {
        // No frames? Nothing to do!
        if (buffers.length === 0 || !this.processLogo) {
            return Promise.resolve(buffers)
        }

        // @ts-ignore
        const canvasElement = buffers[0].asCanvasElement()
        if (!canvasElement) return Promise.resolve(buffers)
        const canvas = canvasElement as HTMLCanvasElement

        // If the buffer contains video frames of size 0 * 0 , return
        const frameWidth = canvas.width
        const frameHeight = canvas.height
        if (frameWidth === 0 || frameHeight === 0 || this.logo.width === 0 || this.logo.height === 0) {
            return Promise.resolve(buffers)
        }

        const { dx, dy, dw, dh } = this.calcPositionAndSize(canvas.width, canvas.height)

        /**
         * Iterate the video frames and process them
         */
        for (const bufferFrame of buffers) {
            try {
                // @ts-ignore
                const context = (bufferFrame.asCanvasElement()! as HTMLCanvasElement).getContext("2d")!
                context.drawImage(this.logo, dx, dy, dw, dh)
            } catch (err) {
                console.log("Exception:: ", err)
            }
        }
        return Promise.resolve(buffers)
    }

    /**
     * Calculates size and position for the logo.
     *
     * Width is a minimum of 100px and a maximum of 1/5 of the canvas
     *
     * Height depends on the aspect ratio
     *
     * Position depends on the position member
     * @param canvasWidth the width of the canvas where we draw on
     */
    calcPositionAndSize(canvasWidth: number, canvasHeight: number): { dx: number; dy: number; dw: number; dh: number } {
        const dw = Math.max(100, Math.min(canvasWidth / 5, this.logo.width))
        const dh = dw * (this.logo.height / this.logo.width)

        // Position for logo rendering
        let dx: number
        let dy: number
        switch (this.position) {
            case "topright":
                dx = canvasWidth - dw - 20
                dy = 20
                break
            case "bottomleft":
                dx = 20
                dy = canvasHeight - dh - 20
                break
            case "bottomright":
                dx = canvasWidth - dw - 20
                dy = canvasHeight - dh - 20
                break
            default:
                dx = 20
                dy = 20
        }
        return { dx: dx, dy: dy, dw: dw, dh: dh }
    }
    /* #endregion */
}

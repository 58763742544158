import styled from "styled-components"
import * as React from "react"
import { Button } from "react-bootstrap"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { IconGlobalError } from "../../ui/Icons"

const ErrorContentPanel = styled.div`
    min-width: 200px;
    max-width: 300px;
    font-family: ${branding.font1};
`

const ErrorImagePanel = styled.div`
    width: 100%;
    margin-top: 184px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
`

const TitlePanel = styled.div`
    font-size: 20px;
    font-weight: bold;
    text-align: center;
`

const DescriptionPanel = styled.div`
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
`

const ReloadButtonPannel = styled.div`
    display: flex;
    justify-content: center;
`

const ReloadButton = styled(Button)`
    margin-top: 30px;
    width: 120px;
    max-width: 300px;
    height: 35px;
    border-radius: 5px;
    background: ${branding.errorSite.reloadButtonColor};
    border: none;
    font-family: ${branding.font1};
    font-size: 12px;

    &:active {
        background: ${branding.errorSite.reloadButtonColor};
    }

    &:hover {
        background: ${branding.errorSite.reloadButtonColor};
        opacity: 0.7;
    }
`

interface ErrorSiteProps {}

const ErrorSite: React.FC<ErrorSiteProps> = (props) => {
    const languageState = useLanguageState()
    const strings = languageState.getStrings()

    function onReloadClicked() {
        window.location.reload()
    }
    return (
        <ErrorContentPanel>
            <ErrorImagePanel>{IconGlobalError({ fill: branding.errorSite.iconColor })}</ErrorImagePanel>
            <TitlePanel>{strings.errorSite.errorTitle}</TitlePanel>
            <DescriptionPanel>{strings.errorSite.errorDescription}</DescriptionPanel>
            <ReloadButtonPannel>
                <ReloadButton variant="primary" onClick={onReloadClicked}>
                    {strings.errorSite.reloadButtonText}
                </ReloadButton>
            </ReloadButtonPannel>
        </ErrorContentPanel>
    )
}

export default ErrorSite

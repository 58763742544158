/*********************************************************************************************
 * PRIVACY POLICY
 **********************************************************************************************/

import branding from "../branding/branding"
import { BackendServiceError, fetchDataRest, topic } from "./BackendServicesUtils"
import { PrivacyUserAnswer } from "./Types"
import { defaultLogger as logger } from "../globalStates/AppState"

export const createPrivacyUserQuestionId = (organizationId: string) => {
    if (branding.globalOptIn) {
        return "vg_tracking_" + branding.configuration.topicName
    }
    return "vg_tracking_" + branding.configuration.topicName + "/" + organizationId
}

export async function getPrivacyUserAnswer(sotUserId: string, organizationId: string): Promise<PrivacyUserAnswer | undefined> {
    const defaultRoute: string = `/privacypolicy/topic/${topic}/sotuserid/${sotUserId}/answer`
    const params = { organizationId }
    try {
        const data = await fetchDataRest(defaultRoute, params, "GET")
        if ((data as PrivacyUserAnswer[]).length > 0)
            return (data as PrivacyUserAnswer[]).find(
                (pa: PrivacyUserAnswer) => pa.questionId === createPrivacyUserQuestionId(organizationId)
            )
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return undefined
    }
}

export interface addPrivacyPolicyAnswerParams {
    sotUserId: string
    answerText: string
    questionText: string
    organizationId: string
}

export async function addPrivacyPolicyAnswer(
    params: addPrivacyPolicyAnswerParams
): Promise<PrivacyUserAnswer | BackendServiceError> {
    const defaultRoute: string = `/privacypolicy/topic/${topic}/sotuserid/${params.sotUserId}/privacy`
    const body = {
        answerText: params.answerText,
        questionText: params.questionText,
        questionId: createPrivacyUserQuestionId(params.organizationId)
    }
    try {
        return await fetchDataRest(defaultRoute, null, "POST", body)
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            body,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

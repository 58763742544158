import React from "react"
import branding from "../branding/branding"
import BSFormCheck from "react-bootstrap/FormCheck"
import styled from "styled-components"

const InputContainer = styled.div<{ textColor: string }>`
    color: ${(props) => props.textColor};

    input[type="radio"] {
        /* remove standard background appearance */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /* create custom radiobutton appearance */
        display: inline-block;
        width: 12px;
        height: 12px;
        padding: 2px;
        /* background-color only for content */
        background-clip: content-box;
        border: 1px solid ${branding.sideIconBar.sideIconColorDark};
        background-color: transparent;
        border-radius: 50%;
    }

    /* appearance for checked radiobutton */
    input[type="radio"]:checked {
        background-color: ${branding.crsTabs.defaultActionItemActiveStateColor};
        border: 1px solid ${branding.crsTabs.defaultActionItemActiveStateColor};
    }
`

interface RadioFieldProps {
    id: string
    label: string
    name: string
    value: string
    radioValue: string
    textColor: string
    onChange: (value: string) => void
}

const RadioField: React.FC<RadioFieldProps> = (props: RadioFieldProps) => {
    return (
        <InputContainer textColor={props.textColor}>
            <BSFormCheck className="form-check-inline">
                <BSFormCheck.Input
                    id={props.id}
                    type="radio"
                    name={props.name}
                    checked={props.value === props.radioValue}
                    onChange={() => {
                        props.onChange(props.radioValue)
                    }}
                />
                <BSFormCheck.Label htmlFor={props.id}>{props.label}</BSFormCheck.Label>
            </BSFormCheck>
        </InputContainer>
    )
}

export default RadioField

import { fetchDataWebService } from "./BackendServicesUtils"
import { Trademark } from "./Types"
import { defaultLogger as logger } from "../globalStates/AppState"

/*********************************************************************************************
 * TRADEMARK LIST
 **********************************************************************************************/
export interface TrademarkListResponse {
    count: number
    trademarks: Trademark[]
}

export async function loadTrademarksData(params: object, signal?: AbortSignal): Promise<TrademarkListResponse> {
    try {
        const data = await fetchDataWebService("/search", params, signal, true)
        return { count: data.count, trademarks: data.entities }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

/*********************************************************************************************
 * TRADEMARK DETAILS
 **********************************************************************************************/
export interface TrademarkResponse {
    content: Trademark
}

export interface TrademarkDetailsParams {
    trademarkid: string
    rootCategories?: Array<string>
    showCategoryHierarchy?: boolean
}

export async function loadTrademarkData(params: TrademarkDetailsParams, signal?: AbortSignal): Promise<TrademarkResponse> {
    try {
        const data = await fetchDataWebService("/trademarkdetails", params, signal)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/trademarkdetails",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

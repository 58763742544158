import { useEffect, useState } from "react"

type PopupEventHandler = (popupOpen: boolean) => void

class PopupObserver {
    private static INSTANCE: PopupObserver

    private handlers: PopupEventHandler[] = []
    private observerRegistered: boolean = false
    private observer = new MutationObserver(this.callback)
    private lastValue = false

    constructor() {
        PopupObserver.INSTANCE = this
    }

    informHandlers(popupOpen: boolean) {
        // Only inform handlers if the value changed
        if (this.lastValue === popupOpen) return
        this.lastValue = popupOpen

        // Inform every registered handler
        for (const handler of this.handlers) {
            handler(popupOpen)
        }
    }

    /** Callback that is called every time a childList change is recognized on the body element*/
    callback(_mutations: MutationRecord[], _observer: MutationObserver) {
        /* We iterate over the body child's because the mutations only show what's mutated. We won't allways have the information about the modal beeing added in teh mutations */
        for (let i = 0; i < document.body.children.length; i++) {
            const classString = document.body.children.item(i)!.getAttribute("class")

            // We define a popup currently as having the "modal" class name
            if (classString && classString.indexOf("modal") >= 0) {
                PopupObserver.INSTANCE.informHandlers(true)
                return
            }
        }
        PopupObserver.INSTANCE.informHandlers(false)
    }

    registerHandler(handler: PopupEventHandler) {
        this.handlers.push(handler)

        // If the observer doesn't observer yet, let's change that, because we have listeners
        if (!this.observerRegistered) {
            this.observerRegistered = true
            this.observer.observe(document.body, { childList: true })
        }
    }

    unregisterHandler(handler: PopupEventHandler) {
        const index = this.handlers.indexOf(handler, 0)
        if (index > -1) {
            this.handlers.splice(index, 1)
        }

        // If there are no longer any listeners we don't need to observer anylonger
        if (this.handlers.length === 0) {
            this.observerRegistered = false
            this.observer.disconnect()
        }
    }
}

const popupObserver = new PopupObserver()

/**
 * Will detected elements with `modal` class names in the document body.
 * @return `true` if one such element is present, `false` otherwise
 */
export default function usePopupDetection() {
    const [popupOpen, setPopupOpen] = useState(false)

    useEffect(() => {
        popupObserver.registerHandler(setPopupOpen)
        return () => {
            popupObserver.unregisterHandler(setPopupOpen)
        }
    }, [])

    return popupOpen
}

import { useRef, useState, useEffect, useCallback } from "react"
import _, { debounce } from "lodash"
import * as React from "react"
import styled from "styled-components"
import InfiniteScroll from "react-infinite-scroll-component"
import { Contact } from "../backendServices/Types"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import Spinner from "react-bootstrap/esm/Spinner"
import { IconSearchSmall, IconClearSearchItem } from "../ui/Icons"
import { useLanguageState } from "../globalStates/LanguageState"
import branding from "../branding/branding"
import AvatarWithPresenceState from "../ui/AvatarWithPresenceState"
import "moment-timezone"
import { BackendServiceError } from "../backendServices/BackendServicesUtils"
import {
    loadRelevantProfilesListData,
    loadContactListData,
    ContactListResponse
} from "../backendServices/SeriesOfTopicsUserServices"
import { getSearchPersonTitle, isNullOrEmpty } from "./helpers/GlobalFunctions"

const SearchPersonInputRoot = styled.div<{ isOpen?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: row;
    border: ${(props) => (props.isOpen ? "1px solid #727272" : "1px solid #d9d9d9")};
    border-radius: 5px;
    height: 35px;
    font-size: 12px;
    color: #727272;

    /* &:focus {
        border: 1px solid red !important;
    } */
    /* background-color:#e8e8e8; */
    /* border-top-right-radius: 5px;
    border-top-left-radius: 5px; */
`
const SearchPersonsRoot = styled.div`
    display: none;
    position: absolute;
    top: 36px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #727272;
    width: 100%;
    height: 240px;
    overflow: hidden;
    z-index: 10;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`
const SearchPersonResultItem = styled.div<{ hideBorder?: boolean; pointerEnabled?: boolean; highlight?: boolean }>`
    border-bottom: ${(props) => (props.hideBorder ? "unset" : "")};
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};
    padding: 8px;
    background-color: ${(props) => (props.highlight ? branding.calendarEntryModalPageContent.resultItemHover : "white")};
    pointer-events: ${(props) => (props.pointerEnabled ? "auto" : "none")};
    cursor: pointer;
    display: flex;
    flex-flow: row;
    align-items: center;

    &.disabled {
        cursor: auto;
        color: gray;
        pointer-events: none;
    }
`
const SearchPersonResultTitle = styled.div<{ shortenPersonInfo?: boolean }>`
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
`

const SearchPersonResultTitleCompany = styled.div<{ shortenPersonInfo?: boolean }>`
    display: block;
    white-space: nowrap;
    overflow: ${(props) => (props.shortenPersonInfo ? "hidden" : "visible")};
    text-overflow: ${(props) => (props.shortenPersonInfo ? "ellipsis" : "initial")};
`
const PersonInput = styled.input`
    border: 0px;
    flex-grow: 1;
    /* margin-right: 4px;
    padding-top: 8px; */
    /* background-color: #e8e8e8; */
    &:focus {
        outline: none;
        color: ${branding.primaryColor};
    }

    &::placeholder {
        color: #727272 !important;
    }
`
const SearchIcon = styled.div<{ topSearch?: string }>`
    flex: 0 0 auto;
    width: 15px;
    height: 100%;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: ${(props) => (props.topSearch ? props.topSearch : "-3px")};
    top: 0px;
    & svg {
        width: 100%;
        height: 100%;
    }
`
const ClearIcon = styled.span<{ topClear?: string }>`
    flex: 0 0 auto;
    width: 15px;
    height: 15px;
    margin-right: 12px;
    margin-top: ${(props) => (props.topClear ? props.topClear : "-1px")};
    /* background-color: #e8e8e8 */
    /* & svg {
        width: 100%;
        height: 100%;
    } */
`
interface ResultPerson extends Contact {
    disabled: boolean
}

interface PersonSearchInputProps {
    placeholder: string
    personClicked: (person: Contact) => void
    className?: string
    disableSearchIcon?: boolean
    disabledText?: string
    disableIds?: string[] // IDs of persons to be displayed as disabled in the result list
    excludeIds?: string[] // IDs of persons to be excluded from the result list
    calendarEntryModal?: boolean
    start?: Date
    end?: Date
    timezone?: string
    topSearch?: string
    topClear?: string
    menuOpened?: () => void
    shortenPersonInfo?: boolean
}

const SearchPersonInput: React.FC<PersonSearchInputProps> = (props) => {
    const userState = useLoggedInState()

    const inputRef = useRef<HTMLInputElement>(null)
    const resultRowRef = useRef<HTMLDivElement>(null)
    const [isOpen, setOpen] = useState(false)
    const [page, setPage] = useState(0)
    const [resultPersons, setResultPersons] = useState<ResultPerson[]>([])
    const [clickedPersons, setClickedPersons] = useState<ResultPerson[]>([])
    const [searchStringValue, setSearchStringValue] = useState("")
    const [searchString, setSearchString] = useState("")
    const [hasNext, setHasNext] = useState<boolean>()
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
    const [pointerEnabled, setPointerEnabled] = useState<boolean>(true)
    const [searchPersonRootHeight, setSearchPersonRootHeight] = useState<number>(0)
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const lang = languageState.getLanguage()

    useEffect(() => {
        const profileId = userState.user()?.profileId
        if (!profileId) return
        ;(userState.isMatchActive()
            ? loadRelevantProfilesListData(profileId!, { page: page, itemsPerPage: 25, searchString: searchString })
            : loadContactListData(profileId!, { page: page, itemsPerPage: 25, searchString: searchString })
        ).then((data) => {
            if (searchString !== inputRef.current?.value) return
            if (!data) return
            if ((data as BackendServiceError).httpStatus) {
                // TODO ERROR
            } else {
                const contactListResp = data as ContactListResponse
                const pageResultPersons: ResultPerson[] = []
                contactListResp.contacts
                    .filter((contact) => !props.excludeIds?.includes(contact.sotUser.id))
                    .forEach((contact) => {
                        const disabled =
                            props.disableIds?.includes(contact.sotUser.id) ||
                            contact.sotUser.myConnectionStatus === "BLOCKED" ||
                            contact.sotUser.myConnectionStatus === "BLOCKING"

                        // Filter for guests, which people they can invite.
                        if (userState.user()?.type === "guest" && userState.user()?.invitingOrganization) {
                            if (
                                contact.sotUser.organizations &&
                                contact.sotUser?.organizations[0] &&
                                contact.sotUser?.organizations[0].id === userState.user()?.invitingOrganization?.id
                            ) {
                                pageResultPersons.push({ disabled: disabled, ...contact.sotUser })
                            }
                        } else {
                            pageResultPersons.push({ disabled: disabled, ...contact.sotUser })
                        }
                    })

                setHasNext(contactListResp.hasNextPage)

                //setResultPersons(page === 0 ? pageResultPersons : resultPersons.concat(pageResultPersons))

                setResultPersons(
                    _.uniqBy(page === 0 ? pageResultPersons : resultPersons.concat(pageResultPersons), function (p) {
                        return p.id
                    })
                )
            }
        })
    }, [page, searchString, lang]) // eslint-disable-line

    useEffect(() => {
        const tempResultPersons = resultPersons
            .filter((contact) => !props.excludeIds?.includes(contact.id))
            .map((contact) => {
                const newDisabled = props.disableIds?.includes(contact.id) ?? false
                const { disabled, ...rest } = contact
                return { disabled: newDisabled, ...rest }
            })
        setResultPersons(tempResultPersons)
    }, [props.excludeIds, props.disableIds]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (resultPersons.length < 11 && hasNext) {
            setPage(page + 1)
        }
    }, [resultPersons]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedIndex === null) {
            let personElement = document.getElementsByClassName("infiniteScrollPersonInput")[0]
            personElement.scrollTop = 0
        }
    }, [selectedIndex]) // eslint-disable-line react-hooks/exhaustive-deps

    const debouncedSearch = React.useMemo(
        () =>
            debounce((value: string) => {
                setSearchString(value)
                setPage(0)
                setSelectedIndex(null)
            }, 800),
        []
    )

    const handleSearchDebounced = useCallback(
        (searchStr: string) => {
            debouncedSearch(searchStr)
        },
        [debouncedSearch]
    )

    function onSearchStringChanged(e: React.FormEvent<HTMLInputElement>) {
        const searchStr = e.currentTarget.value
        setSearchStringValue(searchStr)
        setOpen(true)
        handleSearchDebounced(searchStr)
    }

    function onClickClearIcon() {
        setOpen(false)
        setSelectedIndex(null)
        setSearchString("")
        setSearchStringValue("")
        handleSearchDebounced("")
    }

    function onExit() {
        setOpen(false)
        setPage(0)
        setSelectedIndex(null)
    }

    function onPersonClicked(person: ResultPerson) {
        if (person.disabled) {
            return
        }
        props.personClicked(person)
        setClickedPersons(clickedPersons.concat(person))
        setOpen(false)
        setPage(0)
        setSearchString("")
        setSearchStringValue("")
        setSelectedIndex(null)
    }

    function onKeyPressed(event: any, person: ResultPerson) {
        if (selectedIndex === null) {
            setSelectedIndex(0)
        }
        //on Enter pressed
        if (event.keyCode === 13 && person) {
            event.preventDefault()
            onPersonClicked(person)
        }
        //on arrowDown pressed
        if (event.keyCode === 40 && selectedIndex != null && selectedIndex < resultPersons.length - 1) {
            event.preventDefault()
            setPointerEnabled(false)
            setSelectedIndex(selectedIndex + 1)
            let personElement = document.getElementById("person" + (selectedIndex + 1).toString())
            //if(personElement && parentElement && (personElement?.offsetTop > parentElement?.offsetHeight + parentElement?.scrollTop))
            personElement?.scrollIntoView({ block: "nearest", behavior: "smooth" })
        }
        //on arrowUp pressed
        if (event.keyCode === 38 && selectedIndex != null && selectedIndex > 0) {
            event.preventDefault()
            setPointerEnabled(false)
            setSelectedIndex(selectedIndex - 1)
            let personElement = document.getElementById("person" + (selectedIndex - 1).toString())
            //if(personElement && parentElement && (personElement?.offsetTop < parentElement?.offsetHeight + parentElement?.scrollTop))
            personElement?.scrollIntoView({ block: "nearest", behavior: "smooth" })
        }
    }

    const resultItemsCount = Math.max(resultPersons.length, 1)

    const calculateTheDropdownHeight = () => {
        let searchPersonResultItemList = document.getElementsByClassName("searchPersonResultItem")
        let height = 0
        for (let index = 0; index < searchPersonResultItemList.length; index++) {
            const element = searchPersonResultItemList[index]
            height += element.clientHeight
        }
        setSearchPersonRootHeight((height > 250 ? 250 : height) || 53) // Max height was 400, current 250 and if there are no results height should be 53 to show the "No results found" row
    }
    useEffect(() => {
        if (isOpen) calculateTheDropdownHeight()
    }, [resultPersons.length, isOpen])

    useEffect(() => {
        if (isOpen && props.menuOpened) props.menuOpened()
        // eslint-disable-next-line
    }, [isOpen])

    /**
     * @param person
     * @returns string
     *
     * This function generates a title when the user
     * hovers over a person result in the search input
     */
    function personInfoTitle(person: any): string {
        let title: string = ""
        let personFullName = [person.firstName, person.lastName].join(" ")
        let personPosition = person.position
        let personCompany = person.company

        if (personFullName && personPosition && personCompany) {
            title = personFullName + " | " + personPosition + " / " + personCompany
        } else if (personFullName && personPosition && !personCompany) {
            title = personFullName + " | " + personPosition
        } else if (personFullName && !personPosition && personCompany) {
            title = personFullName + " | " + personCompany
        } else if (personFullName && !personPosition && !personCompany) {
            title = personFullName
        } else {
            title = personFullName
        }

        return title
    }

    return (
        <SearchPersonInputRoot className={props.className} isOpen={isOpen}>
            {!props.disableSearchIcon && (
                <SearchIcon topSearch={props.topSearch}>
                    {IconSearchSmall({ fill: branding.sideIconBar.sideIconColorDark })}
                </SearchIcon>
            )}
            <PersonInput
                ref={inputRef}
                placeholder={props.placeholder}
                onChange={onSearchStringChanged}
                onClick={() => setOpen(true)}
                onBlur={onExit}
                value={searchStringValue}
                onKeyDown={(event) => onKeyPressed(event, resultPersons[selectedIndex!])}
            />
            {!props.disableSearchIcon && (
                <ClearIcon
                    topClear={props.topClear}
                    onClick={onClickClearIcon}
                    style={{
                        color: branding.mainInfoColor ?? "#000",
                        visibility: searchStringValue !== "" ? "visible" : "hidden"
                    }}
                >
                    {IconClearSearchItem({ fill: branding.sideIconBar.sideIconColorDark })}
                </ClearIcon>
            )}
            <SearchPersonsRoot
                id="searchPersons"
                style={{ display: isOpen ? "block" : "none", height: resultItemsCount < 12 ? searchPersonRootHeight : 240 }}
                onPointerMove={(event) => {
                    setPointerEnabled(true)
                }}
            >
                <InfiniteScroll
                    style={{ overflowX: "hidden" }}
                    className="infiniteScrollPersonInput"
                    dataLength={resultItemsCount}
                    next={() => setPage(page + 1)}
                    hasMore={hasNext as boolean}
                    scrollableTarget="searchPersons"
                    scrollThreshold="100%"
                    height={240}
                    loader={
                        <SearchPersonResultItem style={{ justifyContent: "center" }}>
                            <Spinner animation="border" />
                        </SearchPersonResultItem>
                    }
                >
                    {resultPersons.map((person, index) => (
                        <SearchPersonResultItem
                            id={"person" + index}
                            hideBorder={resultPersons.length - 1 === index}
                            highlight={index === selectedIndex}
                            pointerEnabled={pointerEnabled}
                            tabIndex={index}
                            ref={resultRowRef}
                            key={index}
                            onMouseEnter={(event) => setSelectedIndex(index)}
                            onMouseDown={(event) => {
                                event.preventDefault()
                                onPersonClicked(person)
                            }}
                            className={[person.disabled ? "disabled" : "", "searchPersonResultItem"].join(" ")}
                        >
                            <AvatarWithPresenceState
                                badgeSize={8}
                                showAvatarBadge={true}
                                badgeRight={20}
                                badgeTop={23}
                                userId={person.id}
                                avatarSize={36}
                                content={{ pictureUrl: person.logoUrl, alt: person.firstName + " " + person.lastName }}
                            ></AvatarWithPresenceState>
                            <SearchPersonTextRoot>
                                <SearchPersonTitleContainer
                                    title={props.shortenPersonInfo ? personInfoTitle(person) : ""}
                                    calendarEntryModal={props.calendarEntryModal}
                                >
                                    <SearchPersonResultTitle>
                                        {_.truncate([person.firstName, person.lastName].join(" "), { length: 30 })}
                                    </SearchPersonResultTitle>
                                    {(!isNullOrEmpty(person.position) || !isNullOrEmpty(person.company)) && <Divider>|</Divider>}
                                    <SearchPersonResultTitleCompany shortenPersonInfo={props.shortenPersonInfo}>
                                        {getSearchPersonTitle(person.company, person.position)}
                                    </SearchPersonResultTitleCompany>
                                </SearchPersonTitleContainer>
                            </SearchPersonTextRoot>
                        </SearchPersonResultItem>
                    ))}
                    {resultPersons.length === 0 && (
                        <SearchPersonResultItem
                            key="mt"
                            className="disabled"
                            onMouseDown={(event) => {
                                event.preventDefault()
                            }}
                        >
                            {strings.chatBranding.noResultFoundText}
                        </SearchPersonResultItem>
                    )}
                </InfiniteScroll>
            </SearchPersonsRoot>
        </SearchPersonInputRoot>
    )
}

const Divider = styled.p`
    padding: 0 10px 0 10px;
`

const SearchPersonTextRoot = styled.div`
    display: flex;
    flex-flow: column;
`
const SearchPersonTitleContainer = styled.div<{ calendarEntryModal?: boolean }>`
    display: flex;
    flex-flow: row;
    line-height: 17px;
    font-size: 12px;
    margin-bottom: 2px;
    font-weight: bold;
    width: ${(props) => (props.calendarEntryModal ? "530px" : "220px")};
`

export default SearchPersonInput

// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import {
    AudioVideoFacade,
    ConsoleLogger,
    DefaultDeviceController,
    DefaultMeetingSession,
    DefaultModality,
    Device,
    DeviceChangeObserver,
    LogLevel,
    MeetingSession,
    MeetingSessionConfiguration,
    VideoInputDevice
} from "amazon-chime-sdk-js"
import throttle from "lodash/throttle"
import {
    AttendeeData,
    AttendeeRole,
    ban,
    ChimeMeetingData,
    createOrJoinMeeting,
    getAttendeeInfo,
    kick,
    leaveRoom,
    MeetingKind
} from "../backendServices/MeetingServices"
import { BackendServiceError } from "../backendServices/BackendServicesUtils"
import { getEnvironment } from "../environments"
import { defaultLogger as logger } from "../globalStates/AppState"
import { MeetingStatus } from "./context/ChimeContext"
import { MeetingStatusCode } from "./enums/MeetingStatusCode"
import FullDeviceInfoType from "./types/FullDeviceInfoType"
import RosterType from "./types/RosterType"

export default class ChimeSdkWrapper implements DeviceChangeObserver {
    /* #region  members */
    private static ROSTER_THROTTLE_MS = 1000

    meetingId?: string

    externalMeetingId?: string

    attendeeId?: string

    externalUserId?: string

    userRole?: AttendeeRole

    meetingSession: MeetingSession | null = null

    meetingTimeLeft: number | null = null

    meetingMaxDuration: number | null = null

    timeLimitChanged: boolean = false

    meetingKind: MeetingKind = "virtualCafe"

    maxAttendees: number = 5

    localAttendeeId: string | null = null

    audioVideo: AudioVideoFacade | null = null

    devicesUpdatedCallbacks: ((fullDeviceInfo: FullDeviceInfoType) => void)[] = []

    roster: RosterType = {}

    rosterUpdateCallbacks: ((roster: RosterType) => void)[] = []

    configuration: MeetingSessionConfiguration | null = null

    unhandledrejection = (event: PromiseRejectionEvent) => {
        this.logError(event.reason)
    }
    /* #endregion */

    initializeSdkWrapper = async () => {
        this.meetingSession = null
        this.audioVideo = null
        this.roster = {}
        this.rosterUpdateCallbacks = []
        this.configuration = null
        this.userRole = undefined
        this.meetingId = undefined
        this.externalMeetingId = undefined
        this.attendeeId = undefined
        this.externalUserId = undefined
    }

    createOrJoinRoom = async (
        externalUserId: string,
        externalMeetingId: string,
        contactState: {
            setConnectionStatus: (id: string, connectionStatus: string) => void
            setUserType: (id: string, userType: string) => void
        }
    ): Promise<void> => {
        if (this.lastLeaveRoomPromise) {
            await this.lastLeaveRoomPromise
            this.lastLeaveRoomPromise = null // Neccessary to avoid double leaves
        }
        const response = await createOrJoinMeeting(externalMeetingId, externalUserId)
        if ((response as BackendServiceError).httpStatus) {
            throw response
        }
        const meetingData = response as ChimeMeetingData
        this.meetingTimeLeft = meetingData.meeting.remainingDurationMillis
        this.meetingMaxDuration = meetingData.meeting.maxDurationSeconds
        this.timeLimitChanged = meetingData.meeting.timeLimitChanged
        this.meetingKind = meetingData.meeting.meetingKind
        this.maxAttendees = meetingData.meeting.maxAttendees
        this.meetingId = meetingData.chime.Meeting.MeetingId
        this.externalMeetingId = externalMeetingId
        this.attendeeId = meetingData.chime.Attendee.AttendeeId
        this.externalUserId = externalUserId
        this.userRole = meetingData.attendee.role

        this.configuration = new MeetingSessionConfiguration(meetingData.chime.Meeting, meetingData.chime.Attendee)
        await this.initializeMeetingSession(this.configuration, contactState)
        this.localAttendeeId = meetingData.chime.Attendee.AttendeeId
    }

    initializeMeetingSession = async (
        configuration: MeetingSessionConfiguration,
        contactState: {
            setConnectionStatus: (id: string, connectionStatus: string) => void
            setUserType: (id: string, userType: string) => void
        }
    ): Promise<void> => {
        let logLevel = LogLevel.ERROR
        switch (getEnvironment()) {
            case "dev":
            case "integration":
                logLevel = LogLevel.WARN
        }
        const logger = new ConsoleLogger("SDK", logLevel)
        const deviceController = new DefaultDeviceController(logger)
        this.meetingSession = new DefaultMeetingSession(configuration, logger, deviceController)
        this.audioVideo = this.meetingSession.audioVideo
        try {
            // Needed to initialize DefaultDeviceController deviceInfoCache
            // otherwise selection of others divices does not work properly
            await Promise.all([
                this.audioVideo?.listAudioInputDevices(),
                this.audioVideo?.listAudioOutputDevices(),
                this.audioVideo?.listVideoInputDevices()
            ])
        } catch (e) {
            // errors do not concern us here
        }

        this.audioVideo?.realtimeSubscribeToAttendeeIdPresence((presentAttendeeId: string, present: boolean): void => {
            if (!present) {
                delete this.roster[presentAttendeeId]
                this.publishRosterUpdate.cancel()
                this.audioVideo?.realtimeUnsubscribeFromVolumeIndicator(presentAttendeeId)
                this.publishRosterUpdate()
                return
            }

            this.audioVideo?.realtimeSubscribeToVolumeIndicator(
                presentAttendeeId,
                async (
                    attendeeId: string,
                    volume: number | null,
                    muted: boolean | null,
                    signalStrength: number | null,
                    externalUserId?: string
                ) => {
                    const baseAttendeeId = new DefaultModality(attendeeId).base()
                    if (baseAttendeeId !== attendeeId) {
                        // Don't include the content attendee in the roster.
                        //
                        // When you or other attendees share content (a screen capture, a video file,
                        // or any other MediaStream object), the content attendee (attendee-id#content) joins the session and
                        // shares content as if a regular attendee shares a video.
                        //
                        // For example, your attendee ID is "my-id". When you call meetingSession.audioVideo.startContentShare,
                        // the content attendee "my-id#content" will join the session and share your content.
                        return
                    }

                    let shouldPublishImmediately = false

                    if (!this.roster[attendeeId]) {
                        this.roster[attendeeId] = {
                            volume: 0,
                            signalStrength: 0,
                            muted: false,
                            handRaised: false
                        }
                    } else {
                        this.roster[attendeeId] = { ...this.roster[attendeeId] }
                    }
                    if (volume !== null) {
                        // We could use the volume. But that value is only ever used in a boolean fashio. This way we should reduce updates
                        this.roster[attendeeId].volume = Math.round(volume * 100)
                    }
                    if (muted !== null) {
                        this.roster[attendeeId].muted = muted
                    }

                    if (signalStrength !== null) {
                        this.roster[attendeeId].signalStrength = Math.round(signalStrength * 100)
                    }

                    if (
                        externalUserId &&
                        this.externalMeetingId &&
                        attendeeId &&
                        this.roster[attendeeId] &&
                        !this.roster[attendeeId].id
                    ) {
                        this.roster[attendeeId].id = "tmp"
                        const response = await getAttendeeInfo(this.externalMeetingId!, externalUserId)
                        if ((response as BackendServiceError).httpStatus) {
                            if (this.roster[attendeeId]) this.roster[attendeeId].id = undefined
                            throw response
                        }
                        this.roster[attendeeId] = { ...this.roster[attendeeId], ...response }
                        shouldPublishImmediately = true
                        if (response && (response as AttendeeData).id) {
                            contactState.setConnectionStatus(
                                (response as AttendeeData).id || "",
                                (response as AttendeeData).connectionStatus || "UNRELATED"
                            )
                            contactState.setUserType(
                                (response as AttendeeData).id || "",
                                (response as AttendeeData).userType || "none"
                            )
                        }
                    }

                    if (shouldPublishImmediately) {
                        this.publishRosterUpdate.cancel()
                    }
                    this.publishRosterUpdate()
                }
            )
        })
    }

    joinRoom = async (): Promise<void> => {
        window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => {
            this.logError(event.reason)
        })

        this.audioVideo?.start()
    }

    private lastLeaveRoomPromise?: Promise<void> | null

    leaveRoom = async (meetingStatus?: MeetingStatus): Promise<void> => {
        // If there already is a promise, we are leaving and don't need to do it again
        if (this.lastLeaveRoomPromise) return
        window.removeEventListener("unhandledrejection", this.unhandledrejection)
        this.lastLeaveRoomPromise = (async () => {
            this.localAttendeeId = null
            try {
                this.audioVideo?.stop()
            } catch (error: any) {
                this.logError(error)
            }
            try {
                switch (meetingStatus?.meetingStatusCode) {
                    case MeetingStatusCode.Banned:
                        await ban(this.externalMeetingId!, this.externalUserId!, meetingStatus.errorMessage)
                        break
                    case MeetingStatusCode.Kicked:
                        await kick(this.externalMeetingId!, this.externalUserId!, meetingStatus.errorMessage)
                        break
                    default:
                        await leaveRoom(this.externalMeetingId!, this.externalUserId!)
                }
            } catch (error: any) {
                this.logError(error)
            }

            await this.initializeSdkWrapper()
        })()
    }

    /* #region Device */

    chooseAudioInputDevice = async (device: Device) => {
        try {
            if (!this.audioVideo) return
            await this.audioVideo?.startAudioInput(device)
        } catch (error: any) {
            this.logError(error)
        }
    }

    chooseAudioOutputDevice = async (device: string) => {
        try {
            if (!this.audioVideo) return
            await this.audioVideo?.chooseAudioOutput(device)
        } catch (error: any) {
            this.logError(error)
        }
    }

    chooseVideoInputDevice = async (device: VideoInputDevice | null) => {
        try {
            if (!this.audioVideo) return
            if (device == null) await this.audioVideo?.stopVideoInput()
            else await this.audioVideo?.startVideoInput(device)
        } catch (error: any) {
            this.logError(error)
        }
    }

    audioInputsChanged = (freshAudioInputDeviceList?: MediaDeviceInfo[]) => {}
    audioOutputsChanged = (freshAudioOutputDeviceList?: MediaDeviceInfo[]) => {}
    videoInputsChanged = (freshVideoInputDeviceList?: MediaDeviceInfo[]) => {}
    /* #endregion */

    /* #region RosterUpdate */

    subscribeToRosterUpdate = (callback: (roster: RosterType) => void) => {
        this.rosterUpdateCallbacks.push(callback)
    }

    unsubscribeFromRosterUpdate = (callback: (roster: RosterType) => void) => {
        const index = this.rosterUpdateCallbacks.indexOf(callback)
        if (index !== -1) {
            this.rosterUpdateCallbacks.splice(index, 1)
        }
    }

    private publishRosterUpdate = throttle(() => {
        for (let i = 0; i < this.rosterUpdateCallbacks.length; i += 1) {
            const callback = this.rosterUpdateCallbacks[i]
            callback({ ...this.roster })
        }
    }, ChimeSdkWrapper.ROSTER_THROTTLE_MS)

    /* #endregion */

    /* #region Utilities */

    private logError = (error: Error) => {
        // eslint-disable-next-line
        console.error(error)
        // hopefully this works enough
        logger.error({ message: "ChimeSdkWrapper ", error })
    }

    /* #endregion */
}

export const chimeSdk = new ChimeSdkWrapper()

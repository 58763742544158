import { createState, useState, State } from '@hookstate/core';
import { CrsAlertType } from '../ui/CrsAlert';

interface AlertData {
    type: CrsAlertType,
    message: string,
    duration?: number // value in ms 
}

interface AlertState {
    showAlert: boolean,
    alertData: AlertData | null
}

const useWrapState = ((state: State<AlertState>) => {
    return ({
        show: (newState: { message: string, type: CrsAlertType, duration?: number }) => {
            state.set({ ...{ showAlert: true }, ...{ alertData: newState } })
        },
        isAlertActive: (): boolean => {
            return state.get().showAlert
        },
        hideAlert: (): void => {
            state.set({ showAlert: false, alertData: null })
        },
        getAlertData: (): AlertData | null => {
            return state.get().alertData;
        }
    })
})
const state = createState({} as AlertState)
export const useAlertState = () => useWrapState(useState(state))
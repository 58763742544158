import React from "react"
import {
    EntityJobOffer,
    EntityNews,
    EntityOrganization,
    EntityPerson,
    EntityProduct,
    EntityTrademark,
    Exhibitor,
    JobOffer,
    News,
    Person,
    Product,
    Trademark
} from "../../backendServices/Types"
import branding, { Branding } from "../../branding/branding"
import EmptyTile from "../../contentArea/reception/EmptyTile"
import { useLanguageState } from "../../globalStates/LanguageState"
import {
    joboffersPageRoute,
    exhibitorNewsPageRoute,
    productsPageRoute,
    showfloorPageRoute,
    speakersPageRoute,
    trademarksPageRoute
} from "../../navigationArea/RoutePaths"
import { IconJoboffer, IconNews, IconOrganization, IconProduct, IconSpeakers, IconTrademark } from "../Icons"
import TileRow from "../TileRow"
import useWindowDimensions from "../WindowDimensionsHook"
import styled from "styled-components"
import { device } from "../../utils/Device"
import { ConditionalWrapper } from "../ConditionalWrapper"
import { OrganizationComponent } from "./components/OrganizationComponent"
import PersonComponent from "./components/PersonComponent"
import { EntityComponent } from "./components/EntityComponent"
import InView from "react-intersection-observer"
import { NewsComponent } from "./components/NewsComponent"
import { UserOrganizationVisitSource } from "../../backendServices/TrackingServices"
import { getShareTargetType } from "../../contentArea/entitiesActions/actionsUtils"

type ComponentTypeString = EntityOrganization | EntityProduct | EntityTrademark | EntityPerson | EntityNews | EntityJobOffer
type CarouselType = Exhibitor | Product | Trademark | Person | News | JobOffer

// Wrapper which is used only for carousel of type PERSON
const TileWrapperSpeakers = styled.div<{ $count?: number }>`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(${(props) => props.$count}, 95px);
    grid-gap: ${branding.receptionPage.speakersTileGridGap ?? "10px"};
    padding-bottom: 1.25rem;
    width: 100%;
    @media ${device.tablet} {
        grid-template-columns: repeat(${(props) => props.$count}, 280px);
    }

    @media ${device.mobile} {
        grid-template-columns: repeat(${(props) => props.$count}, 95px);
    }
`

/* #region  CAROUSEL CONFIG */
const getCarouselConfig = (
    type: ComponentTypeString,
    strings: Branding,
    entities: CarouselType[],
    isMobile: boolean,
    lobby?: boolean
) => {
    switch (type) {
        case "organization":
            return {
                title: strings.receptionPage.exhibitors,
                linkText: strings.receptionPage.showFloorNavLinkText,
                link: showfloorPageRoute,
                icon: <IconOrganization fill={branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor} />,
                entities: entities as Exhibitor[],
                widthHeight: isMobile ? { width: 205, height: 95 } : { width: 500, height: 280 },
                tileRowHeight: isMobile ? "95px" : "220px",
                tileRowChildWidth: (isMobile ? 205 : 500) + 4
            }
        case "product":
            return {
                title: strings.navigationArea.productsItemTitle,
                linkText: strings.receptionPage.productsNavLinkText,
                link: productsPageRoute,
                icon: (
                    <IconProduct
                        fill={branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor}
                        width={"20px"}
                        height={"20px"}
                    />
                ),
                entities: entities as Product[],
                widthHeight: isMobile ? { width: 95, height: 95 } : { width: 500, height: 280 },
                tileRowHeight: isMobile ? "95px" : "280px",
                tileRowChildWidth: isMobile ? 95 : 500
            }
        case "trademark":
            return {
                title: strings.navigationArea.trademarksItemTitle,
                linkText: strings.receptionPage.trademarksNavLinkText,
                link: trademarksPageRoute,
                icon: <IconTrademark fill={branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor} />,
                entities: entities as Trademark[],
                widthHeight: isMobile ? { width: 95, height: 95 } : { width: 250, height: 250 },
                tileRowHeight: isMobile ? "95px" : "220px",
                tileRowChildWidth: isMobile ? 95 : 250
            }
        case "person":
            return {
                title: strings.receptionPage.speakers,
                linkText: strings.receptionPage.speakersNavLinkText,
                link: speakersPageRoute,
                icon: <IconSpeakers fill={branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor} />,
                entities: entities as Person[],
                widthHeight: isMobile ? { width: 95, height: 95 } : { width: 280, height: 280 },
                tileRowChildWidth: 280
            }
        case "news":
            if (lobby) {
                return {
                    title: strings.navigationArea.newsItemTitle,
                    linkText: strings.receptionPage.newsNavLinkText,
                    link: exhibitorNewsPageRoute,
                    icon: <IconNews fill={branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor} />,
                    entities: entities as News[],
                    widthHeight: isMobile ? { width: 205, height: 95 } : { width: 500, height: 280 },
                    tileRowHeight: isMobile ? "95px" : "220px",
                    tileRowChildWidth: (isMobile ? 205 : 500) + 4
                }
            }
            return {
                title: strings.navigationArea.newsItemTitle,
                linkText: strings.receptionPage.newsNavLinkText,
                link: exhibitorNewsPageRoute,
                icon: <IconNews fill={branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor} />,
                entities: entities as News[],
                widthHeight: isMobile ? { width: 95, height: 95 } : { width: 250, height: 250 },
                tileRowHeight: isMobile ? "95px" : "220px",
                tileRowChildWidth: isMobile ? 95 : 250
            }
        case "joboffer":
            return {
                title: strings.exhibitorsPageContent.jobofferTabTitle,
                linkText: strings.exhibitorsPageContent.jobofferTabTitle,
                link: joboffersPageRoute,
                icon: (
                    <IconJoboffer
                        fill={branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor}
                        width="23px"
                        height="23px"
                    />
                ),
                entities: entities as JobOffer[],
                widthHeight: isMobile ? { width: 95, height: 95 } : { width: 250, height: 250 },
                tileRowHeight: isMobile ? "95px" : "220px",
                tileRowChildWidth: isMobile ? 95 : 250
            }
        default:
            return {
                title: strings.navigationArea.trademarksItemTitle,
                linkText: strings.receptionPage.trademarksNavLinkText,
                link: trademarksPageRoute,
                icon: <IconTrademark fill={branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor} />,
                entities: entities as Trademark[],
                widthHeight: isMobile ? { width: 95, height: 95 } : { width: 250, height: 250 },
                tileRowHeight: isMobile ? "95px" : "220px",
                tileRowChildWidth: isMobile ? 95 : 250
            }
    }
}
/* #endregion */

/* #region  CAROUSEL COMPONENT */
interface EntityComponentParsedProps {
    type: ComponentTypeString
    entity: CarouselType
    lobby?: boolean
    myFair?: boolean
    src?: UserOrganizationVisitSource
}
const EntityComponentParsed = (props: EntityComponentParsedProps) => {
    const { type, entity } = props

    if (type === "organization" && !props.myFair) {
        //bookmarked exhibitors in my fair page have a different layout, defined in EntityComponent
        const exhibitor = entity as Exhibitor
        return (
            <OrganizationComponent
                maxBadgeCount={branding.exhibitorsPageContent.exhibitorTilesMaxBadgeCount}
                backgroundUrl={exhibitor.backgroundImageURL}
                exhibitor={exhibitor}
                src={props.src || "UNKNOWN"}
            />
        )
    } else if (type === "person") {
        return <PersonComponent {...(entity as Person)} type="person" />
    } else if (type === "news" && props.lobby) {
        return <NewsComponent news={entity as News} lobby={props.lobby} />
    } else {
        return (
            <EntityComponent
                type={type}
                entity={entity as Exhibitor | Product | Trademark | News | JobOffer}
                shareTargetType={getShareTargetType(type)}
                lobby={props.lobby}
                showContextMenuOnHover
            />
        )
    }
}
/* #endregion */

/* #region  CAROUSEL CONTENT */
interface EntityCarouselProps {
    type: ComponentTypeString
    entities: CarouselType[]
    title?: string
    linkText?: string
    link?: string
    emptyTileMessage?: string
    onBeforeLinkClick?: () => void
    onRightMouseClick?: () => void
    lobby?: boolean
    myFair?: boolean
    src?: UserOrganizationVisitSource
    collection?: boolean
}
export const EntityCarousel: React.FC<EntityCarouselProps> = (props) => {
    const strings = useLanguageState().getStrings()
    const { isMobile } = useWindowDimensions()
    const carouselConfig = getCarouselConfig(props.type, strings, props.entities, isMobile, props.lobby)

    // Let's take props over defaults
    const title = props.title ?? carouselConfig?.title
    const icon = carouselConfig?.icon
    const linkText = props.linkText ?? carouselConfig?.linkText
    const link = props.link ?? carouselConfig?.link
    const { entities, widthHeight, tileRowHeight, tileRowChildWidth } = carouselConfig

    let content
    if (entities.length === 0) {
        content = (
            <EmptyTile
                header={props.emptyTileMessage ?? title}
                buttonNavLink={link}
                buttonMessage={linkText}
                hideButton={true}
                bgColor={branding.receptionPage.emptyTileBgColor}
            />
        )
    } else {
        content = entities.map((entity, index) => (
            <InView key={index} threshold={0} initialInView>
                {({ inView, ref }) => (
                    <div ref={ref}>
                        {inView ? (
                            <ConditionalWrapper
                                wrapper={(children) =>
                                    props.type === "person" ? <div style={widthHeight} children={children} /> : null
                                }
                            >
                                <EntityComponentParsed
                                    type={props.type}
                                    entity={entity}
                                    lobby={props.lobby}
                                    myFair={props.myFair}
                                />
                            </ConditionalWrapper>
                        ) : (
                            <div style={widthHeight} />
                        )}
                    </div>
                )}
            </InView>
        ))
    }

    return (
        <TileRow
            icon={icon}
            iconVisible={true}
            title={title}
            titleVisible={true}
            navLink={link}
            navLinkText={linkText}
            navLinkTextVisible={true}
            onBeforeLinkClick={props.onBeforeLinkClick}
            onRightMouseClick={props.onRightMouseClick}
            height={tileRowHeight}
            scrollBar={entities && entities.length > 0}
            isMyFairPage
            hideShadows={isMobile}
            childWidth={tileRowChildWidth}
            childCount={entities.length ?? 0}
            collection={props.collection}
        >
            {/* Wrapping carousel items only if it's type of person */}
            <ConditionalWrapper
                wrapper={(children) => (props.type === "person" ? <TileWrapperSpeakers children={children} /> : null)}
            >
                {content}
            </ConditionalWrapper>
        </TileRow>
    )
}
/* #endregion */

import { fetchDataWebService } from "./BackendServicesUtils"
import { EntityType, SuggestGroup } from "./Types"
import { defaultLogger as logger } from "../globalStates/AppState"
import { LocalizedString } from "../branding/branding"

/*********************************************************************************************
 * SUGGESTION REQUEST
 **********************************************************************************************/
export interface SuggestResponse {
    searchString: string
    suggestGroups: SuggestGroup[]
}

export enum GroupConfigType {
    /**
     * @title Category group config type
     * @description Type name for category group config
     * @title_de Category group config type
     * @description_de Type name for category group config
     */
    cat = "cat",
    /**
     * @title Interest group config type
     * @description Type name for interest group config
     * @title_de Interest group config type
     * @description_de Type name for interest group config
     */
    interest = "interest",
    /**
     * @title City group config type
     * @description Type name for city group config
     * @title_de City group config type
     * @description_de Type name for city group config
     */
    city = "city",
    /**
     * @title Country group config type
     * @description Type name for country group config
     * @title_de Country group config type
     * @description_de Type name for country group config
     */
    country = "country",
    /**
     * @title Entity group config type
     * @description Type name for entity group config
     * @title_de Entity group config type
     * @description_de Type name for entity group config
     */
    entity = "entity",
    /**
     * @title Hall group config type
     * @description Type name for hall group config
     * @title_de Hall group config type
     * @description_de Type name for hall group config
     */
    hall = "hall",
    /**
     * @title Postcode group config type
     * @description Type name for postcode group config
     * @title_de Postcode group config type
     * @description_de Type name for postcode group config
     */
    postcode = "postcode",
    /**
     * @title Collections group config type
     * @description Type name for Collections group config
     * @title_de Collections group config type
     * @description_de Type name for Collections group config
     */
    collections = "collections",
    /**
     * @title Employment type group config type
     * @description Type name for employment type group config
     * @title_de Employment type group config type
     * @description_de Type name for employment type group config
     */
    employment_type = "employment_type",
    /**
     * @title Job location group config type
     * @description Type name for job location group config
     * @title_de Job location group config type
     * @description_de Type name for job location group config
     */
    job_location = "job_location",
    /**
     * @title Stand Registration Configuration
     * @description Configuration to fetch stand with exhibitor and hall for location picker in CalendarEntryModal
     * @title_de Stand Registration Konfiguration
     * @description_de Konfiguration um Stand mit entsprechendem Veranstalter und entsprechender Halle in Standortwahl in CalendarEntryModal anzufordern
     */
    stand_registration = "stand_registration",
    /**
     * @title Product type group config type
     * @description Type name for product type group config
     * @title_de Konfigurationstyp der Produkttypgruppe
     * @description_de Name des Typs für die Konfiguration der Produkttypgruppe
     */
    prodtype = "prodtype"
}

export interface GroupConfig {
    /**
     * @title Suggest group id
     * @description Id provided for suggest group
     * @title_de Gruppen-ID vorschlagen
     * @description_de Id für die Vorschlagsgruppe vorgesehen
     */
    id: string
    /**
     * @title Suggest group type
     * @description Type provided for suggest group
     * @title_de Gruppentyp vorschlagen
     * @description_de Typ für die vorgeschlagene Gruppe
     */
    type: GroupConfigType
    /**
     * @title Suggest group entity type
     * @description Entity type provided for suggest group
     * @title_de Gruppenentitätstyp vorschlagen
     * @description_de Entitätstyp für Vorschlagsgruppe vorgesehen
     */
    entityType: EntityType
    /**
     * @title Suggest group msc number
     * @description Maximum number of results provided for suggest group
     * @title_de Gruppe msc-Nummer vorschlagen
     * @description_de Maximale Anzahl von Ergebnissen für die vorgeschlagene Gruppe
     */
    msc: number
    /**
     * @title Category Ids
     * @description List of category aliases for the suggest group
     * @title_de Kategorie Ids
     * @description_de Liste der Kategorie-Aliase für die Vorschlagsgruppe
     */
    catIds: string[]
    /**
     * @title Suggest Group Section Title
     * @description Title for the heading section of the suggestion group
     * @title_de Titel des Gruppenabschnitts vorschlagen
     * @description_de Titel für den Überschriftenbereich der Vorschlagsgruppe
     */
    sectionTitle: LocalizedString
    /**
     * @title Suggest Group Show More Title
     * @description Title for the show more section displayed below suggestion group
     * @title_de Gruppe vorschlagen Mehr Titel anzeigen
     * @description_de Titel für den Abschnitt "Mehr anzeigen", der unter der Vorschlagsgruppe angezeigt wird
     */
    showMoreTitle: LocalizedString
}

export async function loadSuggestions(query: string, groupConfig: GroupConfig[]): Promise<SuggestResponse> {
    try {
        const suggestGroups = groupConfig.map((group) => {
            return { ...(group as any).jsonData }
        })
        // We don't want to send showMoreTitle and sectionTitle properties to the backend because they are not relevant there
        // Show more title is only used on the client side, to show title below suggest groups (SuggestBox.tsx)
        // Section title is only used on the client side, to show title of the suggest group (SuggestBox.tsx)
        suggestGroups.forEach((g: any) => {
            delete g.showMoreTitle
            delete g.sectionTitle
        })

        const data = await fetchDataWebService("/suggest", {
            searchstring: query,
            groupconfig: JSON.stringify(suggestGroups)
        })
        return { searchString: data.query, suggestGroups: data.suggestionGroups }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/suggest",
            params: { query },
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

import { createState, State, useState } from "@hookstate/core"
import { VideoTileState } from "amazon-chime-sdk-js"
import { chimeSdk } from "../ChimeSdkWrapper"
import { DataMessageType } from "../enums/DataMessageType"
import { ShallowVideoTileState } from "./ChimeContext"
import { getShallowVideoTileState } from "./VideoContext"

interface StateValues {
    localAttendeeSharingScreen: boolean
    sharingScreen: boolean
    shareScreenTile: ShallowVideoTileState | null
}

const getStartValues = (): StateValues => {
    return {
        shareScreenTile: null,
        localAttendeeSharingScreen: false,
        sharingScreen: false
    }
}

export interface ContentShareContext {
    onCallStart: () => void
    onCallEnd: () => void
    receiveAttendeeDataMessage: (dataMessageType: DataMessageType, dataMessageJson: any) => void
    stopContentShare: () => void
    modStopContentShare: (attendeeId: string) => void
    startContentShareFromScreenCapture: (sourceId?: string | undefined, frameRate?: number | undefined) => Promise<void>
    isSharingScreen: () => boolean
    isLocalAttendeeSharingScreen: () => boolean
    getShareScreenTile: () => ShallowVideoTileState | null
    playVideoFile: (mediaStream: MediaStream) => Promise<void>
}

const createStateWrapper = (state: State<StateValues>): ContentShareContext => {
    const observer = {
        videoTileDidUpdate: (tileState: VideoTileState): void => {
            if (!tileState.boundAttendeeId || !tileState.tileId) {
                return
            }
            const newTileState = getShallowVideoTileState(tileState)

            if (newTileState.isContent) {
                state.merge({ sharingScreen: true, shareScreenTile: newTileState })
            }
        },
        videoTileWasRemoved: (tileId: number): void => {
            if (state.value.shareScreenTile?.tileId === tileId) {
                state.merge({ sharingScreen: false, shareScreenTile: null })
            }
        }
    }

    const contentShareObserver = {
        contentShareDidStop: () => {
            state.merge({ localAttendeeSharingScreen: false, sharingScreen: false })
        },
    }

    const stopContentShare = () => {
        chimeSdk.audioVideo?.stopContentShare()
        state.merge({ localAttendeeSharingScreen: false, sharingScreen: false })
    }

    return {
        onCallStart: () => {
            chimeSdk.audioVideo?.addContentShareObserver(contentShareObserver)
            chimeSdk.audioVideo?.addObserver(observer)
        },
        onCallEnd: () => {
            chimeSdk.audioVideo?.removeContentShareObserver(contentShareObserver)
            chimeSdk.audioVideo?.removeObserver(observer)
            stopContentShare()
        },
        receiveAttendeeDataMessage: (dataMessageType: DataMessageType, _dataMessageJson: any) => {
            if (dataMessageType === DataMessageType.STOP_SCREENSHARE) stopContentShare()
        },
        stopContentShare: () => stopContentShare(),
        modStopContentShare(attendeeId: string) {
            chimeSdk.audioVideo?.realtimeSendDataMessage(attendeeId, { type: DataMessageType.STOP_SCREENSHARE })
        },
        startContentShareFromScreenCapture: async (sourceId?: string | undefined, frameRate?: number | undefined) => {
            await chimeSdk.audioVideo?.startContentShareFromScreenCapture(sourceId, frameRate)
            state.merge({ localAttendeeSharingScreen: true, sharingScreen: true })
        },
        isSharingScreen: () => {
            return state.value.sharingScreen
        },
        isLocalAttendeeSharingScreen: () => {
            return state.value.localAttendeeSharingScreen
        },
        getShareScreenTile: () => {
            return state.value.shareScreenTile
        },
        playVideoFile: async (mediaStream: MediaStream) => {
            await chimeSdk.audioVideo?.startContentShare(mediaStream)
            state.merge({ localAttendeeSharingScreen: true })
        }
    }
}

const state = createState<StateValues>(getStartValues())
export const useContentShareContext = (): ContentShareContext => createStateWrapper(useState(state))

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://2fdl233vczd43cqo2lboxqvg2u.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_NzTzHWWZ7",
    "aws_user_pools_web_client_id": "7bjiv5gjmcs5t4m90qnns9p27f",
    "oauth": {}
};


export default awsmobile;

import "@dvsl/zoomcharts"
import { uniq } from "lodash"
import { ContactListResponse } from "../../backendServices/SeriesOfTopicsUserServices"
import { NetworkingUser } from "../../backendServices/Types"
import branding from "../../branding/branding"

// ENUMS
export enum autosuggestFilterOptions {
    USER_NAME = "USER_NAME",
    JOB_TITLE = "JOB_TITLE",
    COMPANY_NAME = "COMPANY_NAME"
}

// INTERFACES
interface NodeLink {
    id: string
    from: string
    to: string
}

// Removing all the links that are pointing to the nodes that do not exist
export function filterLinks(
    netChartDataCopy: ZoomCharts.Configuration.NetChartDataObject,
    filteredNodes: Array<ZoomCharts.Configuration.NetChartDataObjectNode>,
    netChartData: ZoomCharts.Configuration.NetChartDataObject
) {
    netChartDataCopy.links = []

    filteredNodes.forEach((element) => {
        netChartDataCopy.links = netChartDataCopy.links.concat(
            netChartData!.links.filter((x) => {
                if (x.to === element.id && filteredNodes.includes(netChartData!.nodes.find((y) => y.id === x.from)!!)) return true
                else if (x.from === element.id && filteredNodes.includes(netChartData!.nodes.find((y) => y.id === x.to)!!))
                    return true
                else return false
            })
        )
    })
}

// Removing all the nodes that are not connected
export function removeSeparateNodes(
    newNetChartData: ZoomCharts.Configuration.NetChartDataObject,
    oldNetChartData: ZoomCharts.Configuration.NetChartDataObject
) {
    let newLinks = newNetChartData.links
    let newNodes = []
    let oldNodes = oldNetChartData.nodes
    newNodes = oldNodes.filter((oldNode) => {
        return newLinks.find((newLink) => newLink.to === oldNode.id || newLink.from === oldNode.id)
    })
    newNetChartData.nodes = newNodes
}

// NetChart node label set
export function setNodeLabel(node: ZoomCharts.Configuration.ItemsChartNode) {
    node.label = [(node.data as NetworkingUser).firstName, (node.data as NetworkingUser).lastName].filter(Boolean).join(" ")
}

// NetChart node image set
export function setNodeImage(node: ZoomCharts.Configuration.ItemsChartNode) {
    node.image = !(node.data as NetworkingUser).showLoader ? (node.data as NetworkingUser).logoUrl : "loading-image.png"
    node.lineWidth = 2 // default line width

    if (!(node.data as NetworkingUser).logoUrl) {
        let alt = [(node.data as NetworkingUser).firstName, (node.data as NetworkingUser).lastName]
            .filter(Boolean)
            .join(" ")
            .split(" ")
            .map((value, index) => (index <= 1 && value && value.length > 0 ? value.substr(0, 1).toUpperCase() : null))
            .join("")

        node.fillColor = "lightgrey"
        node.lineWidth = 1 // if no image, set the line width to be narrow, to be consistent with the avatars which contain an image.

        node.items.push({
            align: "center",
            text: alt,
            backgroundStyle: { fillColor: "transparent" },
            textStyle: { fillColor: "#fff", font: "18px Arial" }
        })
    }
    if ((node.data as NetworkingUser).type && (node.data as NetworkingUser).type === "speaker") {
        node.items.push({
            image: "/branding/icons/networking_speaker_indicator.svg",
            imageSlicing: [0, 0, 20, 20],
            px: -0.7,
            py: -0.7,
            x: 0,
            y: 0,
            backgroundStyle: { fillColor: "transparent" }
        })
    } else if ((node.data as NetworkingUser).type && (node.data as NetworkingUser).type === "staff") {
        node.items.push({
            image: "/branding/icons/networking_staff_indicator.svg",
            imageSlicing: [0, 0, 20, 20],
            px: -0.7,
            py: -0.7,
            x: 0,
            y: 0,
            backgroundStyle: { fillColor: "transparent" }
        })
    }
}

// NetChart node radius set
export function setNodeRadius(node: ZoomCharts.Configuration.ItemsChartNode) {
    node.radius = node.radius! * 1.5
}

// NetChart node border set
export function setNodeBorder(node: ZoomCharts.Configuration.ItemsChartNode) {
    let color
    if ((node?.data as NetworkingUser).type === "attendee") {
        color = "transparent"
    } else if ((node?.data as NetworkingUser).type === "staff") {
        color = "#0900F8"
    } else if ((node?.data as NetworkingUser).type === "speaker") {
        color = branding.dangerButtonColor
    }

    node.lineColor = color
}

// Getting first names, last names, positions and companies from loaded nodes which will be used for the filter by user attributes autocomplete functionality
export function getUserAttributes(nodes: ZoomCharts.Configuration.NetChartDataObjectNode[]) {
    if (!nodes) return
    let autosuggestCategories = branding.networkingArea.autosuggestFilterOptions
    if (!autosuggestCategories.length) {
        autosuggestCategories = [
            autosuggestFilterOptions.USER_NAME,
            autosuggestFilterOptions.JOB_TITLE,
            autosuggestFilterOptions.COMPANY_NAME
        ]
    }
    let userAttributesTemp: Array<string> = []
    nodes.forEach((node) => {
        if ((node as NetworkingUser).firstName && autosuggestCategories.includes(autosuggestFilterOptions.USER_NAME))
            userAttributesTemp.push((node as NetworkingUser).firstName! + " " + (node as NetworkingUser).lastName!)
        if ((node as NetworkingUser).position && autosuggestCategories.includes(autosuggestFilterOptions.JOB_TITLE))
            userAttributesTemp.push((node as NetworkingUser).position!)
        if ((node as NetworkingUser).company && autosuggestCategories.includes(autosuggestFilterOptions.COMPANY_NAME))
            userAttributesTemp.push((node as NetworkingUser).company!)
    })

    userAttributesTemp.sort()

    return uniq(userAttributesTemp)
}

// Creating netChartDataObject from the data recevied which is ContactListResponse interface
export const createFindNewPeopleChartData = (
    data: ContactListResponse,
    loggedInUserId: string,
    userState: any
): ZoomCharts.Configuration.NetChartDataObject => {
    if (!data.contacts) return { nodes: [], links: [] }

    let firstCircleNumberOfNodes = 10
    let userToConnectTo = loggedInUserId
    let usersToConnectTo: Array<string> = []
    let counterHelper = 0 // Helper that we use to measure how many connections there should be per node
    // Filter out users i am connected to
    let nodes: Array<NetworkingUser> = data.contacts.map((x) => x.sotUser)
    let connections: Array<NodeLink> = []

    nodes.forEach((item, index) => {
        usersToConnectTo.push(item.id)
        if (firstCircleNumberOfNodes > 0) {
            userToConnectTo = loggedInUserId
            firstCircleNumberOfNodes--
        } else {
            // Here we are doubling number of connections per node for the second row
            if (index >= 21 && index <= 40 && index % 2 !== 0) {
                counterHelper--
            }
            if (counterHelper === 1) {
                usersToConnectTo.splice(0, 1)
            } else {
                counterHelper++
            }
            userToConnectTo = usersToConnectTo[0]
        }
        if (userToConnectTo) {
            connections.push({
                id: userToConnectTo + item.id,
                from: item.id,
                to: userToConnectTo
            })
        }
    })
    // Adding logged in user data
    nodes.push({
        id: userState.user()!.profileId,
        firstName: userState.user()!.firstName!,
        lastName: userState.user()!.lastName,
        logoUrl: userState.user()!.logoUrl,
        entityType: "networking_user",
        orderkey: userState.user().firstName! + " " + userState.user().lastName!,
        loaded: true
    })
    return { nodes: nodes, links: connections }
}

export const setFindNewPeopleViewNodesLoadNumber = (num: number): number => {
    if (num === 30) return 40
    else if (num === 40) return 60
    else return num
}

import { useState, useEffect } from "react"
import * as React from "react"
import styled from "styled-components"
import { useLanguageState } from "../globalStates/LanguageState"
import { SlidoConfigBranding } from "../branding/ThirdPartyResources"
import branding from "../branding/branding"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import ChatPage, { ChatConversationParam, calcConversationId, DisplayStyle } from "../communicationArea/ChatPage"
import { ConversationType } from "../API"
import CrsMultiSwitch from "../ui/CrsMultiSwitch"
import { ContactEntry, ContactEntryType } from "../communicationArea/ContactEntry"
import { loadUsersData } from "../backendServices/SeriesOfTopicsUserServices"
import { ContentScrollContainer } from "../ui/ScrollContainer"
import { NextPageLoader } from "../communicationArea/CommunicationArea"
import CenteredLoader from "../ui/CenteredLoader"
import BackendError from "../ui/BackendError"
import useWindowDimensions from "../ui/WindowDimensionsHook"
import NetworkingToggle from "../communicationArea/components/NetworkingToggle"
import { TabActivateNetworking } from "../communicationArea/NetworkingTab"
import { listChannelParticipants } from "../backendServices/GraphQLServices"
import InView from "react-intersection-observer"

const TabRoot = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    flex-grow: 1;
    border-left: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    background: white;
`

const ChatTabNavigationArea = styled.div<{ size: number }>`
    display: flex;
    align-items: stretch;
    margin-top: 20px;
    min-width: ${(props) => (props.size < 1230 ? "100%" : "80%")};
    /* width: calc(100% - 60px); */
    height: 30px;
    min-height: 30px;
    padding: 1px;
    font-size: 11px;
    font-family: ${branding.font1};

    & > * {
        flex-grow: 1;
        flex-basis: 100px;
    }
`

const TabContentArea = styled.div`
    margin-top: 15px;
    overflow: auto;
    width: 100%;
    height: calc(100% - 40px); /* Depends on header and other stuff from above! */
`

export enum ChatRoomType {
    CHAT = "CHAT",
    POLL = "POLL",
    QANDA = "QANDA",
    PARTICIPANTS = "PARTICIPANTS"
}

const ChatPageWrapper = styled.div`
    background: white;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
`

interface ChatTabProps {
    visible: boolean
    roomId: string
    type: string
}

function ChatTab(props: ChatTabProps) {
    return (
        <ChatPageWrapper style={{ display: props.visible ? undefined : "none" }}>
            <ChatPage
                param={ChatConversationParam.conversationByConversationId(
                    ConversationType.PUBLIC,
                    calcConversationId(props.roomId)
                )}
                displayStyle={DisplayStyle.PINBOARD}
                eventDate={props.type === "eventDate"}
                channel={props.type === "channel"}
            />
        </ChatPageWrapper>
    )
}

function SlidoTab(props: slidoProps) {
    const userState = useLoggedInState()
    const currentUserName = [userState.user()?.firstName, userState.user()?.lastName].filter(Boolean).join(" ")
    const eventCode =
        branding.configuration.topicName === branding.thirdPartyResources.find((elem) => elem.id === "slido")?.config.topic
            ? branding.thirdPartyResources.find((elem) => elem.id === "slido")?.config.eventCode
            : ""

    const src =
        props.type === ChatRoomType.POLL
            ? "https://app.sli.do/event/" + eventCode + "/live/polls?section=" + props.uuid + "&user_name=" + currentUserName
            : "https://app.sli.do/event/" + eventCode + "/live/questions?section=" + props.uuid + "&user_name=" + currentUserName

    return (
        <div style={{ display: props.visible ? undefined : "none", height: "100%" }}>
            <iframe
                id="slidoIframe"
                title="Slido Poll and Questions"
                src={src}
                height="100%"
                width="100%"
                style={{ borderWidth: "0px" }}
            ></iframe>
        </div>
    )
}

const ParticipantsTabRoot = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    height: 100%;
    overflow: hidden;
`
const EmptyParticipantsRoot = styled.div`
    display: flex;
    justify-content: center;
    padding: 50px;
    font-size: 20px;
`

interface ParticipantsTabProps {
    channelId: string
}
const ParticipantsTab = (props: ParticipantsTabProps) => {
    const userState = useLoggedInState()
    const loggedInUserId = useLoggedInState().user()?.profileId
    const strings = useLanguageState().getStrings()
    const [users, setUsers] = useState<any>([])
    const [nextPageToken, setNextPageToken] = useState<string>()
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const [networkingEnabled, setNetworkingEnabled] = useState<boolean>(userState.isMatchActive() || false)
    const [timeoutMs, setTimeoutMs] = useState<number>(userState.isMatchActive() ? 0 : 1000)

    const fetchData = () => {
        ;(async () => {
            await listChannelParticipants(props.channelId, nextPageToken)
                .then((resp) => {
                    if (resp) {
                        const participantsTemp = resp.items
                        setNextPageToken(resp?.nextToken)

                        // loading sotUsers
                        const participantsFiltered = participantsTemp.filter((x) => x.id !== loggedInUserId)
                        if (participantsFiltered && participantsFiltered.length > 0) {
                            let userParameters: any = {
                                filterlist: participantsFiltered.map((item: any) => `id_sotu_${item.id}`),
                                order: "lexic"
                            }
                            loadUsersData(userParameters)
                                .then((resp) => {
                                    setIsLoading(false)
                                    setUsers(users && users.length > 0 ? users.concat(resp.users) : resp.users)
                                })
                                .catch((err) => {
                                    setIsLoading(false)
                                    setIsError(true)
                                })
                        } else setIsLoading(false)
                    } else setIsLoading(false)
                })
                .catch((err) => {
                    setIsLoading(false)
                    setIsError(true)
                })
        })()
    }

    const loadNextPage = async () => {
        setIsLoading(true)
        fetchData()
    }

    function enableNetworking() {
        setNetworkingEnabled(true)
        setTimeoutMs(1000)
    }

    useEffect(() => {
        setNetworkingEnabled(userState.isMatchActive())
        // eslint-disable-next-line
    }, [userState.isMatchActive()])

    useEffect(() => {
        if (userState.isMatchActive()) {
            setIsLoading(true)

            setTimeout(() => {
                fetchData()
            }, timeoutMs)

            setTimeoutMs(0)
        } else {
            setUsers([])
        }
    }, [props.channelId, userState.isMatchActive()]) // eslint-disable-line

    let content = null

    if (!userState.isMatchActive())
        content = (
            <div>
                <TabActivateNetworking>{strings.communicationArea.activateNetworkingText}</TabActivateNetworking>
                <div style={{ marginLeft: "2%", marginRight: "2%" }}>
                    <NetworkingToggle
                        customEnabled={networkingEnabled}
                        customFunction={() => enableNetworking()}
                        alwaysShowToggle={true}
                    />
                </div>
                {networkingEnabled && <CenteredLoader />}
            </div>
        )
    else if (isLoading && users?.length === 0) content = <CenteredLoader />
    else if (isError) content = <BackendError />
    else if (users && users.length > 0)
        content = (
            <ContentScrollContainer>
                {users.map((sotUser: any) => {
                    return <ContactEntry contact={sotUser} key={sotUser.id} entryType={ContactEntryType.PARTICIPANTS} />
                })}
                {nextPageToken && (
                    <InView
                        threshold={0.1}
                        onChange={(isVisible) => {
                            if (isVisible) loadNextPage()
                        }}
                    >
                        <NextPageLoader />
                    </InView>
                )}
            </ContentScrollContainer>
        )
    else content = <EmptyParticipantsRoot>{strings.eventDateDetailPageContent.noParticipantsMessage}</EmptyParticipantsRoot>

    return <ParticipantsTabRoot>{content}</ParticipantsTabRoot>
}

interface slidoProps {
    uuid?: string
    visible: boolean
    type: ChatRoomType
}

function getUUID(slidoName?: string) {
    if (slidoName === undefined) return ""
    let slidoRoomsConfig: SlidoConfigBranding | undefined = branding.thirdPartyResources.find(
        (elem) => elem.id === "slido"
    )?.config
    return slidoRoomsConfig?.slidoRooms.find((elem) => elem.id === slidoName)?.uuid ?? ""
}

function ChatTabWrapper(props: { eventKey: ChatRoomType; roomId: string; uuid?: string; channelId: string; type: string }) {
    return (
        <>
            <ChatTab roomId={props.roomId} visible={props.eventKey === ChatRoomType.CHAT} type={props.type} />
            {props.eventKey === ChatRoomType.POLL && (
                <SlidoTab uuid={props.uuid} visible={props.eventKey === ChatRoomType.POLL} type={ChatRoomType.POLL} />
            )}
            {props.eventKey === ChatRoomType.QANDA && (
                <SlidoTab uuid={props.uuid} visible={props.eventKey === ChatRoomType.QANDA} type={ChatRoomType.QANDA} />
            )}
            {props.eventKey === ChatRoomType.PARTICIPANTS && <ParticipantsTab channelId={props.channelId} />}
        </>
    )
}

interface ChatRoomAreaProps {
    chatRoom: string
    slidoName?: string
    hideQnA?: boolean
    channelId?: string
    type: string
}

// const LiveChatTitleRoot = styled.div`
//     font-family: ${branding.font1};
//     position: relative;
//     display: flex;
//     display: -webkit-box;
//     border: ${branding.crsMultiSwitcher.crsMultiSwitcherBorder};
//     border-radius: 15px;
//     padding: 1px;
//     /* font-size: 1.1rem; */
//     flex-basis: 0;
//     user-select: none;
//     height: 30px;
//     color: ${branding.crsMultiSwitcher.crsMultiSwitcherNotActiveColor};

// `
// const LiveChatTitle = styled.div`
//   position: absolute;
//   font-family:${branding.font1};
//   text-align: center;
//   font-style: normal;
//   font-weight: 400;
//   border-radius: 20px;
//   font-size: ${branding.crsMultiSwitcher.crsMultiSwitcherMultiSwitchItemFontSize ?? "12px"};
//   background-color: ${branding.crsMultiSwitcher.crsMultiSwitcherActiveBgColor};
//   color: ${ branding.crsMultiSwitcher.crsMultiSwitcherActiveColor};
//   padding: .3rem 1rem;
//   cursor: pointer;
//   width: 99.7%;
//   height: calc(100% - 1px - 1px);
//   text-align: center;
// `;

const ChatRoomAreaTab: React.FunctionComponent<ChatRoomAreaProps> = (props) => {
    let availableViews = branding.eventDateDetailPageContent.multiSwitchItems
    let initialViewType = branding.eventDateDetailPageContent.initialChatRoomAreaTab
    const [eventKey, setEventKey] = useState<ChatRoomType>(getEventKey())

    function getEventKey() {
        let entityKey: ChatRoomType
        if (availableViews.includes(initialViewType) && availableViews.length) {
            entityKey = initialViewType
        } else
            entityKey =
                branding.eventDateDetailPageContent.chatVisible && availableViews.includes(ChatRoomType.CHAT)
                    ? ChatRoomType.CHAT
                    : branding.eventDateDetailPageContent.participantsVisible &&
                      availableViews.includes(ChatRoomType.PARTICIPANTS)
                    ? ChatRoomType.PARTICIPANTS
                    : branding.eventDateDetailPageContent.pollsVisible && availableViews.includes(ChatRoomType.POLL)
                    ? ChatRoomType.POLL
                    : branding.eventDateDetailPageContent.qandaVisible && availableViews.includes(ChatRoomType.QANDA)
                    ? ChatRoomType.QANDA
                    : ChatRoomType.CHAT
        return entityKey
    }

    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const windowSize = useWindowDimensions()
    // const multiSwitchData = [
    //   { value: ChatRoomType.CHAT, label: strings.communicationArea.livechat },
    //   { value: ChatRoomType.POLL, label: strings.communicationArea.poll},
    //   { value: ChatRoomType.PARTICIPANTS, label: strings.eventDateDetailPageContent.channelParticipantsTabTitle}
    // ]

    let switcherItems: any = []
    const itemsOrder = branding.eventDateDetailPageContent.multiSwitchItems
    itemsOrder.forEach((item: any) => {
        let newItem
        switch (item) {
            case "CHAT":
                if (branding.eventDateDetailPageContent.chatVisible) {
                    newItem = { value: ChatRoomType.CHAT, label: strings.communicationArea.livechat }
                }
                break
            case "POLL":
                if (branding.eventDateDetailPageContent.pollsVisible) {
                    newItem = { value: ChatRoomType.POLL, label: strings.communicationArea.poll }
                }
                break
            case "QANDA":
                if (branding.eventDateDetailPageContent.qandaVisible) {
                    newItem = { value: ChatRoomType.QANDA, label: strings.communicationArea.qanda }
                }
                break
            case "PARTICIPANTS":
                if (branding.eventDateDetailPageContent.participantsVisible) {
                    newItem = {
                        value: ChatRoomType.PARTICIPANTS,
                        label: strings.eventDateDetailPageContent.channelParticipantsTabTitle
                    }
                }
                break
        }
        if (newItem) switcherItems.push(newItem)
    })

    return (
        <TabRoot className="chat-tabroot">
            <ChatTabNavigationArea size={windowSize.width}>
                {(branding.eventDateDetailPageContent.chatVisible ||
                    branding.eventDateDetailPageContent.participantsVisible ||
                    !branding.eventDateDetailPageContent.pollsVisible) && (
                    <CrsMultiSwitch
                        items={switcherItems}
                        activeItem={eventKey}
                        onItemClick={setEventKey}
                        style={{
                            mainWidth: "auto",
                            border: branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9",
                            msRootItem: {
                                fontSize: windowSize.width > 1230 ? "12px" : windowSize.width <= 1025 ? "11.5px" : "10.5px"
                            },
                            msItem: {
                                position: "relative",
                                zIndex: 1,
                                fontSize: windowSize.width > 1230 ? "12px" : windowSize.width <= 1025 ? "11.5px" : "10.5px"
                            },
                            msActiveItem: {
                                backgroundColor: branding.crsMultiSwitcher.crsMultiSwitcherActiveBgColor,
                                color: branding.crsMultiSwitcher.crsMultiSwitcherActiveColor,
                                position: "absolute",
                                zIndex: 0
                            }
                        }}
                    />
                )}
            </ChatTabNavigationArea>
            <TabContentArea className="rs-nav-content">
                <ChatTabWrapper
                    eventKey={eventKey!}
                    roomId={props.chatRoom}
                    uuid={getUUID(props.slidoName)}
                    channelId={props.channelId!}
                    type={props.type}
                />
            </TabContentArea>
        </TabRoot>
    )
}

export default ChatRoomAreaTab

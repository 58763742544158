import styled from "styled-components"
import classNames from "classnames"
import TreeMenu, { TreeMenuItem } from "react-simple-tree-menu"
import "../../../../node_modules/react-simple-tree-menu/dist/main.css"
import branding from "../../../branding/branding"
import { useSuggestContext } from "../../../navigationArea/useSuggestContext"
import { useHistory } from "react-router-dom"
import { globalSearchResultPageRoute } from "../../../navigationArea/RoutePaths"
import { device } from "../../../utils/Device"
import { ReactNode } from "react"
import Highlighter from "react-highlight-words"

const SimpleTreeRoot = styled.div<{ horizontalPadding?: boolean }>`
    padding-bottom: 20px;
    padding-left: ${(props) => (props.horizontalPadding ? "7px" : "0")};
    padding-right: ${(props) => (props.horizontalPadding ? "7px" : "0")};
    & .rstm-tree-item-group {
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-bottom: unset;
        margin: 0;

        & li {
            border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        }
        & li.rstm-tree-item--active,
        li.rstm-tree-item--focused {
            background-color: white;
            box-shadow: unset;
            font-weight: 700;
            color: ${branding.categoriesPageContent.selectedCategoryTextColor};
        }
    }

    & .rstm-tree-item-level0 {
        color: ${branding.categoriesPageContent.categoriesListInactiveColor};
        font-family: ${branding.categoriesPageContent.categoriesListFont};
    }

    @media ${device.mobile} {
        padding-right: 30px;

        & .rstm-tree-item-level0 {
            color: ${branding.categoriesPageContent.categoriesListInactiveColor};
            font-family: ${branding.categoriesPageContent.categoriesListFont};
            font-size: 14px !important;
        }

        & .rstm-tree-item-level1 {
            color: ${branding.categoriesPageContent.categoriesListInactiveColor};
            font-size: 14px !important;
        }
    }
`
export interface TreeData {
    key: string
    label: string
    nodes?: Array<TreeData>
    code?: string
}

interface SimpleTreeDataProps {
    treeData: Array<TreeData>
    horizontalPadding?: boolean
    showSearchResults?: boolean
    searchParam?: string
}

const SimpleTreeComponent = (props: SimpleTreeDataProps) => {
    const suggestState = useSuggestContext()
    const history = useHistory()
    const searchParam: string = props.searchParam || ""

    const addCategoryToSearchFilter = (key: string, label: string, props: any) => {
        const formatedKey = key.replace(props.parent + "/", "")
        suggestState.addExternalSuggestion({
            icon: "",
            id: "",
            subtitle: "",
            title: label,
            value: "cat_" + formatedKey,
            active: true
        })
        history.push(globalSearchResultPageRoute)
    }

    return (
        <SimpleTreeRoot horizontalPadding={props.horizontalPadding} onClick={() => {}}>
            {props.treeData.length && (
                <>
                    {/* component call of search results is separated to be able to always show opened nodes for search results, so results in all the levels of the tree are shown */}
                    {props.showSearchResults ? (
                        <TreeMenu
                            data={props.treeData}
                            hasSearch={false}
                            onClickItem={({ key, label, ...props }) => addCategoryToSearchFilter(key, label, props)}
                            openNodes={props.treeData.map((item: TreeData) => {
                                return item.key
                            })}
                        >
                            {({ items }) => (
                                <>
                                    <ul className="rstm-tree-item-group">
                                        {items.map(({ key, ...props }) => (
                                            <ItemComponent key={key} searchParam={searchParam} {...props}></ItemComponent>
                                        ))}
                                    </ul>
                                </>
                            )}
                        </TreeMenu>
                    ) : (
                        <TreeMenu
                            data={props.treeData}
                            hasSearch={false}
                            onClickItem={({ key, label, ...props }) => addCategoryToSearchFilter(key, label, props)}
                            debounceTime={125}
                        />
                    )}
                </>
            )}
        </SimpleTreeRoot>
    )
}

//code below is adjusted from react-simple-tree-menu package itself: https://github.com/iannbing/react-simple-tree-menu/blob/master/src/TreeMenu/renderProps.tsx

const DEFAULT_PADDING = 0.75
const ICON_SIZE = 2
const LEVEL_SPACE = 1.75

const ToggleIcon = ({ on, openedIcon, closedIcon }: { on: boolean; openedIcon: ReactNode; closedIcon: ReactNode }) => (
    <div role="img" aria-label="Toggle" className="rstm-toggle-icon-symbol">
        {on ? openedIcon : closedIcon}
    </div>
)

export const ItemComponent: React.FunctionComponent<TreeMenuItem> = ({
    hasNodes = false,
    isOpen = false,
    level = 0,
    onClick,
    toggleNode,
    active,
    focused,
    openedIcon = "-",
    closedIcon = "+",
    label = "unknown",
    style = {},
    searchParam = ""
}) => (
    <li
        className={classNames(
            "rstm-tree-item",
            `rstm-tree-item-level${level}`,
            { "rstm-tree-item--active": active },
            { "rstm-tree-item--focused": focused }
        )}
        style={{
            paddingLeft: `${DEFAULT_PADDING + ICON_SIZE * (hasNodes ? 0 : 1) + level * LEVEL_SPACE}rem`,
            ...style
        }}
        role="button"
        aria-pressed={active}
        onClick={onClick}
    >
        {hasNodes && (
            <div
                className="rstm-toggle-icon"
                onClick={(e) => {
                    hasNodes && toggleNode && toggleNode()
                    e.stopPropagation()
                }}
            >
                <ToggleIcon on={isOpen} openedIcon={openedIcon} closedIcon={closedIcon} />
            </div>
        )}
        <Highlighter
            caseSensitive={false}
            searchWords={[searchParam]}
            highlightStyle={{
                display: "inline",
                backgroundColor: branding.categoriesPageContent.categorySearchHighlightBackgroundColor,
                color: branding.categoriesPageContent.categorySearchHighlightFontColor,
                fontWeight: "bold",
                padding: 0
            }}
            textToHighlight={label}
        />
    </li>
)

export default SimpleTreeComponent

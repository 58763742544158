import moment from "moment"
import { useEffect, useState } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import { loadEventDateDetails } from "../../backendServices/EventdateServices"
import branding from "../../branding/branding"
import { AppStateContextMethods, useAppState } from "../../globalStates/AppState"
import { meetingPageRoute } from "../../navigationArea/RoutePaths"

import { useChimeContext } from "../context/ChimeContext"
import { MeetingStatusCode } from "../enums/MeetingStatusCode"
import { RemainingTimeRoot } from "./RemainingTime"

// const AlertPopup = styled.div<{ $isMeetingPage: boolean }>`
//     position: absolute;
//     top: -645px;
//     box-shadow: ${(props) => (props.$isMeetingPage ? "none" : "0px 5px 15px 1px rgba(0, 0, 0, 0.4)")};
//     text-indent: 0;
//     width: ${(props) => (props.$isMeetingPage ? "initial" : "inherit")};
//     height: ${(props) => (props.$isMeetingPage ? "initial" : "56px")};
//     padding: 10px;
//     right: 10px;
//     color: #fff;
//     font-size: 12px;
// `

export const RoundTableEndCountdownTimer = () => {
    const alertText = branding.configuration.roundTableAvailableAfterEventTimerLabel
    const roundTableAvailableAfterMinutes = branding.configuration.roundTableOpenAfterMinutes
    const [eventEndTime, setEventEndTime] = useState("")
    const chime = useChimeContext()
    const [isEventOver, setIsEventOver] = useState<boolean>(false)
    const now = moment(new Date()).format("yyyy-MM-DD HH:mm:ss")
    const meetingId = chime.getExternalMeetingId()?.split("rt_")[1]
    const [timeLabel, setTimeLabel] = useState("-:-")
    const roundTableAvailable = moment(eventEndTime).add(roundTableAvailableAfterMinutes, "minutes").format("yyyy-MM-DD HH:mm:ss")
    let countDownTime = moment(roundTableAvailable).diff(now, "seconds")
    const isOnMeetingPage = useRouteMatch(meetingPageRoute) !== null
    const history = useHistory()
    const [meetingName, setMeetingName] = useState("")
    const [dataLoaded, setDataLoaded] = useState(false)
    const appState = useAppState()

    useEffect(() => {
        /**
         * On page load, set the default state of roundtable status
         */
        appState.setRoundTableStatus({ roundTableId: meetingId, isOver: false })
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (chime.getKind() === "roundtable") {
            setDataLoaded(false)
            const params = { id: meetingId }
            loadEventDateDetails(params)
                .then((res) => {
                    setMeetingName(res.content.name)
                    setEventEndTime(res.content.enddatetime)
                    setIsEventOver(
                        moment(moment(new Date()).format("yyyy-MM-DD HH:mm:ss")).diff(moment(eventEndTime), "seconds") > 0
                    )
                })
                .then(() => {
                    setDataLoaded(true)
                })
        }
        // eslint-disable-next-line
    }, [eventEndTime])

    useEffect(() => {
        // if event is over run the countdown
        if (isEventOver) {
            appState.setRoundTableStatus({ roundTableId: meetingId, isOver: true })
            if (moment().diff(eventEndTime, "minutes") < roundTableAvailableAfterMinutes) {
                let countDownTimerId = setInterval(() => {
                    countDownTime--
                    setTimeLabel(moment.utc(countDownTime * 1000).format("mm:ss"))
                    appState.setRoundTableStatus({ ...appState.roundTableStatus, countDown: countDownTime })
                }, 1000)

                return () => clearInterval(countDownTimerId)
            }
        } else {
            // if event is not over, check every second if it is
            let checkIntervalId = setInterval(() => {
                if (moment(moment(new Date()).format("yyyy-MM-DD HH:mm:ss")).diff(moment(eventEndTime), "seconds") > 0) {
                    setIsEventOver(true)
                }
            }, 1000)

            return () => clearInterval(checkIntervalId)
        }
        // eslint-disable-next-line
    }, [eventEndTime, isEventOver])

    useEffect(() => {
        if (moment(moment(new Date()).format("yyyy-MM-DD HH:mm:ss")).diff(roundTableAvailable, "seconds") >= 0) {
            chime.setKeepCallActive(false)
            chime.setMeetingStatus({ meetingStatusCode: MeetingStatusCode.Ended })
            if (!isOnMeetingPage) {
                chime.setShowConferenceOverlay(false)
            } else {
                history.push("/eventdate/" + meetingName + "--" + meetingId)
            }
        }
        // eslint-disable-next-line
    }, [countDownTime, dataLoaded])

    useEffect(() => {
        updateTimerStringState(appState, alertText, timeLabel, countDownTime)
        //eslint-disable-next-line
    }, [timeLabel])

    return chime.getKind() === "roundtable" && isEventOver ? (
        <RemainingTimeRoot $isMeetingPage={isOnMeetingPage}>
            {/* <AlertPopup $isMeetingPage={isOnMeetingPage}>
                {alertText} {timeLabel}
            </AlertPopup> */}
        </RemainingTimeRoot>
    ) : null
}

function updateTimerStringState(appState: AppStateContextMethods, alertText: string, timeLabel: string, timeInSeconds: number) {
    appState.setRoundTableCountdownString(`${alertText} ${timeLabel}`)
}

import { createState,  State, useState } from '@hookstate/core';
import { chimeSdk } from '../ChimeSdkWrapper';
import { DataMessageType } from '../enums/DataMessageType';
import RosterAttendeeType, { rosterAttendeeTypeEquals } from '../types/RosterAttendeeType';
import RosterType from '../types/RosterType';

interface StateValues {
    open: boolean
    handRaised: boolean
    roster: { [attendeeId: string]: RosterAttendeeType }
}

const getStartValues = (): StateValues => {
    return {
        open: false, // Roster Sidebar opened
        handRaised: false,
        roster: {},
    }
}

export interface RosterContext {
    onCallStart: () => void
    onCallEnd: () => void
    receiveMeetingDataMessage: (dataMessageType: DataMessageType, dataMessageJson: any) => void
    isOpen: () => boolean
    setOpen: (open: boolean) => void
    getRoster: () => {
        [attendeeId: string]: RosterAttendeeType;
    },
    getLocalAttendeeId: () => string | null,
    getAttendee: (attendeeId: string) => RosterAttendeeType,
    getNumAttendees: () => number,
    getMaxAttendees: () => number,
    hasMaxAttendees: () => boolean,
    isMyHandRaised: () => boolean,
    raiseMyHand: (raiseHand: boolean) => void,
    raiseHand: (attendeeId: string, raiseHand: boolean) => void,
}

const useStateWrapper = (state: State<StateValues>) :RosterContext=> {
    const rosterState = useState(state.roster)


    const handleHandRaisedState = (attendeeId: string, raiseHand: boolean) => {
        if (attendeeId === chimeSdk.localAttendeeId) {
            state.merge({ handRaised: raiseHand })
            saveRaiseHand(attendeeId, raiseHand)
        }
        else
            rosterState.set(prev => {
                if (prev[attendeeId]) {// If the roster is not loaded yet
                    prev[attendeeId].handRaised = raiseHand
                }
                return prev
            })
    }

    const rosterUpdate = (newRoster: RosterType) => {

        /*// For testing of all the tiles
        newRoster["1"] = { name: "Max Dubiel", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["2"] = { name: "Hendrik Weißbrod", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["3"] = { name: "Alex Bork", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["4"] = { name: "Alex Merkle", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["5"] = { name: "Julian Tan", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["6"] = { name: "Gustavo Niewöhner", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["7"] = { name: "Michael Gust", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["8"] = { name: "Carsten Kirschner", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["9"] = { name: "Amila Handzic", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["10"] = { name: "Jozo Skoko", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["11"] = { name: "Niloofar Rashvanloo", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["12"] = { name: "Johan Tomberg", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["13"] = { name: "Kristian Skobic", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["14"] = { name: "Waldemar Tomber", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["15"] = { name: "Meris Gutosic", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["16"] = { name: "Haris Heric", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["17"] = { name: "Haris Heric1", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["18"] = { name: "Haris Heric2", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["19"] = { name: "Haris Heric3", muted: false, signalStrength: 0, volume: 0, handRaised: false } */

        let hasChanges = false
        const oldRoster = rosterState.value
        const newRosterKeys = Object.keys(newRoster)
        const oldRosterKeys = Object.keys(oldRoster)
        // If the old and new roster list are of different length -> update the view
        const difference = newRosterKeys.filter(x => !oldRosterKeys.includes(x)).concat(oldRosterKeys.filter(x => !newRosterKeys.includes(x)))
        if (difference.length > 0) {
            hasChanges = true
        } else {
            // check if there are changes between the new and old update that we are interested in -> update
            for (let key of newRosterKeys) {
                const newRosterEntry = newRoster[key]
                const oldRosterEntry = oldRoster[key]
                if (!rosterAttendeeTypeEquals(newRosterEntry, oldRosterEntry)) {
                    hasChanges = true
                    break
                }
            }
        }

        if (hasChanges) {
            rosterState.set(newRoster)
        }
    }

    const raiseHand = (attendeeId: string, raiseHand: boolean) => {
        chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, { type: DataMessageType.RAISEHAND, attendeeId: chimeSdk.attendeeId, data: raiseHand })
        // Setting this values extra, because the sender does not receive his broadcasted message
        handleHandRaisedState(attendeeId, raiseHand)
    }

    return ({
        onCallStart: () => {
            chimeSdk.subscribeToRosterUpdate(rosterUpdate);
            handleHandRaisedState(chimeSdk.attendeeId!, loadRaiseHand(chimeSdk.attendeeId!))
        },
        onCallEnd: () => {
            chimeSdk.unsubscribeFromRosterUpdate(rosterUpdate)
            handleHandRaisedState(chimeSdk.attendeeId!, false)
        },
        receiveMeetingDataMessage: (dataMessageType: DataMessageType, dataMessageJson: any) => {
            if (dataMessageType === DataMessageType.RAISEHAND && dataMessageJson.attendeeId) {
                handleHandRaisedState(dataMessageJson.attendeeId, dataMessageJson.data)
            }
        },
        isOpen: () => state.get().open,
        setOpen: (open: boolean) => {
            return state.set(prevState => {
                prevState.open = open
                return prevState
            })
        },
        getRoster: () => {
            return rosterState.value
        },
        getLocalAttendeeId: () => {
            return chimeSdk.localAttendeeId
        },
        getAttendee: (attendeeId: string) => {
            return rosterState.value[attendeeId]
        },
        getNumAttendees: () => {
            return Object.keys(rosterState.get()).length
        },
        getMaxAttendees: () => {
            return chimeSdk.maxAttendees
        },
        hasMaxAttendees: () => {
            return Object.keys(rosterState.get()).length >= chimeSdk.maxAttendees
        },
        isMyHandRaised: () => {
            return state.value.handRaised
        },
        raiseMyHand: (_raiseHand: boolean) => {
            raiseHand(chimeSdk.localAttendeeId!, _raiseHand)
        },
        raiseHand: raiseHand,
    })
}

const state = createState<StateValues>(getStartValues())
export const useRosterContext = (): RosterContext => useStateWrapper(useState(state))

function saveRaiseHand(localAttendeeId: string, raiseHand: boolean) {
    localStorage.setItem("virtualGuide-myRaisedHand", `${localAttendeeId}#${raiseHand}`)
}

function loadRaiseHand(localAttendeeId: string) {
    const storageValue = localStorage.getItem("virtualGuide-myRaisedHand")
    if (!storageValue)
        return false
    const storageValueSplit = storageValue.split("#")
    if (!storageValueSplit || storageValueSplit.length !== 2)
        return false
    if (storageValueSplit[0] === localAttendeeId)
        return "true" === storageValueSplit[1]
    return false
}
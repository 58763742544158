import eventState, { EventPhase } from '../globalStates/EventState'
import queryString from 'query-string'
import { getIamPartOf } from '../globalStates/IAmPartOf'

const queryParams: any = queryString.parse(window.location.search)

export const isEventAnnouncementPhase: boolean = eventState.phase === EventPhase.EVENT_ANNOUNCEMENT

export const isTicketSalePhase: boolean = eventState.phase === EventPhase.TICKET_SALE

export const isOnboardingPhase: boolean = eventState.phase === EventPhase.ONBOARDING

export const isExplorationPhase: boolean = eventState.phase === EventPhase.EXPLORATION

export const isSoftOpeningPhase: boolean = eventState.phase === EventPhase.SOFT_OPENING

export const isLivePhase: boolean = (eventState.phase === EventPhase.LIVE || getIamPartOf(queryParams) === "VIR")

export const isPostEventPhase: boolean = (eventState.phase === EventPhase.POST_EVENT && getIamPartOf(queryParams) !== "VIR")

export const isExplorationOrPostEventPhase: boolean = eventState.phase === EventPhase.EXPLORATION || isPostEventPhase
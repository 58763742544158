import { fetchDataWebService } from "./BackendServicesUtils"
import { JobOffer } from "./Types"
import { defaultLogger as logger } from "../globalStates/AppState"

/*********************************************************************************************
 * JOBOFFER LIST
 **********************************************************************************************/
export interface JobofferListResponse {
    count: number
    jobofferList: JobOffer[]
}

export async function loadJobofferListData(params: object, signal?: AbortSignal): Promise<JobofferListResponse> {
    try {
        const data = await fetchDataWebService("/search", params, signal, true)
        return { count: data.count, jobofferList: data.entities }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

/*********************************************************************************************
 * JOBOFFER DETAILS
 **********************************************************************************************/
export interface JobOfferResponse {
    content: JobOffer
}

export interface JobOfferDetailParams {
    id: string
}

export async function loadJobOfferData(params: JobOfferDetailParams, signal?: AbortSignal): Promise<JobOfferResponse> {
    try {
        const data = await fetchDataWebService("/jobofferdetails", params, signal)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/jobofferdetails",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

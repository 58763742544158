import React, { useRef } from "react"
import styled from "styled-components"

const UploadLogoRoot = styled.div`
`

interface LogoUploadProps {
    onImageChanged: (src: string | null) => void
    children?: React.ReactNode
    style?: React.CSSProperties
}
export function LogoUpload(props: LogoUploadProps) {
    const imgRef = useRef<HTMLImageElement>(null)
    const inputRef = useRef<any>(null)

    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !imgRef?.current) return
        const file = e.target.files[0]
        if (file && isFileImage(file)) {
            reader.addEventListener("load", () => {
                if (imgRef.current) imgRef.current.src = reader.result as string
            })
            reader.readAsDataURL(file)
        } else {
            props.onImageChanged(null)
        }
    }

    const onImageLoaded = () => {
        props.onImageChanged(imgRef.current!.src)
    }

    const onImageError = () => {
        props.onImageChanged(null)
    }

    return (
        <UploadLogoRoot onClick={() => inputRef.current.click()} style={props.style}>
            <input style={{ display: "none" }} ref={inputRef} type="file" accept="image/png, image/jpeg" onChange={onChange} />
            <img style={{ display: "none" }} ref={imgRef} onLoad={onImageLoaded} onError={onImageError} alt="logo preview" />
            {props.children}
        </UploadLogoRoot>
    )
}

function isFileImage(file: File) {
    return file && file["type"].split("/")[0] === "image"
}

const reader = new FileReader()

export function findCountryName(countryCode: string, lang: string): string {
    const countryName: string =
        (lang === "en"
            ? getCountries(lang).find((country: any) => country.code === countryCode)?.en
            : getCountries(lang).find((country: any) => country.code === countryCode)?.de) || ""
    return countryName
}

export interface Country {
    en: string
    de: string
    code: string
}

function sortAlphabetically(lang: string): Country[] {
    return countries.sort(function (a: Country, b: Country) {
        if (lang === "de") {
            return a.de.localeCompare(b.de)
        } else {
            return a.en.localeCompare(b.en)
        }
    })
}

export function getCountries(lang: string): Array<Country> {
    return sortAlphabetically(lang)
}

const countries: Array<Country> = [
    { en: "Afghanistan", de: "Afghanistan", code: "AF" },
    { en: "Åland Islands", de: "Åland", code: "AX" },
    { en: "Albania", de: "Albanien", code: "AL" },
    { en: "Algeria", de: "Algerien", code: "DZ" },
    { en: "American Samoa", de: "Amerikanisch-Samoa", code: "AS" },
    { en: "Andorra", de: "Andorra", code: "AD" },
    { en: "Angola", de: "Angola", code: "AO" },
    { en: "Anguilla", de: "Anguilla", code: "AI" },
    { en: "Antarctica", de: "Antarctica", code: "AQ" },
    { en: "Antigua and Barbuda", de: "Antigua und Barbuda", code: "AG" },
    { en: "Argentina", de: "Argentinien", code: "AR" },
    { en: "Armenia", de: "Armenien", code: "AM" },
    { en: "Aruba", de: "Aruba", code: "AW" },
    { en: "Australia", de: "Australien", code: "AU" },
    { en: "Austria", de: "Österreich", code: "AT" },
    { en: "Azerbaijan", de: "Aserbaidschan", code: "AZ" },
    { en: "Bahamas", de: "Bahamas", code: "BS" },
    { en: "Bahrain", de: "Bahrain", code: "BH" },
    { en: "Bangladesh", de: "Bangladesch", code: "BD" },
    { en: "Barbados", de: "Barbados", code: "BB" },
    { en: "Belarus", de: "Weißrussland", code: "BY" },
    { en: "Belgium", de: "Belgien", code: "BE" },
    { en: "Belize", de: "Belize", code: "BZ" },
    { en: "Benin", de: "Benin", code: "BJ" },
    { en: "Bermuda", de: "Bermuda", code: "BM" },
    { en: "Bhutan", de: "Bhutan", code: "BT" },
    { en: "Bolivia", de: "Bolivia", code: "BO" },
    { en: "Bosnia and Herzegovina", de: "Bosnien und Herzegowina", code: "BA" },
    { en: "Botswana", de: "Botswana", code: "BW" },
    { en: "Bouvet Island", de: "Bouvetinsel", code: "BV" },
    { en: "Brazil", de: "Brasilien", code: "BR" },
    { en: "British Indian Ocean Territory", de: "Britisches Territorium im Indischen Ozean", code: "IO" },
    { en: "Brunei", de: "Brunei", code: "BN" },
    { en: "Bulgaria", de: "Bulgarien", code: "BG" },
    { en: "Burkina Faso", de: "Burkina Faso", code: "BF" },
    { en: "Burundi", de: "Burundi", code: "BI" },
    { en: "Cambodia", de: "Kambodscha", code: "KH" },
    { en: "Cameroon", de: "Kamerun", code: "CM" },
    { en: "Canada", de: "Kanada", code: "CA" },
    { en: "Cape Verde", de: "Kap Verde", code: "CV" },
    { en: "Cayman Islands", de: "Kaimaninseln", code: "KY" },
    { en: "Central African Republic", de: "Zentralafrikanische Republik", code: "CF" },
    { en: "Chad", de: "Tschad", code: "TD" },
    { en: "Chile", de: "Chile", code: "CL" },
    { en: "China", de: "China", code: "CN" },
    { en: "Christmas Island", de: "Weihnachtsinsel", code: "CX" },
    { en: "Cocos (Keeling) Islands", de: "Kokosinseln", code: "CC" },
    { en: "Colombia", de: "Kolumbien", code: "CO" },
    { en: "Comoros", de: "Komoren", code: "KM" },
    { en: "Congo, Republic of", de: "Kongo (Rep.)", code: "CG" },
    { en: "Congo, The Democratic Republic of", de: "Kongo (Dem. Rep.)", code: "CD" },
    { en: "Cook Islands", de: "Cookinseln", code: "CK" },
    { en: "Costa Rica", de: "Costa Rica", code: "CR" },
    { en: "Cote D'Ivoire", de: "Elfenbeinküste", code: "CI" },
    { en: "Croatia", de: "Kroatien", code: "HR" },
    { en: "Cuba", de: "Kuba", code: "CU" },
    { en: "Cyprus", de: "Zypern", code: "CY" },
    { en: "Czechia", de: "Tschechien", code: "CZ" },
    { en: "Denmark", de: "Dänemark", code: "DK" },
    { en: "Djibouti", de: "Dschibuti", code: "DJ" },
    { en: "Dominica", de: "Dominica", code: "DM" },
    { en: "Dominican Republic", de: "Dominikanische Republik", code: "DO" },
    { en: "East Timor", de: "Osttimor", code: "TL" },
    { en: "Ecuador", de: "Ecuador", code: "EC" },
    { en: "Egypt", de: "Ägypten", code: "EG" },
    { en: "El Salvador", de: "El Salvador", code: "SV" },
    { en: "Equatorial Guinea", de: "Äquatorialguinea ", code: "GQ" },
    { en: "Eritrea", de: "Eritrea", code: "ER" },
    { en: "Estonia", de: "Estland", code: "EE" },
    { en: "Eswatini", de: "Eswatini", code: "SZ" },
    { en: "Ethiopia", de: "Äthiopien", code: "ET" },
    { en: "Falkland Islands (Malvinas)", de: "Falklandinseln", code: "FK" },
    { en: "Faroe Islands", de: "Färöer ", code: "FO" },
    { en: "Fiji", de: "Fidschi", code: "FJ" },
    { en: "Finland", de: "Finnland", code: "FI" },
    { en: "France", de: "Frankreich", code: "FR" },
    { en: "French Guiana", de: "Französisch-Guayana", code: "GF" },
    { en: "French Polynesia", de: "Französisch-Polynesien", code: "PF" },
    { en: "French Southern and Antarctic Lands", de: "Französische Süd- und Antarktisgebiete", code: "TF" },
    { en: "Gabon", de: "Gabun", code: "GA" },
    { en: "Gambia", de: "Gambia", code: "GM" },
    { en: "Georgia", de: "Georgien", code: "GE" },
    { en: "Germany", de: "Deutschland", code: "DE" },
    { en: "Ghana", de: "Ghana", code: "GH" },
    { en: "Gibraltar", de: "Gibraltar", code: "GI" },
    { en: "Greece", de: "Griechenland", code: "GR" },
    { en: "Greenland", de: "Grönland", code: "GL" },
    { en: "Grenada", de: "Grenada", code: "GD" },
    { en: "Guadeloupe", de: "Guadeloupe", code: "GP" },
    { en: "Guam", de: "Guam", code: "GU" },
    { en: "Guatemala", de: "Guatemala", code: "GT" },
    { en: "Guernsey", de: "Guernsey", code: "GG" },
    { en: "Guinea", de: "Guinea", code: "GN" },
    { en: "Guinea-Bissau", de: "Guinea-Bissau", code: "GW" },
    { en: "Guyana", de: "Guyana", code: "GY" },
    { en: "Haiti", de: "Haiti", code: "HT" },
    { en: "Heard Island and McDonald Islands", de: "Heard und McDonaldinseln", code: "HM" },
    { en: "Honduras", de: "Honduras", code: "HN" },
    { en: "Hong Kong", de: "Hongkong", code: "HK" },
    { en: "Hungary", de: "Ungarn", code: "HU" },
    { en: "Iceland", de: "Island", code: "IS" },
    { en: "India", de: "Indien", code: "IN" },
    { en: "Indonesia", de: "Indonesien", code: "ID" },
    { en: "Iran,", de: "Iran", code: "IR" },
    { en: "Iraq", de: "Irak", code: "IQ" },
    { en: "Ireland", de: "Irland", code: "IE" },
    { en: "Isle of Man", de: "Insel Man", code: "IM" },
    { en: "Israel", de: "Israel", code: "IL" },
    { en: "Italy", de: "Italien", code: "IT" },
    { en: "Jamaica", de: "Jamaica", code: "JM" },
    { en: "Japan", de: "Japan", code: "JP" },
    { en: "Jersey", de: "Jersey", code: "JE" },
    { en: "Jordan", de: "Jordanien", code: "JO" },
    { en: "Kazakhstan", de: "Kasachstan", code: "KZ" },
    { en: "Kenya", de: "Kenia", code: "KE" },
    { en: "Kiribati", de: "Kiribati", code: "KI" },
    { en: "Korea, Democratic People'S Republic of", de: "Nordkorea", code: "KP" },
    { en: "Korea, Republic of", de: "Südkorea", code: "KR" },
    { en: "Kosovo", de: "Kosovo", code: "XK" },
    { en: "Kuwait", de: "Kuwait", code: "KW" },
    { en: "Kyrgyzstan", de: "Kirgisistan", code: "KG" },
    { en: "Laos", de: "Laos", code: "LA" },
    { en: "Latvia", de: "Lettland", code: "LV" },
    { en: "Lebanon", de: "Libanon", code: "LB" },
    { en: "Lesotho", de: "Lesotho", code: "LS" },
    { en: "Liberia", de: "Liberia", code: "LR" },
    { en: "Libya", de: "Libyen", code: "LY" },
    { en: "Liechtenstein", de: "Liechtenstein", code: "LI" },
    { en: "Lithuania", de: "Litauen", code: "LT" },
    { en: "Luxembourg", de: "Luxemburg", code: "LU" },
    { en: "Macao", de: "Macau", code: "MO" },
    { en: "Madagascar", de: "Madagaskar", code: "MG" },
    { en: "Malawi", de: "Malawi", code: "MW" },
    { en: "Malaysia", de: "Malaysia", code: "MY" },
    { en: "Maldives", de: "Malediven", code: "MV" },
    { en: "Mali", de: "Mali", code: "ML" },
    { en: "Malta", de: "Malta", code: "MT" },
    { en: "Marshall Islands", de: "Marshallinseln", code: "MH" },
    { en: "Martinique", de: "Martinique", code: "MQ" },
    { en: "Mauritania", de: "Mauretanien", code: "MR" },
    { en: "Mauritius", de: "Mauritius", code: "MU" },
    { en: "Mayotte", de: "Mayotte", code: "YT" },
    { en: "Mexico", de: "Mexiko", code: "MX" },
    { en: "Micronesia", de: "Mikronesien", code: "FM" },
    { en: "Moldova", de: "Moldau", code: "MD" },
    { en: "Monaco", de: "Monaco", code: "MC" },
    { en: "Mongolia", de: "Mongolei", code: "MN" },
    { en: "Montenegro", de: "Montenegro", code: "ME" },
    { en: "Montserrat", de: "Montserrat", code: "MS" },
    { en: "Morocco", de: "Marokko", code: "MA" },
    { en: "Mozambique", de: "Mosambik", code: "MZ" },
    { en: "Myanmar", de: "Myanmar", code: "MM" },
    { en: "Namibia", de: "Namibia", code: "NA" },
    { en: "Nauru", de: "Nauru", code: "NR" },
    { en: "Nepal", de: "Nepal", code: "NP" },
    { en: "Netherlands", de: "Niederlande", code: "NL" },
    { en: "Netherlands Antilles", de: "Niederländische Antillen", code: "AN" },
    { en: "New Caledonia", de: "Neukaledonien", code: "NC" },
    { en: "New Zealand", de: "Neuseeland", code: "NZ" },
    { en: "Nicaragua", de: "Nicaragua", code: "NI" },
    { en: "Niger", de: "Niger", code: "NE" },
    { en: "Nigeria", de: "Nigeria", code: "NG" },
    { en: "Niue", de: "Niue", code: "NU" },
    { en: "Norfolk Island", de: "Norfolkinseln", code: "NF" },
    { en: "North Macedonia", de: "Nordmazedonien", code: "MK" },
    { en: "Northern Mariana Islands", de: "Nördliche Marianen", code: "MP" },
    { en: "Norway", de: "Norwegen", code: "NO" },
    { en: "Oman", de: "Oman", code: "OM" },
    { en: "Pakistan", de: "Pakistan", code: "PK" },
    { en: "Palau", de: "Palau", code: "PW" },
    { en: "Palestine, State of", de: "Palästina, Staat", code: "PS" },
    { en: "Panama", de: "Panama", code: "PA" },
    { en: "Papua New Guinea", de: "Papua-Neuguinea", code: "PG" },
    { en: "Paraguay", de: "Paraguay", code: "PY" },
    { en: "Peru", de: "Peru", code: "PE" },
    { en: "Philippines", de: "Philippinen", code: "PH" },
    { en: "Pitcairn", de: "Pitcairninseln", code: "PN" },
    { en: "Poland", de: "Polen", code: "PL" },
    { en: "Portugal", de: "Portugal", code: "PT" },
    { en: "Puerto Rico", de: "Puerto Rico", code: "PR" },
    { en: "Qatar", de: "Katar", code: "QA" },
    { en: "Réunion", de: "Réunion", code: "RE" },
    { en: "Romania", de: "Rumänien", code: "RO" },
    { en: "Russia", de: "Russland", code: "RU" },
    { en: "Rwanda", de: "Ruanda", code: "RW" },
    { en: "Saint Helena, Ascension and Tristan da Cunha", de: "Saint Helena, Ascension und Tristan da Cunha", code: "SH" },
    { en: "Saint Kitts and Nevis", de: "Saint Kitts und Nevis", code: "KN" },
    { en: "Saint Lucia", de: "Saint Lucia", code: "LC" },
    { en: "Saint Pierre and Miquelon", de: "Saint Pierre und Miquelon", code: "PM" },
    { en: "Saint Vincent and the Grenadines", de: "Saint Vincent und die Grenadinen", code: "VC" },
    { en: "Samoa", de: "Samoa", code: "WS" },
    { en: "San Marino", de: "San Marino", code: "SM" },
    { en: "Sao Tome and Principe", de: "São Tomé und Princípe", code: "ST" },
    { en: "Saudi Arabia", de: "Saudi-Arabien", code: "SA" },
    { en: "Senegal", de: "Senegal", code: "SN" },
    { en: "Serbia", de: "Serbien", code: "RS" },
    { en: "Seychelles", de: "Seychellen", code: "SC" },
    { en: "Sierra Leone", de: "Sierra Leone", code: "SL" },
    { en: "Singapore", de: "Singapur", code: "SG" },
    { en: "Slovakia", de: "Slowakei", code: "SK" },
    { en: "Slovenia", de: "Slowenien", code: "SI" },
    { en: "Solomon Islands", de: "Salomonen", code: "SB" },
    { en: "Somalia", de: "Somalia", code: "SO" },
    { en: "South Africa", de: "Südafrika", code: "ZA" },
    { en: "South Georgia and the South Sandwich Islands", de: "Südgeorgien und die Südlichen Sandwichinseln", code: "GS" },
    { en: "South Sudan", de: "Südsudan", code: "SS" },
    { en: "Spain", de: "Spanien", code: "ES" },
    { en: "Sri Lanka", de: "Sri Lanka", code: "LK" },
    { en: "Sudan", de: "Sudan", code: "SD" },
    { en: "Suriname", de: "Suriname", code: "SR" },
    { en: "Svalbard and Jan Mayen", de: "Svalbard und Jan Mayen", code: "SJ" },
    { en: "Sweden", de: "Schweden", code: "SE" },
    { en: "Switzerland", de: "Schweiz", code: "CH" },
    { en: "Syria", de: "Syrien", code: "SY" },
    { en: "Taiwan, Province of China", de: "Taiwan (Republik China)", code: "TW" },
    { en: "Tajikistan", de: "Tadschikistan", code: "TJ" },
    { en: "Tanzania", de: "Tansania", code: "TZ" },
    { en: "Thailand", de: "Thailand", code: "TH" },
    { en: "Togo", de: "Togo", code: "TG" },
    { en: "Tokelau", de: "Tokelau", code: "TK" },
    { en: "Tonga", de: "Tonga", code: "TO" },
    { en: "Trinidad and Tobago", de: "Trinidad und Tobago", code: "TT" },
    { en: "Tunisia", de: "Tunesien", code: "TN" },
    { en: "Turkey", de: "Türkei", code: "TR" },
    { en: "Turkmenistan", de: "Turkmenistan", code: "TM" },
    { en: "Turks and Caicos Islands", de: "Turks- und Caicosinseln", code: "TC" },
    { en: "Tuvalu", de: "Tuvalu", code: "TV" },
    { en: "Uganda", de: "Uganda", code: "UG" },
    { en: "Ukraine", de: "Ukraine", code: "UA" },
    { en: "United Arab Emirates", de: "Vereinigte Arabische Emirate", code: "AE" },
    { en: "United Kingdom", de: "Vereinigtes Königreich", code: "GB" },
    { en: "United States of America", de: "Vereinigten Staaten von Amerika", code: "US" },
    { en: "United States Minor Outlying Islands", de: "Kleinere abgelegene Inseln der Vereinigten Staaten", code: "UM" },
    { en: "Uruguay", de: "Uruguay", code: "UY" },
    { en: "Uzbekistan", de: "Usbekistan", code: "UZ" },
    { en: "Vanuatu", de: "Vanuatu", code: "VU" },
    { en: "Vatican City", de: "Vatikanstadt", code: "VA" },
    { en: "Venezuela", de: "Venezuela", code: "VE" },
    { en: "Vietnam", de: "Vietnam", code: "VN" },
    { en: "Virgin Islands, British", de: "Britische Jungferninseln", code: "VG" },
    { en: "Virgin Islands, U.S.", de: "Amerikanische Jungferninseln", code: "VI" },
    { en: "Wallis and Futuna", de: "Wallis aud Futuna", code: "WF" },
    { en: "Western Sahara", de: "Westsahara", code: "EH" },
    { en: "Yemen", de: "Yemen", code: "YE" },
    { en: "Zambia", de: "Sambia", code: "ZM" },
    { en: "Zimbabwe", de: "Simbabwe", code: "ZW" }
]

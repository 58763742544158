// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { useEffect, useRef } from "react"
import styled from "styled-components"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useVideoContext } from "../context/VideoContext"
import { LocalVideoStatus } from "../enums/LocalVideoStatus"
import { detect } from "detect-browser"

const PreviewVideoRoot = styled.video`
    transform: none !important;
    border-radius: 5px;
`

interface PreviewVideoProps {}

export default function PreviewVideo(props: PreviewVideoProps) {
    const ref = useRef<HTMLVideoElement>(null)
    const videoContext = useVideoContext()
    const strings = useLanguageState().getStrings().audioVideoSettings
    const browser = detect()?.name

    const localVideoStatus = videoContext.getLocalVideoStatus()
    useEffect(
        () => {
            const videoElement = ref.current
            if (!videoElement || videoContext.getLocalVideoStatus() !== LocalVideoStatus.Enabled) return
            videoContext.startPreviewVideo(videoElement)
            return () => {
                if (!videoElement) return
                videoContext.stopPreviewVideo(videoElement)
            }
        },
        // eslint-disable-next-line
        [ref.current, localVideoStatus]
    )
    return (
        <PreviewWrapper browser={browser}>
            <PreviewVideoRoot style={{ maxWidth: "310px", maxHeight: "197px" }} muted ref={ref} />
            <PreviewOverlay>
                <span>{strings.preview}</span>
            </PreviewOverlay>
        </PreviewWrapper>
    )
}

const PreviewWrapper = styled.div<{ browser: any }>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: auto;
    ${(props) => {
        if (props.browser === "chrome") {
            return `
            width: 310px;
        `
        } else if (props.browser === "firefox") {
            return `
        width: 263px;
        `
        } else {
            return `
        width: 310px;
        `
        }
    }}

    
`

export const PreviewOverlay = styled.div`
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 30px;
    background: linear-gradient(0deg, #202428 0%, rgba(114, 114, 114, 0) 100%);
    color: #fff;
    text-align: center;
    z-index: 5;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 12px;
    line-height: 12px;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 0.5em;
`

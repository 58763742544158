import branding from "../branding/branding"
import { IconDefaultAvatar } from "../ui/Icons"

interface AvatarDefaultContentProps {
    width?: number
    height?: number
}
const AvatarDefaultContent: React.FunctionComponent<AvatarDefaultContentProps> = (props) => {
    const iconwidth = props.width
    const iconheight = props.height
    return (
        <span>
            {IconDefaultAvatar({
                fill: branding.communicationAreaDefaultAvatarColor || "lightgrey",
                width: iconwidth + "px",
                height: iconheight + "px"
            })}
        </span>
    )
}

export default AvatarDefaultContent

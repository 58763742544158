import { useEffect, useRef } from "react"
import * as React from "react"
import styled from "styled-components"
import { IconSearch } from "../ui/Icons"
import branding from "../branding/branding"
import { useAppState } from "../globalStates/AppState"
import { useLanguageState } from "../globalStates/LanguageState"
import { ConditionalWrapper } from "../ui/ConditionalWrapper"
import GuestUserBannerSharedState from "../contentArea/guestUserBanner/GuestUserBannerSharedState"
import { useSuggestContext } from "./useSuggestContext"
import SuggestBox, { SearchAllBtn } from "./SuggestBox"
import useWindowDimensions from "../ui/WindowDimensionsHook"

const TopBarRootContainer = styled.div`
    position: relative;
    width: 100%;
    height: 0;
`

const TopBarRoot = styled.div<{
    backgroundColor: string
    foregroundColor: string
    background?: string
    border?: boolean
    position: string
    top: string
    showShadow?: boolean
}>`
    position: ${(prop) => prop.position};
    width: 100%;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    height: 60px;
    z-index: 10005;
    border-bottom: ${(prop) => (prop.border === false ? 0 : branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9")};
    background: ${(props) => props.background ?? ""};
    color: ${(props) => props.foregroundColor};
    background-color: ${(props) => props.backgroundColor};
    font-family: ${branding.font1};
    & a:hover,
    & a:active,
    & a:link,
    & a:visited {
        color: ${(props) => props.foregroundColor};
    }
    top: ${(props) => props.top};
    box-shadow: ${(props) => (props.showShadow ? branding.primaryScrollDarkShadowTTB : "initial")};
`

const IconContainer = styled.div`
    width: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
`

const SearchTitle = styled.div`
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    z-index: 11;
    flex: 1;
    cursor: pointer;
    white-space: nowrap;
    align-items: center;
    font-family: ${branding.topBar.searchTitleFontFamily};
    font-size: ${branding.topBar.searchTitleFontSize};
    font-weight: ${branding.topBar.searchTitleFontWeight};
    text-transform: ${branding.topBar.searchTitleTextTranform};
    line-height: 24px;
    letter-spacing: ${branding.topBar.searchTitleLetterSpacing};
    margin-left: 2px;
`
export enum TopBarMode {
    IN_PAGE, // default
    ABOVE_PAGE // the top bar hovers over the content of the page
}

interface TopBarProps {
    border?: boolean
    mode?: TopBarMode
    textColor?: string
    backgroundColor?: string
    background?: string
    setRef?: Function
    showShadow?: boolean
}

const TopBar: React.FunctionComponent<TopBarProps> = (props) => {
    const appState = useAppState()
    const strings = useLanguageState().getStrings()
    //Add banner state
    const { guestUserBannerRef } = GuestUserBannerSharedState()
    const topBannerRef = useRef<HTMLDivElement>(null)
    const { isVisible, setVisible } = useSuggestContext()
    const { isMobile } = useWindowDimensions()

    const position = props.mode === TopBarMode.ABOVE_PAGE ? "absolute" : "sticky"
    const top = guestUserBannerRef && guestUserBannerRef.current && guestUserBannerRef.current.clientHeight !== 0 ? "50px" : "0"
    let textColor: string, backgroundColor: string

    switch (props.mode) {
        case TopBarMode.ABOVE_PAGE:
            textColor = branding.topBar.textColor
            backgroundColor = "transparent"
            break
        default:
            textColor = props.textColor!
            backgroundColor = props.backgroundColor!
    }

    useEffect(() => {
        if (topBannerRef && topBannerRef.current && props.setRef) props.setRef(topBannerRef)
        // eslint-disable-next-line
    }, [topBannerRef])

    if (isMobile) {
        return null
    }

    return (
        <ConditionalWrapper
            wrapper={(children) => (props.mode === TopBarMode.ABOVE_PAGE ? <TopBarRootContainer children={children} /> : null)}
        >
            <div></div>
            <TopBarRoot
                ref={topBannerRef}
                className={appState.isNetworkingOpen() ? "active" : "networkingCollapsed"}
                foregroundColor={textColor!}
                backgroundColor={backgroundColor!}
                border={props.border}
                position={position}
                top={top}
                background={props.background}
                showShadow={props.showShadow}
            >
                <IconContainer className="ic-container" onClick={() => setVisible(true)}>
                    {IconSearch({ fill: "currentColor" })}
                </IconContainer>
                {!isVisible && (
                    <SearchTitle onClick={() => setVisible(true)}>{strings.globalTexts.globalSearchTitle}</SearchTitle>
                )}
                <SearchAllBtn>{strings.suggestBoxContent.searchAllBtn.text}</SearchAllBtn>
                {isVisible && <SuggestBox />}
            </TopBarRoot>
        </ConditionalWrapper>
    )
}

export default TopBar

TopBar.defaultProps = {
    textColor: branding.mainInfoColor ?? "#000",
    backgroundColor: "#fff",
    iconColor: branding.primaryColor ?? "#000"
} as Partial<TopBarProps>

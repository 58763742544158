import { BackendServiceError, fetchDataRest, topic } from "./BackendServicesUtils"
import { defaultLogger as logger } from "../globalStates/AppState"

/*********************************************************************************************
 * EVENT DETAILS
 **********************************************************************************************/
export interface EventDetailsResponse {
    content: Event
}
export async function loadEventDetails(eventId: string, delayTime?: number): Promise<EventDetailsResponse | BackendServiceError> {
    const defaultRoute: string = `/eventDates/topic/${topic}/event/${eventId}`

    try {
        const data = await fetchDataRest(
            defaultRoute,
            {
                delayTime: delayTime
            },
            "GET"
        )
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: null,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export enum MeetingStatusCode {
    Loading = "LOADING",
    Succeeded = "SUCCEEDED",
    Disconnected = "DISCONNECTED",
    Failed = "FAILED",
    Full = "FULL",
    TimeUp = "TIMEUP",
    Kicked = "KICKED",
    Banned = "BANNED",
    Ended = "ENDED",
    GreenroomLive = "GREENROOMLIVE",
}
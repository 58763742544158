import { useEffect, useState } from "react"
import branding from '../../branding/branding'
import { ThirdPartySettingsType } from './SettingsTabBranding'

let thirdPartySettingsModule: ThirdPartySettingsModule | undefined
async function loadThirdPartySettingsModule() {
    switch (branding.settingsTab.thirdPartySettingsType) {
        case ThirdPartySettingsType.KOELNMESSE:
            thirdPartySettingsModule = (await import('./koelnmesse/KoelnMesseSettings')).default
            break
        default:
            break
    }
}
export interface ThirdPartySettingsModule {
    component: () => JSX.Element
}

interface LoginModuleComponentProps {
    
}
function ThirdPartySettingsComponent(props: LoginModuleComponentProps) {
    const [thirdPartySettingsModuleState, setThirdPartySettingsModuleState] = useState<ThirdPartySettingsModule | undefined>()
    useEffect(() => {
        if (!thirdPartySettingsModuleState)
            loadThirdPartySettingsModule().then(() => {
                setThirdPartySettingsModuleState(thirdPartySettingsModule)
            })
    }, []) // eslint-disable-line

    if (thirdPartySettingsModuleState)
        return <thirdPartySettingsModuleState.component/>
    else
        return null
}

export default ThirdPartySettingsComponent


/*********************************************************************************************
 * SPEAKER LIST
 **********************************************************************************************/

import { fetchDataWebService } from "./BackendServicesUtils"
import { Contact, Person } from "./Types"
import { defaultLogger as logger } from "../globalStates/AppState"

export interface SpeakersListResponse {
    /**
     * @title Speakers list response count
     * @description Count number for speakers list response
     * @title_de Speakers list response count
     * @description_de Count number for speakers list response
     */
    count: number
    /**
     * @title Persons list
     * @description Configuration for persons list
     * @title_de Persons list
     * @description_de Configuration for ersons list
     */
    persons: Person[]
}

export interface PersonsDataRequestParams {
    numresultrows: number
    startresultrow: number
    filterlist: string[]
    order: string
    alpha?: string
}

export async function loadPersonsData(params: PersonsDataRequestParams, signal?: AbortSignal): Promise<SpeakersListResponse> {
    try {
        const data = await fetchDataWebService("/search", params, signal)
        return { count: data.count, persons: data.entities }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { count: 0, persons: [] }
    }
}

/*********************************************************************************************
 * SPEAKER DETAILS
 **********************************************************************************************/
export interface SpeakerResponse {
    content: Contact
}

export interface PersonDetailsParams {
    id: string
}
export async function loadPersonData(params: PersonDetailsParams): Promise<SpeakerResponse> {
    try {
        const data = await fetchDataWebService("/persondetails", params)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/persondetails",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

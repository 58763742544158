const loadZoomchart = (callback: () => void) => {
    const existingScript = document.getElementById('zoomchart-script');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://cdn.zoomcharts-cloud.com/1/stable/zoomcharts.js';
      script.id = 'zoomchart-script';
      document.body.appendChild(script);
      script.onload = () => { 
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  };
  export default loadZoomchart;
import * as React from "react"
import styled from "styled-components"
import { Modal } from "react-bootstrap"
import branding from "../branding/branding"
import { IconClose, IconIndicatorErrorBlack } from "./Icons"
const ModalRoot = styled(Modal)<{ width?: string; height?: string; top?: string }>`
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};

    svg {
        color: ${branding.primaryColor};
    }

    .close {
        text-shadow: none;
        color: ${branding.mainInfoColor};
        margin: -1.8rem -1rem -1rem auto;
    }

    .modal-dialog {
        width: ${(props) => (props.width ? props.width : "300px")} !important;
        height: ${(props) => (props.height ? props.height : "auto")} !important;
        top: ${(props) => (props.top ? props.top : "80%")};
        transform: translateY(-50%);
    }

    .modal-content {
        width: ${(props) => (props.width ? props.width : "300px")} !important;
        height: ${(props) => (props.height ? props.height : "auto")} !important;
    }

    .modal-header-close {
        outline: 0;
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 26px;
        font-weight: 10;
        line-height: 1.66666667;
        color: ${branding.mainInfoColor};
        width: 30px;
        padding: 0 4px;
        background: none;
        border-style: none;
        margin-right: 0px;
        margin-top: -10px;
    }

    .modal-content {
        font-family: ${branding.font1};
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: none;
        padding: 15px 0 10px 0;
    }
    .modal-footer {
        justify-content: flex-end;
        border: none;
        font-size: 12px;
        line-height: 12px;
        padding: 0;
    }

    .modal-body {
        padding: 5px 5px 15px 5px;
        font-size: 12px;
        line-height: 17px;
        display: flex;
        flex-direction: column;

        p {
            margin-bottom: 0;
        }

        a {
            color: #000;
            text-decoration: underline;
        }
    }

    .modal-title {
        font-family: ${branding.font1};
        margin-left: 5px;
        font-size: 14px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 3px;
        color: ${branding.crsTabs.tabItemDefaultActiveStateColor};

        .injected-svg {
            width: 13px !important;
            height: 13px !important;
            margin-bottom: 3px !important;
        }
    }

    .btn-submit {
        font-family: ${branding.font1};
        width: 120px;
        display: block;
        background-color: ${branding.sayHelloModal.submitBtnPrimaryBgColor ?? "#000"};
        color: ${branding.sayHelloModal.submitBtnPrimaryTextColor ?? "#fff"};
        /* border: 1px solid ${branding.sayHelloModal.submitBtnPrimaryBorderColor ?? "#000"};
        border-radius: 5px; */
        padding: 10px 8px;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        border: 1px solid ${branding.crsTabs.defaultActionItemActiveStateColor};
    }

    .subtitleText {
        font-family: ${branding.font1};
        display: absolute;
        align-items: center;
        font-size: 14px;
        line-height: 18px;
        margin: 10px 15px 25px 15px;
    }

    .headerImage {
        margin-top: 5px;
        margin-left: 15px;
    }

    .customString {
        font-weight: bold;
    }

    .messageBox {
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        /* margin-left: 8px; */
        font-family: ${branding.font1};
    }

    .checkboxWrapper {
        margin-top: 20px;
    }

    .checkboxLabel {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
    }

    .checkbox {
        margin-right: 5px;
    }
`

interface ConferenceRoomModalProps {
    close: () => void
    title: string
    message: string
    clickableWord?: string
    clickableWordURL?: string
    showSubmitButton?: boolean
    submitButtonText?: string
    showCheckBox?: boolean
    checkBoxLabel?: string
    handleCheckBox?: () => void
    width?: string
    height?: string
    top?: string
}

const ConferenceRoomModal: React.FunctionComponent<ConferenceRoomModalProps> = (props: ConferenceRoomModalProps) => {
    return (
        <ModalRoot backdrop="static" show={true} animation={false} top={props.top} width={props.width} height={props.height}>
            <div onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h3 className="modal-title">
                        {IconIndicatorErrorBlack({ fill: branding.crsTabs.tabItemDefaultActiveStateColor })} {props.title}
                    </h3>
                    <button type="button" className="close" onClick={() => props.close()} aria-label="Close">
                        {IconClose({ fill: branding.sideIconBar.sideIconColorDark, width: "10", height: "10" })}
                    </button>
                </div>

                <div className="modal-body">
                    <p>
                        {props.message}
                        <span>
                            <a href={props.clickableWordURL} rel="noreferrer" target="_blank">
                                {props.clickableWord}
                            </a>
                        </span>
                    </p>
                    {props.showCheckBox && props.checkBoxLabel && props.handleCheckBox && (
                        <CheckBox checkBoxLabel={props.checkBoxLabel} handleCheckBox={props.handleCheckBox} />
                    )}
                </div>

                <div className="modal-footer">
                    {props.showSubmitButton && (
                        <div onClick={() => props.close()} className="btn-submit">
                            {props.submitButtonText || "OK"}
                        </div>
                    )}
                </div>
            </div>
        </ModalRoot>
    )
}

interface CheckboxProps {
    checkBoxLabel: string
    handleCheckBox: () => void
}

const CheckBox = (props: CheckboxProps) => {
    return (
        <>
            <div className="checkboxWrapper">
                <label className="checkboxLabel">
                    <input type="checkbox" className="checkbox" onChange={() => props.handleCheckBox()} />
                    {props.checkBoxLabel}
                </label>
            </div>
        </>
    )
}

export default ConferenceRoomModal

import moment, { Moment } from "moment"
import { CalendarEntry } from "../backendServices/GraphQLServices"
import branding from "../branding/branding"

const ITEMS_IN_ROW = 4
const SLOT_INTERVAL = branding.personDetailPageContent.meetingSlotsSection.timeSlotIntervalMinutes
const NUMBER_OF_SLOTS_IN_ONE_HOUR = 60 / SLOT_INTERVAL
const START_TIME_BRANDING = branding.personDetailPageContent.meetingSlotsSection.startTime
const END_TIME_BRANDING = branding.personDetailPageContent.meetingSlotsSection.endTime

/* #region  PRIVATE HELPERS */
const parseHoursAndMinutes = (timeString: string) => {
    const timeObj = timeString.split(":")
    return {
        hours: parseInt(timeObj[0]),
        minutes: parseInt(timeObj[1])
    }
}

const getStartTime = () => {
    return parseHoursAndMinutes(START_TIME_BRANDING)
}

const getEndTime = () => {
    return parseHoursAndMinutes(END_TIME_BRANDING)
}

const getDateWithTimezone = (date: Moment, type: "start" | "end") => {
    const timezone = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
    const timeObj = type === "start" ? getStartTime() : getEndTime()
    return moment(date).set("h", timeObj.hours).set("m", timeObj.minutes).tz(timezone)
}

const getTimeDifferenceAsHours = (startDate: Moment, endDate: Moment) => {
    const duration = moment.duration(endDate.diff(startDate))
    return Math.abs(duration.asHours()) + 1
}
/* #endregion */

export const getNumberOfItemsInLastRow = (date: Moment) => {
    const startDate = getDateWithTimezone(date, "start")
    const endDate = getDateWithTimezone(date, "end")
    const timeDifferenceAsHours = getTimeDifferenceAsHours(startDate, endDate)
    const totalItems = timeDifferenceAsHours * NUMBER_OF_SLOTS_IN_ONE_HOUR
    return ITEMS_IN_ROW - Math.abs(totalItems % ITEMS_IN_ROW)
}

export const createTimeSlots = (date: Moment) => {
    const endDate = getDateWithTimezone(date, "end")
    let startDate = getDateWithTimezone(date, "start")
    let times: string[] = []

    do {
        times.push(startDate.format("HH:mm"))
        startDate = startDate.add(SLOT_INTERVAL, "minutes")
    } while (startDate.isSameOrBefore(endDate))

    return times
}

export const createMapNotAvailableSlotsByDay = (meetings: CalendarEntry[] | undefined) => {
    const timezone = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
    const map: any = {}

    if (meetings && meetings.length > 0) {
        meetings?.forEach((meeting) => {
            const start = moment.tz(moment(meeting.start), timezone)
            const end = moment.tz(moment(meeting.end), timezone)
            const key = start.format("YYYY-MM-DD")

            if (!map[key]) map[key] = []
            map[key].push({ start: start, end: end })
        })
    }
    return map
}

export const isSlotDisabled = (slotDateTime: Moment, meetingStartDateTime: Moment, meetingEndDateTime: Moment) => {
    const meetingStartTemp = meetingStartDateTime.clone()
    const meetingEndTemp = meetingEndDateTime.clone()

    return (
        (slotDateTime.isSame(meetingStartTemp, "hours") && slotDateTime.isSame(meetingStartTemp, "minutes")) ||
        (slotDateTime.isSame(meetingEndTemp, "hours") && slotDateTime.isSame(meetingEndTemp.subtract("m", 1), "minutes")) ||
        slotDateTime.isBetween(meetingStartDateTime, meetingEndDateTime)
    )
}

/**
 *
 * This method generates meeting dates for the timeslots feature for the desktop view. This is used for the Desktop view
 * of timeslots, because it is reqired to always display 7 days, but to disable days which are not
 * in timeslots range.
 * @returns any[]
 */
export const generateMeetingDates = () => {
    const timezone = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
    let startDateMoment = moment(branding.personDetailPageContent.meetingSlotsSection.startDate).tz(timezone)
    const endDateMoment = moment(branding.personDetailPageContent.meetingSlotsSection.endDate).tz(timezone)
    const dates = []

    // special moment
    let start = moment(branding.personDetailPageContent.meetingSlotsSection.startDate, "YYYY-MM-DD")
    let end = moment(branding.personDetailPageContent.meetingSlotsSection.endDate, "YYYY-MM-DD")

    const istTimeRangeShorterThanSevenDays = Math.abs(moment.duration(start.diff(end)).asDays()) + 1 < 7

    if (istTimeRangeShorterThanSevenDays) {
        while (dates.length < 7) {
            dates.push(startDateMoment.format("YYYY-MM-DD HH:mm"))
            startDateMoment = startDateMoment.clone().add(1, "day")
        }
    } else {
        while (startDateMoment.isSameOrBefore(endDateMoment)) {
            dates.push(startDateMoment.format("YYYY-MM-DD HH:mm"))
            startDateMoment = startDateMoment.clone().add(1, "day")
        }
    }

    return dates
}

/**
 * This method generats dates for the timeslots feature for mobile view. For mobile view it is reqired to show dates which are available
 * in the datepicker.
 * @returns any[]
 */

export const generateMeetingDatesMobile = () => {
    const timezone = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
    let startDateMoment = moment(branding.personDetailPageContent.meetingSlotsSection.startDate).tz(timezone)
    const endDateMoment = moment(branding.personDetailPageContent.meetingSlotsSection.endDate).tz(timezone)
    const dates = []

    while (startDateMoment.isSameOrBefore(endDateMoment)) {
        dates.push(startDateMoment.format("YYYY-MM-DD HH:mm"))
        startDateMoment = startDateMoment.clone().add(1, "day")
    }

    return dates
}

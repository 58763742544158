import * as React from "react"
import { Modal } from "react-bootstrap"
import styled from "styled-components"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { IconClose } from "../Icons"
import { DisagreeButton, SubmitButtonContainer, SubmitButtonNew } from "./SayHelloModal"

const ModalRoot = styled(Modal)`
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};

    svg {
        color: ${branding.primaryColor};
    }

    .close {
        text-shadow: none;
        color: ${branding.mainInfoColor};
        margin-top: -25px;
    }

    .modal-dialog {
        width: 500px;
        top: 40%;
        transform: translateY(-50%);
    }

    .modal-header-close {
        outline: 0;
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 26px;
        font-weight: 10;
        line-height: 1.66666667;
        color: ${branding.mainInfoColor};
        width: 30px;
        padding: 0 4px;
        background: none;
        border-style: none;
        margin-right: 0px;
        margin-top: -10px;
    }

    .modal-content {
        font-family: ${branding.font1};
        padding: 0 10px;
        padding-right: 25px;
        border-radius: 5px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: none;
        padding: 25px 0 20px 0;
    }
    .modal-footer {
        justify-content: space-between;
        border: none;
        padding: 0px 8px;
        margin-right: -15px;
    }

    .modal-body {
        padding: 0px 15px 10px 15px;
    }

    .modal-title {
        font-family: ${branding.font1};
        margin-left: 15px;
        font-size: 14px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 10px;
    }

    .modal-text {
        font-family: ${branding.font1};
        font-size: 12px;
        line-height: 17px;
        font-weight: normal;
        margin-top: 3px;
    }

    .btn-primary.disabled  {
        pointer-events: none !important;
        opacity: 0.5 !important;
    }
`

interface RemoveFromChatModalProps {
    removeOpponent: () => void
    onClose: () => void
}

const RemoveFromChatModal: React.FunctionComponent<RemoveFromChatModalProps> = (props: RemoveFromChatModalProps) => {
    const strings = useLanguageState().getStrings()

    function handleRemove() {
        props.removeOpponent()

        setTimeout(() => {
            props.onClose()
        }, 300)
    }

    return (
        <ModalRoot backdrop={true} onHide={() => props.onClose()} show={true} animation={false}>
            <div onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h3 className="modal-title">{strings.chatBranding.removeParticipantTitle}</h3>
                    <button type="button" className="close" onClick={() => props.onClose()} aria-label="Close">
                        {IconClose({ fill: branding.sideIconBar.sideIconColorDark, width: "11", height: "11" })}
                    </button>
                </div>

                <div className="modal-body">
                    <p className="modal-text">{strings.chatBranding.removeParticipantModalText}</p>
                </div>
                <div className="modal-footer">
                    <SubmitButtonContainer justifyContent="flex-end" style={{ gap: "10px" }}>
                        <DisagreeButton
                            onClick={() => props.onClose()}
                            className="d-flex align-items-center"
                            style={{ width: "84px", marginRight: "0px", cursor: "pointer" }}
                        >
                            {strings.chatBranding.removeParticipantModalDeclineText}
                        </DisagreeButton>
                        <SubmitButtonNew
                            type="submit"
                            onClick={() => handleRemove()}
                            className="d-flex align-items-center justify-content-center"
                            width="84px"
                        >
                            {strings.chatBranding.removeParticipantModalConfirmText}
                        </SubmitButtonNew>
                    </SubmitButtonContainer>
                </div>
            </div>
        </ModalRoot>
    )
}

export default RemoveFromChatModal

import { Col, Row } from "react-bootstrap";
import { isFirefox, isSafari } from "react-device-detect";
import { ActionMeta, OptionTypeBase, ValueType } from "react-select";
import branding from "../../../../branding/branding";
import { useLanguageState } from "../../../../globalStates/LanguageState";
import { IconMicrophone, IconVolumeHigh } from "../../../../ui/Icons";
import { chimeSdk } from "../../../ChimeSdkWrapper";
import { useDevices } from "../../../hooks/useDevices";
import { SettingsContentContainer, StyledSelect, SelectThemeCustom } from "../Settings";

export function AudioSettings() {
    const deviceSwitcherState = useDevices();
    const strings = useLanguageState().getStrings().audioVideoSettings;
    deviceSwitcherState.ensureDevices()
    return (
        <SettingsContentContainer>
            <Row className="mb-2">
                <Col md={1} className={'pt-1'}>{IconMicrophone({ fill: branding.sideIconBar.sideIconColorDark })}</Col>
                <Col md={11} className={'pl-0'}>
                    <StyledSelect
                        placeholder={strings.audioInputLabel}
                        isMulti={false}
                        isSearchable={false}
                        isClearable={false}
                        options={deviceSwitcherState.audioInputDevices()}
                        value={deviceSwitcherState.currentAudioInputDevice()}
                        onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                            if (value !== null && action.action === "select-option") {
                                const option = value as OptionTypeBase;
                                deviceSwitcherState.setAudioInputDevice(option.deviceId, option.label);
                                // should be done in audio context, but will be refactored with new library
                                chimeSdk.audioVideo?.startAudioInput(option.deviceId)
                               
                            }   
                        }}
                        theme={SelectThemeCustom} />
                </Col>
            </Row>

            {!isFirefox && !isSafari && (
                <>
                    <Row>
                        <Col md={1} className={'pt-1'}>{IconVolumeHigh({ fill: branding.sideIconBar.sideIconColorDark })}</Col>
                        <Col md={11} className={'pl-0'}>
                            <StyledSelect
                                placeholder={strings.audioOutputLabel}
                                isMulti={false}
                                isSearchable={false}
                                isClearable={false}
                                options={deviceSwitcherState.audioOutputDevices()}
                                value={deviceSwitcherState.currentAudioOutputDevice()}
                                onChange={(value: ValueType<OptionTypeBase, boolean>, action: ActionMeta<OptionTypeBase>) => {
                                    if (value !== null && action.action === "select-option") {
                                        const option = value as OptionTypeBase;
                                        deviceSwitcherState.setAudioOutputDevice(option.deviceId, option.label)
                                         // should be done in audio context, but will be refactored with new library
                                        chimeSdk.audioVideo?.chooseAudioOutput(option.deviceId)
                                    }
                                }}
                                theme={SelectThemeCustom} />
                        </Col>
                    </Row>
                </>
            )}
        </SettingsContentContainer>
    );
}

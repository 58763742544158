import { fetchDataRest, topic } from "./BackendServicesUtils"
import { defaultLogger as logger } from "../globalStates/AppState"

/*********************************************************************************************
 * SYNC FAVORITES
 **********************************************************************************************/
export interface SyncFavoritesResponse {
    content: any
}

export interface SyncFavoritesParams {
    profileId: string
    body: {
        currentTime: string
        lastSyncTime: string
        changedFavorites?: [
            {
                id: string
                kind: string
                deleted: boolean
                lastModified: string
            }
        ]
    }
}

export async function syncFavorites(params: SyncFavoritesParams): Promise<SyncFavoritesResponse> {
    const defaultRoute = `/sync/topic/${topic}/profile/${params.profileId}`
    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", params.body)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { content: [] }
    }
}

import { fetchDataWebService } from "./BackendServicesUtils"
import { Product } from "./Types"
import { defaultLogger as logger } from "../globalStates/AppState"

/*********************************************************************************************
 * PRODUCT DETAILS
 **********************************************************************************************/
export interface ProductDetailsResponse {
    content: Product
}

export interface ProductDetailsParams {
    productid: string
    rootCategories?: Array<string>
    showCategoryHierarchy?: boolean
}

export async function loadProductData(params: ProductDetailsParams): Promise<ProductDetailsResponse> {
    try {
        const data = await fetchDataWebService("/productdetails", params)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/productdetails",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

/*********************************************************************************************
 * PRODUCT LIST
 **********************************************************************************************/
export interface ProductsListResponse {
    count: number
    products: Product[]
}

export async function loadProductsData(params: object, signal?: AbortSignal): Promise<ProductsListResponse> {
    try {
        const data = await fetchDataWebService("/search", params, signal, true)
        return { count: data.count, products: data.entities }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { count: 0, products: [] }
    }
}

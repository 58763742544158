import { Component } from "react"
import ErrorSite from "./ErrorSite"
import styled from "styled-components"
import { defaultLogger as logger } from "../../globalStates/AppState"

const GlobalErrorBoundaryPanel = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
`

class GlobalErrorBoundary extends Component {
    state = {
        error: null
    }

    static getDerivedStateFromError(error: any) {
        return { error: error }
    }

    componentDidCatch(error: any, errorInfo: any) {
        logger.error({ message: "Global Error Boundary", errorMessage: error.message, errorStack: error.stack })
    }

    render() {
        if (this.state.error) {
            return (
                <GlobalErrorBoundaryPanel>
                    <ErrorSite />
                </GlobalErrorBoundaryPanel>
            )
        }
        return this.props.children
    }
}

export default GlobalErrorBoundary

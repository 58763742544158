import { createState, State, useState } from "@hookstate/core"
import { DataMessage, MeetingSessionStatus } from "amazon-chime-sdk-js"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { checkForTimerUpdate, CheckForTimerUpdateResponse, MeetingKind } from "../../backendServices/MeetingServices"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"
import branding from "../../branding/branding"
import { useContactState } from "../../communicationArea/ContactState"
import { defaultLogger as logger } from "../../globalStates/AppState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { trackMeetingHeartbeatEvent } from "../../utils/GTMTracking"
import { chimeSdk } from "../ChimeSdkWrapper"
import { DataMessageType } from "../enums/DataMessageType"
import { MeetingStatusCode } from "../enums/MeetingStatusCode"
import DeviceType from "../types/DeviceType"
import { useActiveSpeakerContext } from "./ActiveSpeakerContext"
import { useAudioContext } from "./AudioContext"
import { useContentShareContext } from "./ContentShareContext"
import { useGreenRoomContext } from "./GreenRoomContext"
import { useRosterContext } from "./RosterContext"
import { useVideoContext } from "./VideoContext"
import { MeetingRoomGroupType, MeetingRoomType } from "../AudioVideoBranding"

export interface MeetingStatus {
    meetingStatusCode: MeetingStatusCode
    errorMessage?: string
    jsonErrorMessage?: string
}

export interface ShallowVideoTileState {
    tileId: number | null
    isContent: boolean
    localTile: boolean
    boundAttendeeId: string | null
    active: boolean
}

interface StateValues {
    kind: MeetingKind
    name: string
    meetingStatus: MeetingStatus
    meetingSecondsLeft: number | null
    meetingChangeAccepted: boolean
    showConferenceOverlay: boolean
    showKickOrBanMessage: boolean
    keepCallActive: boolean
}

const getStartValues = (): StateValues => {
    return {
        name: "",
        kind: "virtualCafe",
        meetingSecondsLeft: null,
        meetingStatus: { meetingStatusCode: MeetingStatusCode.Ended },
        meetingChangeAccepted: false,
        showConferenceOverlay: false,
        showKickOrBanMessage: false,
        keepCallActive: true
    }
}
const state = createState<StateValues>(getStartValues())
let timeUpIntervallId: number | null
let heartbeatIntervall: number | undefined

export interface ChimeContext {
    getExternalMeetingId: () => string | null
    getName: () => string
    getKind: () => MeetingKind
    getMeetingStatus: () => MeetingStatus
    setMeetingStatus: (meetingStatus: MeetingStatus) => void
    getTimeLeft: () => number | null
    getMaxDuration: () => number | null
    startMeetingTimer: (meetingTimeLeft: number | null) => void
    leaveRoom: (meetingStatus?: MeetingStatus | undefined) => Promise<void>
    createRoom: (
        externalMeetingId: string,
        currentAudioInputDevice: DeviceType | null,
        currentAudioOutputDevice: DeviceType | null,
        currentVideoInputDevice: DeviceType | null
    ) => Promise<void>
    createOrJoinMeeting: (name: string, kind?: MeetingKind) => void
    gotoCurrentMeeting: () => void
    setIsMeetingChangeAccepted: (accepted: boolean) => void
    getIsMeetingChangeAccepted: () => boolean
    setShowConferenceOverlay: (visible: boolean) => void
    showConferenceOverlay: () => boolean
    setShowKickOrBanMessage: (visible: boolean) => void
    showKickOrBanMessage: () => boolean
    setKeepCallActive: (active: boolean) => void
    keepCallActive: () => boolean
}

const useWrapState = (chime: State<StateValues>): ChimeContext => {
    const history = useHistory()
    const meetingSecondsLeft = useState(chime.meetingSecondsLeft)
    const loggedInUser = useLoggedInState()
    const contactState = useContactState()
    const loggedIn = loggedInUser.isLoggedIn

    const audioContext = useAudioContext()
    const videoContext = useVideoContext()
    const contentShareContext = useContentShareContext()
    const greenRoomContext = useGreenRoomContext()
    const rosterContext = useRosterContext()
    const activeSpeakerContext = useActiveSpeakerContext()

    useEffect(
        () => {
            if (!loggedIn && chime.name.get()) {
                leaveRoom()
            }
        },
        // eslint-disable-next-line
        [loggedIn]
    )

    const setMeetingStatus = (meetingStatus: MeetingStatus) => {
        chime.meetingStatus.merge(meetingStatus)
    }

    const setShowKickOrBanMessage = (messageVisible: boolean) => {
        chime.showKickOrBanMessage.merge(messageVisible)
    }

    const setKeepCallActive = (active: boolean) => {
        chime.keepCallActive.merge(active)
    }

    // call this with great care, I recommend only calling this in useEffect's returned clean-up function, or else it will often trigger memory-leak-warnings
    const leaveRoom = async (meetingStatusParam?: MeetingStatus) => {
        contentShareContext.onCallEnd()
        rosterContext.onCallEnd()
        activeSpeakerContext.onCallEnd()

        Promise.all([await audioContext.onCallEnd(), await videoContext.onCallEnd()])

        chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, {
            type: DataMessageType.RAISEHAND,
            attendeeId: chimeSdk.attendeeId!,
            data: false
        })
        chimeSdk.audioVideo?.stop()
        chimeSdk.audioVideo?.removeObserver(observer)
        chimeSdk.audioVideo?.realtimeUnsubscribeFromReceiveDataMessage(chimeSdk.attendeeId!)
        chimeSdk.audioVideo?.realtimeUnsubscribeFromReceiveDataMessage(chimeSdk.meetingId!)
        if (chimeSdk.meetingKind === "conferenceroom" && chimeSdk.userRole === "moderator") {
            const data = (await checkForTimerUpdate(chimeSdk.externalMeetingId!!)) as CheckForTimerUpdateResponse
            if (data.timerUpdated) {
                chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, {
                    type: DataMessageType.TIMELIMITCHANGED,
                    meetingTimeLeft: data.newRemainingDuration,
                    meetingMaxDuration: data.maxMeetingLengthInSeconds
                })
            }
        }
        await chimeSdk.leaveRoom(meetingStatusParam)
        window.onbeforeunload = null
        stopHeartbeat()
        if (timeUpIntervallId) {
            clearTimeout(timeUpIntervallId)
            timeUpIntervallId = null
        }
        const values = getStartValues()

        // values.showConferenceOverlay = false
        // if(meetingStatusParam) values.meetingStatus = meetingStatusParam
        values.meetingStatus = meetingStatusParam ? meetingStatusParam : { meetingStatusCode: MeetingStatusCode.Ended }
        if (
            meetingStatusParam?.meetingStatusCode === MeetingStatusCode.Kicked ||
            meetingStatusParam?.meetingStatusCode === MeetingStatusCode.Banned
        ) {
            values.showConferenceOverlay = true
            values.showKickOrBanMessage = true
        }
        chime.set(values)
    }

    const observer = {
        audioVideoDidStop: (_: MeetingSessionStatus): void => {
            if (state.value.meetingStatus.meetingStatusCode === MeetingStatusCode.Succeeded) {
                leaveRoom({ meetingStatusCode: MeetingStatusCode.Disconnected })
            }
        }
    }

    const receiveAttendeeDataMessage = (dataMessage: DataMessage) => {
        const messageData = dataMessage.json()
        const messageDataType: DataMessageType = messageData.type
        if (!messageDataType) return

        if (dataMessage.senderAttendeeId) audioContext.receiveAttendeeDataMessage(messageDataType)

        switch (messageDataType) {
            case DataMessageType.KICK:
                setKeepCallActive(false)
                setMeetingStatus({ meetingStatusCode: MeetingStatusCode.Kicked, errorMessage: messageData.data })
                setShowKickOrBanMessage(true)
                break
            case DataMessageType.BAN:
                setKeepCallActive(false)
                setMeetingStatus({ meetingStatusCode: MeetingStatusCode.Banned, errorMessage: messageData.data })
                setShowKickOrBanMessage(true)
                break
            case DataMessageType.STOP_SCREENSHARE:
                contentShareContext.stopContentShare()
                break
        }
    }

    const receiveMeetingDataMessage = (dataMessage: DataMessage) => {
        const messageData = dataMessage.json()
        const messageDataType: DataMessageType = messageData.type
        if (!messageDataType) return

        contentShareContext.receiveAttendeeDataMessage(messageDataType, messageData)
        greenRoomContext.receiveMeetingDataMessage(messageDataType, messageData)
        rosterContext.receiveMeetingDataMessage(messageDataType, messageData)

        switch (messageDataType) {
            case DataMessageType.TIMELIMITCHANGED:
                chimeSdk.meetingMaxDuration = messageData.meetingMaxDuration
                chimeSdk.meetingTimeLeft = messageData.meetingTimeLeft
                startMeetingTimer(messageData.meetingTimeLeft)
                break
        }
    }

    const startMeetingTimer = (meetingTimeLeft: number | null) => {
        if (timeUpIntervallId) {
            clearInterval(timeUpIntervallId)
            timeUpIntervallId = null
        }
        if (meetingTimeLeft) {
            meetingSecondsLeft.set(meetingTimeLeft / 1000)
            timeUpIntervallId = window.setInterval(() => {
                const newSecondsLeft = meetingSecondsLeft.get()!! - 1
                if (newSecondsLeft <= 0) {
                    leaveRoom({ meetingStatusCode: MeetingStatusCode.TimeUp })
                } else {
                    meetingSecondsLeft.set(newSecondsLeft)
                }
            }, 1000)
        } else {
            meetingSecondsLeft.set(null)
        }
    }

    return {
        getExternalMeetingId: () => {
            return chimeSdk.externalMeetingId ? chimeSdk.externalMeetingId : null
        },
        getName: () => {
            return chime.name.get()
        },
        getKind: () => {
            return chime.kind.get()
        },
        getMeetingStatus: () => {
            return chime.value.meetingStatus
        },
        setMeetingStatus: (meetingStatus: MeetingStatus) => {
            chime.meetingStatus.merge(meetingStatus)
        },
        getTimeLeft: () => {
            return meetingSecondsLeft.value
        },
        getMaxDuration: () => {
            if (chime.kind.get() === "showroom") {
                return branding.showroomMeetingDuration
            }
            return chimeSdk.meetingMaxDuration
        },
        startMeetingTimer: startMeetingTimer,
        leaveRoom: leaveRoom,
        createRoom: async (
            externalMeetingId: string,
            currentAudioInputDevice: DeviceType | null,
            currentAudioOutputDevice: DeviceType | null,
            currentVideoInputDevice: DeviceType | null
        ) => {
            try {
                if (!loggedInUser.user()?.profileId) return
                if (
                    chime.name.get() !== externalMeetingId &&
                    chime.value.meetingStatus.meetingStatusCode === MeetingStatusCode.Succeeded
                ) {
                    await leaveRoom()
                }
                setMeetingStatus({ meetingStatusCode: MeetingStatusCode.Loading })
                try {
                    await chimeSdk.createOrJoinRoom(loggedInUser.user()!.profileId, externalMeetingId, contactState)
                } catch (error: any) {
                    if ((error as BackendServiceError).httpStatus) {
                        // Custom error code from the backend.
                        if (error.httpStatus === 444) {
                            if (error.responseJson?.errorCode === "meetingFull") {
                                setMeetingStatus({ meetingStatusCode: MeetingStatusCode.Full })
                                return
                            } else if (error.responseJson?.errorCode === "meetingTimeUp") {
                                setMeetingStatus({ meetingStatusCode: MeetingStatusCode.TimeUp })
                                return
                            }
                        } else if (error.httpStatus === 401) {
                            if (error.responseJson?.errorCode === "banned") {
                                setMeetingStatus({
                                    meetingStatusCode: MeetingStatusCode.Banned,
                                    errorMessage: error.responseJson?.errorMessage
                                })
                                return
                            } else if (error.responseJson?.errorCode === "channelIsLive") {
                                setMeetingStatus({
                                    meetingStatusCode: MeetingStatusCode.GreenroomLive,
                                    errorMessage: error.responseJson?.errorMessage
                                })
                                return
                            }
                        }
                        logger.error({ message: "ChimeContext Create or Join Room Failed", errorMessage: error.httpStatusText })
                        setMeetingStatus({
                            errorMessage: error.httpStatusText,
                            meetingStatusCode: MeetingStatusCode.Failed,
                            jsonErrorMessage: error.responseJson?.errorMessage
                        })
                        return
                    }
                }

                await audioContext.onCallStart(currentAudioOutputDevice, currentAudioInputDevice)
                await videoContext.onCallStart(currentVideoInputDevice)
                contentShareContext.onCallStart()
                rosterContext.onCallStart()
                activeSpeakerContext.onCallStart()

                chimeSdk.audioVideo?.addObserver(observer)

                await chimeSdk.joinRoom()

                // replaced logic from Controls.tsx
                // because the input/output devices should be managed when joining a room

                if (chime.kind.get() === "breakout" && chimeSdk.userRole !== "moderator") {
                    audioContext.realtimeMuteLocalAudio()
                } else if (chime.kind.get() === "virtualCafe" && chimeSdk.userRole !== "moderator") {
                    const muteOnJoin: boolean | undefined = branding.meetingRoomGroups
                        .map((meetingRoomGroup: MeetingRoomGroupType) => meetingRoomGroup.meetingRooms)
                        .flat()
                        .find(
                            (meetingRoom: MeetingRoomType) => meetingRoom.id === externalMeetingId.replace("vc_", "")
                        )?.muteNonModeratorsOnJoin

                    if (muteOnJoin) {
                        audioContext.setMutedByMod(true)
                        audioContext.realtimeMuteLocalAudio()
                    }
                }

                chimeSdk.audioVideo?.realtimeSubscribeToReceiveDataMessage(chimeSdk.attendeeId!, receiveAttendeeDataMessage)
                chimeSdk.audioVideo?.realtimeSubscribeToReceiveDataMessage(chimeSdk.meetingId!, receiveMeetingDataMessage)

                chime.merge({ name: externalMeetingId.substr(3), kind: getMeetingKindFromExternalMeetingId(externalMeetingId) })
                greenRoomContext.onCallStart(chime.kind.get(), chime.name.get())
                setMeetingStatus({ meetingStatusCode: MeetingStatusCode.Succeeded })

                // turning off local camera when entering the room, so user can choose to turn on camera later and is not surprised
                videoContext.disableLocalVideoTile()

                startHeartbeat(externalMeetingId)
                startMeetingTimer(chimeSdk.meetingTimeLeft)
                if (chimeSdk.timeLimitChanged) {
                    const delay = 5000
                    // 5000 milliseconds wait, because we do not know yet how we can detect that the realtimeSend is ready to really send. Without this delay, the message will not go out currently
                    setTimeout(() => {
                        chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, {
                            type: DataMessageType.TIMELIMITCHANGED,
                            meetingTimeLeft: chimeSdk.meetingTimeLeft! - delay,
                            meetingMaxDuration: chimeSdk.meetingMaxDuration
                        })
                    }, delay)
                }

                // Recommend using "onbeforeunload" over "addEventListener"
                window.onbeforeunload = async (event: BeforeUnloadEvent) => {
                    // Prevent the window from closing immediately
                    // eslint-disable-next-line
                    event.returnValue = true
                }
            } catch (error: any) {
                // eslint-disable-next-line
                logger.error({
                    message: "Chime Context create room failed",
                    errorMessage: error.message,
                    errorStack: error.stack
                })
                setMeetingStatus({ meetingStatusCode: MeetingStatusCode.Failed, errorMessage: error.message })
            }
        },
        createOrJoinMeeting(name: string, kind?: MeetingKind) {
            if (kind) history.push(getUrlForMeeting(name, kind))
            else history.push(getUrlForMeetingFromExternalMeetingId(name))
        },
        gotoCurrentMeeting() {
            if (chimeSdk.externalMeetingId) history.push(getUrlForMeetingFromExternalMeetingId(chimeSdk.externalMeetingId))
        },
        setIsMeetingChangeAccepted: (accepted: boolean) => {
            chime.meetingChangeAccepted.merge(accepted)
        },
        getIsMeetingChangeAccepted: () => {
            return chime.meetingChangeAccepted.value
        },
        setShowConferenceOverlay: (show: boolean) => {
            chime.showConferenceOverlay.merge(show)
        },
        showConferenceOverlay: () => {
            return chime.showConferenceOverlay.value
        },
        setShowKickOrBanMessage: (show: boolean) => {
            chime.showKickOrBanMessage.merge(show)
        },
        showKickOrBanMessage: () => {
            return chime.showKickOrBanMessage.value
        },
        setKeepCallActive: (active: boolean) => {
            chime.keepCallActive.merge(active)
        },
        keepCallActive: () => {
            return chime.keepCallActive.value
        }
    }
}

export const useChimeContext = () => useWrapState(useState(state))

export function getUrlForMeeting(name: string, kind: MeetingKind) {
    return getUrlForMeetingFromExternalMeetingId(getExternalMeetingId(name, kind))
}

function getUrlForMeetingFromExternalMeetingId(externalMeetingId: string) {
    return `/meeting/${externalMeetingId}/createorjoin`
}

export function getExternalMeetingId(name: string, kind: MeetingKind) {
    return getMeetingKindPrefix(kind) + name
}

export function getMeetingKindPrefix(kind: MeetingKind) {
    switch (kind) {
        case "call":
            return "cl_"
        case "showroom":
            return "sr_"
        case "virtualCafe":
            return "vc_"
        case "calenderEntry":
            return "ce_"
        case "greenroom":
            return "gr_"
        case "roundtable":
            return "rt_"
        case "breakout":
            return "br_"
        case "conferenceroom":
            return "cr_"
        default:
            return ""
    }
}

export function getMeetingKindFromExternalMeetingId(externalMeetingId: string) {
    const prefix = externalMeetingId.substr(0, externalMeetingId.indexOf("_"))
    let kind: MeetingKind = "virtualCafe"
    switch (prefix) {
        case "cl":
            kind = "call"
            break
        case "sr":
            kind = "showroom"
            break
        case "vc":
            kind = "virtualCafe"
            break
        case "ce":
            kind = "calenderEntry"
            break
        case "gr":
            kind = "greenroom"
            break
        case "rt":
            kind = "roundtable"
            break
        case "br":
            kind = "breakout"
            break
        case "cr":
            kind = "conferenceroom"
            break
    }
    return kind
}

const startHeartbeat = (externalMeetingId: string) => {
    const meetingKind = getMeetingKindFromExternalMeetingId(externalMeetingId)
    let hearbeatEventCount: number = 0

    heartbeatIntervall = window.setInterval(() => {
        hearbeatEventCount = hearbeatEventCount + branding.gtmFairHeartbeatEventMinutes
        trackMeetingHeartbeatEvent(meetingKind, hearbeatEventCount)
    }, branding.gtmFairHeartbeatEventMinutes * 60 * 1000)
}

const stopHeartbeat = () => {
    window.clearInterval(heartbeatIntervall)
}
